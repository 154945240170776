import { Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import SeasonTeamSwitcher from "../SeasonTeamSwitcher/SeasonTeamSwitcher";

export default function TeamDetail (props) {
    /**
     * @type {{name: string, date: Date, score: number, opposingScore: number}[]} meets
     */
    const meets = useSelector(state => state.meets);

    return (
        <div style={{width: "100%" }}>
            <SeasonTeamSwitcher />
            <Table bordered hover style={{ maxWidth: "1200px", margin: "15px auto 0 auto", boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.5)" }}>
                <thead>
                    <tr>
                        <th>Meet Name</th>
                        <th>Meet Date</th>
                        <th>Team Score</th>
                        <th>Opposing Team Score</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        meets.sort((a, b) => b.date - a.date).map(l => <tr key={`meet${l.name}`}>
                            <td><a href={`#${l.name}`}>{l.name}</a></td>
                            <td>{l.date.toLocaleDateString()}</td>
                            <td>{l.score}</td>
                            <td>{l.opposingScore}</td>
                        </tr>)
                    }
                </tbody>
            </Table>
        </div>
    )
}
