import { useState } from 'react';
import {Form, Card, Button, Col, InputGroup} from 'react-bootstrap'
import fire from '../../utils/firebaseSetup'
import TbTPage from '../../components/TbTPage/TbTPage';

import './LoginFront.css'

const ForgotLogin = (props) => {

    const {
        email,
        setEmail,
        emailError,
        setEmailError,
        setForgotPassword
    } = props;

    const [emailSent, setEmailSent] = useState(false);

    const clearForgotInputs = () => {
        setEmailSent(false);
        setForgotPassword(false);
    }

    const checkEmail = () => {
        fire
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmail('');
                setEmailError('');
                setEmailSent(true);
            })
            .catch(err => {
                switch (err.code){
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setEmailError(err.message);
                        break;
                    default:
                        break;
                }
            })
    }

    return (
        <TbTPage>
            <div style={{ padding: "50px 0", color: "#444" }}>
                { emailSent ? (
                    <Card className='text-center' style={{ width: '25rem', margin: '20px auto' }}>
                        <Form style={{width: '65%', margin: 'auto'}}>
                            <p>You'll receive a recovery/reset email shortly!</p>
                            <Button variant='dark' style={{width: '90%'}} onClick={() => clearForgotInputs()}>Return to Sign In</Button>
                        </Form>
                    </Card>
                ) : (
                    <section className="login">
                        <Card className='text-center' style={{ width: '25rem',margin: '20px auto' }}>
                            <Card.Header>Forgot Password</Card.Header>
                            <Form style={{width: '80%', margin: 'auto' }}>
                                <Form.Group as={Col}>
                                    <p>Enter the email associated with your account to recieve information on how to recover/reset your password</p>
                                    <Form.Label style={{display: 'flex'}}>Email</Form.Label>
                                    <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email address"
                                        autoFocus
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value) }
                                    />
                                    </InputGroup>
                                    <Form.Text style={{width:'100%', color: 'red', display: 'flex', textAlign: 'left'}}>
                                        {emailError}
                                    </Form.Text>
                                </Form.Group>
                                <Button onClick={() => checkEmail()} variant='dark' style={{width: '90%', marginTop: 10}}>Submit</Button>
                            </Form>
                        </Card>
                    </section>
                ) }
            </div>
        </TbTPage>
    )
};

export default ForgotLogin;
