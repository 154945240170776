import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CommonLoading } from "../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import { enableScroll, disableScroll } from "../../utils/helpers";
import "./MatchInfo.css";
import DB from "../../utils/database";
import { winner_legacy } from "../../utils/helpers";
import { TbTTab, TbTTabView } from "../../components/TabView/TabView";
import TbTPage from "../../components/TbTPage/TbTPage";

const FencerModalBox = ({ viewingFencer, setViewingFencer }) => {
    useEffect(() => {
        disableScroll();
    }, []);

    return <div className="fencerModalBox" id="fencerModalBox" onClick={ (e) => { if (e?.target?.id === "fencerModalBox") { enableScroll(); setViewingFencer(null) } } }>
        <div className="fencerModalContent">
            <span className="fencerModalClose" onClick={() => { enableScroll(); setViewingFencer(null) }}>&times;</span>
            <h2>Fencer info</h2>
            <h5 style={{ color: "#444" }}>{viewingFencer.name}</h5>
            <h5 style={{ color: "#444" }}>{viewingFencer.teamName}</h5>
        </div>
    </div>
}

const Bout = ({ bout, data, onChange, editingBout, idx }) => {
    const [expanded, setExpanded] = useState(false);
    const [editingMode, setEditingMode] = useState(false);
    const [editingStrip, setEditingStrip] = useState("");
    const [editingRightSide, setEditingRightSide] = useState(false);

    const score1 = data.matchType === "team"
        ? (bout.aStrip.score1 > bout.aStrip.score2 ? 1 : 0) + (bout.bStrip.score1 > bout.bStrip.score2 ? 1 : 0) + (bout.cStrip.score1 > bout.cStrip.score2 ? 1 : 0)
        : bout.score1

    const score2 = data.matchType === "team"
        ? (bout.aStrip.score2 > bout.aStrip.score1 ? 1 : 0) + (bout.bStrip.score2 > bout.bStrip.score1 ? 1 : 0) + (bout.cStrip.score2 > bout.cStrip.score1 ? 1 : 0)
        : bout.score2

    const labels = {
        "aStrip": "A",
        "bStrip": "B",
        "cStrip": "C",
    }

    const changeLabel = e => {
        const num = Number(e.target.value);
        setEditingMode(false);
        onChange(num, editingStrip, editingRightSide)
    }

    return <div className={`matchInfoBout${expanded ? " expanded" : ""}`} style={{ backgroundColor: bout.boutColor, height: expanded ? 452 : 140 }}>
        <div style={{ display: "flex", padding: 10 }}>
            <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {data.matchType === "team" ? bout.player1 : bout.player1.trim().split(" ").reverse().join(", ")}
                <div className="scoreBox" style={{ backgroundColor: winner_legacy(bout) ? "green" : "red" }}>
                    { editingBout === idx && !editingRightSide ? <input onChange={onChange} style={{ width: 25 }} /> : <p>{score1}</p> }
                </div>
            </div>
            <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {data.matchType === "team" ? bout.player2 : bout.player2.trim().split(" ").reverse().join(", ")}
                <div className="scoreBox" style={{ backgroundColor: !winner_legacy(bout) ? "green" : "red" }}>
                    { editingBout === idx && editingRightSide ? <input onChange={onChange} style={{ width: 25 }} /> : <p>{score2}</p> }
                </div>
            </div>
        </div>
        { expanded && <>
            { ["cStrip", "bStrip", "aStrip"].map((strip, idx) => <div className="boutStripData">
                <div style={{ fontSize: 48, lineHeight: "84px", position: "absolute", left: 30, top: 20, textAlign: "left" }}>
                    { labels[strip] }
                </div>
                <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    { bout[strip].player1Name }
                    <div className="scoreBox" onClick={() => { setEditingRightSide(false); setEditingStrip(strip); setEditingMode(true) }}>
                        <p>{ editingMode && editingStrip === strip && !editingRightSide ? <input onChange={changeLabel} style={{ width: 25 }} /> : <p>{bout[strip].score1}</p> }</p>
                    </div>
                </div>
                <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    { bout[strip].player2Name }
                    <div className="scoreBox" onClick={() => { setEditingRightSide(true); setEditingStrip(strip); setEditingMode(true) }}>
                       <p>{ editingMode && editingStrip === strip && editingRightSide ? <input onChange={changeLabel} style={{ width: 25 }} /> : <p>{bout[strip].score2}</p> }</p>
                    </div>
                </div>
            </div>) }
        </> }
        <p className="expandButton" onClick={() => setExpanded(!expanded)}>{ !expanded ? "Expand..." : "Collapse..." }</p>
    </div>
}

export default function MatchInfo() {
    const { id } = useParams();
    const matches = useSelector(s => s.matchList);
    const [bouts, setBouts] = useState([]);
    const [viewingFencer, setViewingFencer] = useState(null);

    const data = matches.find(l => l.matchID === id);
    const weapon = data.weapon;
    const [teamSummary, setTeamSummary] = useState({ "Sabre": {}, "Foil": {}, "Epee": {} });

    const parseBouts = e => {
        const teams = data.matchType !== "team" ? data.fencers : data.teams;
        if (e) {
            if (data.matchType === "team") {
                for (const bout of e) {
                    Object.defineProperty(bout, 'score1', { configurable: true, get: function() {
                        return Number(this.aStrip.score1 > this.aStrip.score2) + Number(this.bStrip.score1 > this.bStrip.score2) + Number(this.cStrip.score1 > this.cStrip.score2);
                    } });
                    Object.defineProperty(bout, 'score2', { configurable: true, get: function() {
                        return Number(this.aStrip.score1 < this.aStrip.score2) + Number(this.bStrip.score1 < this.bStrip.score2) + Number(this.cStrip.score1 < this.cStrip.score2);
                    } });
                    Object.defineProperty(bout, 'touches1', { configurable: true, get: function() {
                        return Number(this.aStrip.score1 + this.bStrip.score1 + this.cStrip.score1);
                    } });
                    Object.defineProperty(bout, 'touches2', { configurable: true, get: function() {
                        return Number(this.aStrip.score2 + this.bStrip.score2 + this.cStrip.score2);
                    } });
                }
            }
            for (const bout of e) {
                const seed1 = teams.find(l => l.id === bout.player1ID).seed;
                const seed2 = teams.find(l => l.id === bout.player2ID).seed;
                let p1Win;
                let p2Win;
                if (bout.score1 > bout.score2) {
                    p1Win = bout.score1;
                    p2Win = -bout.score2;
                } else if (bout.score1 < bout.score2) {
                    p1Win = -bout.score1;
                    p2Win = bout.score2;
                } else if (bout.fencer1Priority) {
                    p1Win = bout.score1;
                    p2Win = -bout.score2;
                } else if (bout.fencer2Priority) {
                    p1Win = -bout.score1;
                    p2Win = bout.score2;
                }
                if (teamSummary[weapon][seed1]) {
                    teamSummary[weapon][seed1][seed2] = p1Win;
                } else {
                    teamSummary[weapon][seed1] = { [seed2]: p1Win };
                }
                if (teamSummary[weapon][seed2]) {
                    teamSummary[weapon][seed2][seed1] = p2Win;
                } else {
                    teamSummary[weapon][seed2] = { [seed1]: p2Win };
                }
            }
        }
        setTeamSummary({...teamSummary});
    }

    const giveMockData = () => {
        for (const bout of bouts) {
            const winner = Boolean(Math.floor(Math.random() * 2));
            if (winner) {
                bout.score1 = 5;
                bout.score2 = Math.floor(Math.random() * 5);
            } else {
                bout.score1 = Math.floor(Math.random() * 5);
                bout.score2 = 5;
            }
        }
        setBouts([...bouts]);
        parseBouts(bouts);
    }

    useEffect(() => {
        DB.getMatchBouts(id, parseBouts).then(b => { setBouts([...b]); parseBouts(b) });
    }, [id, weapon]);

    useEffect(() => {
        parseBouts(bouts);
    }, [bouts]);

    const gendersObj = {
        "both": "Both mens and womens",
        "boys": "Mens only",
        "girls": "Womens only",
    };

    const weaponsObj = {
        "all": "All weapons",
        "Sabre": "Sabre",
        "Foil": "Foil",
        "Epee": "Epee"
    };

    const date = (typeof data.date === "string") ? new Date(Date.parse(data.date)) : data.date;

    if (!data) {
        return (
            <div className="matchInfoPage">
                <h1 style={{ color: "white" }}>Tournament Info</h1>
                <CommonLoading color="#714FCA" size="large"/>
            </div>
        )
    }

    return (
        <TbTPage>
            <h1 style={{ color: "white", paddingTop: 30 }}>Tournament Info</h1>
            <div style={{ maxWidth: 800, width: "90%", margin: "0 auto" }}>
                <div style={{ marginTop: "20px", color: "white" }} className="rainbowBox rainbowBoxBG">{ data.name }</div>
                <Table style={{ color: "#EEE", backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center" }}>
                    <tbody>
                        <tr>
                            <td>Round { data.round }</td>
                        </tr>
                        <tr>
                            <td>{date.toDateString()}</td>
                        </tr>
                        <tr>
                            <td>Venue: {data.venue}</td>
                        </tr>
                        <tr>
                            <td>{gendersObj[data.gender]}</td>
                        </tr>
                        <tr>
                            <td>{weaponsObj[data.weapon]}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                { Object.keys(data.pairIDs).length > 1 && <Dropdown style={{ textAlign: "center", marginBottom: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                    <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                        { data.weapon }
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "inherit" }}>
                        { Object.keys(data.pairIDs).map(l => (
                            <Dropdown.Item href={`/tournamentInfo/${data.pairIDs[l][data.gender]}`}>{l}</Dropdown.Item>
                        )) }
                    </Dropdown.Menu>
                </Dropdown> }
                { Object.keys(Object.values(data.pairIDs)[0]).length > 1 && <Dropdown style={{ textAlign: "center", marginBottom: 20, marginLeft: 20, marginRight: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                    <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                        { gendersObj[data.gender] }
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "inherit" }}>
                        <Dropdown.Item>{gendersObj[data.gender]}</Dropdown.Item>
                        <Dropdown.Item href={`/tournamentInfo/${data.pairIDs[data.weapon][data.gender === "boys" ? "girls" : "boys"]}`}>{gendersObj[data.gender === "boys" ? "girls" : "boys"]}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> }
                <Button style={{ marginBottom: 20 }} onClick={giveMockData}>Add mock data</Button>
                <Button style={{ marginLeft: 20, marginBottom: 20 }} href={`/tournamentParticipants/${id}`}>Participants</Button>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: 100, width: 100, flexShrink: 1 }}></div>
                <div style={{ maxWidth: 100, width: 100, flexShrink: 1 }}></div>
            </div>
            <TbTTabView>
                <TbTTab title="Scorecard">
                    <Table className="matchScoreTable" bordered style={{ width: (data.teamCount + 2) * 5, height: (data.teamCount + 1) * 50, flexShrink: 0, margin: "auto" }}>
                        <tbody>
                            <tr>
                                <td className="matchWhiteCell"></td>
                                <td className="matchWhiteCell"></td>
                                { (data.teams || data.fencers).map(l => <td key={`matchInfoHeader${l.seed}`} className="matchWhiteCell">{l.seed + 1}</td>) }
                            </tr>
                            {
                                data.matchType === "team" ? data.teams.map(l => (
                                    <tr key={`matchInfoRow${l.seed}`}>
                                        <td className="matchWhiteCell">{l.name}</td>
                                        <td className="matchWhiteCell">{l.seed + 1}</td>
                                        { data.teams.map(j => (
                                            <td
                                                key={`matchInfoCell${l.seed}-${j.seed}`}
                                                style={{
                                                    backgroundColor: l.seed === j.seed ? "black" : "white",
                                                    color: teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? teamSummary[weapon][l.seed][j.seed] > 0 ? "green" : "red" : "initial",
                                                    fontWeight: "bold"
                                                }}
                                                className={[l.seed === j.seed ? "matchBlackCell" : ""]}
                                            >
                                                {teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? teamSummary[weapon][l.seed][j.seed] > 0 ? "V" : "D" : ""}
                                                {teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? Math.abs(teamSummary[weapon][l.seed][j.seed]) : ""}
                                            </td>
                                        ))}
                                    </tr>
                                )) : data.fencers.map(l => (
                                    <tr key={`matchInfoRow${l.seed}`}>
                                        <td className="matchWhiteCell" onClick={ () => setViewingFencer(l) }>{l.name.trim().split(" ").reverse().join(", ")}</td>
                                        <td className="matchWhiteCell">{l.seed + 1}</td>
                                        { data.fencers.map(j => (
                                            <td
                                                key={`matchInfoCell${l.seed}-${j.seed}`}
                                                style={{
                                                    backgroundColor: l.seed === j.seed ? "black" : "white",
                                                    color: teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? teamSummary[weapon][l.seed][j.seed] > 0 ? "green" : "red" : "initial",
                                                    fontWeight: "bold"
                                                }}
                                                className={[l.seed === j.seed ? "matchBlackCell" : ""]}
                                            >
                                                {teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? teamSummary[weapon][l.seed][j.seed] > 0 ? "V" : "D" : ""}
                                                {teamSummary[weapon][l.seed]?.[j.seed] !== undefined ? Math.abs(teamSummary[weapon][l.seed][j.seed]) : ""}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </TbTTab>
                <TbTTab title="Summary">
                    <Table className="matchSummaryTable" bordered style={{ width: 300, height: (data.teamCount + 1) * 50, flexShrink: 0, margin: "auto" }}>
                        <tbody>
                            <tr>
                                <td className="matchWhiteCell"></td>
                                <td className="matchWhiteCell"></td>
                                <td className="matchWhiteCell">W</td>
                                <td className="matchWhiteCell">L</td>
                                <td className="matchWhiteCell">Ind.</td>
                                <td className="matchWhiteCell">T.S.</td>
                                <td className="matchWhiteCell">T.R.</td>
                                <td className="matchWhiteCell">Rank</td>
                            </tr>
                            {
                                (data.teams || data.fencers).map(l => {
                                    // const touches = data.teams.map(j => Object.values(teamSummary[weapon][j.seed] || {}).reduce((acc, cur) => acc + Math.abs(cur), 0));
                                    const wins = Object.values(teamSummary[weapon][l.seed] || {}).reduce((acc, cur) => acc + Math.abs(cur || 0), 0);
                                    const losses = Object.values(teamSummary[weapon]).map(j => j[l.seed]).reduce((acc, cur) => acc + Math.abs(cur || 0), 0);
                                    const ind = wins - losses;
                                    const teamsCopy = [...(data.teams || data.fencers)];
                                    const faceoffs = [];
                                    teamsCopy.sort((a, b) => {
                                        const aWins = Object.values(teamSummary[weapon][a.seed] || {}).filter(j => (j || -1) > 0).length;
                                        const bWins = Object.values(teamSummary[weapon][b.seed] || {}).filter(j => (j || -1) > 0).length;
                                        if (aWins > bWins) {
                                            return -1;
                                        } else if (aWins < bWins) {
                                            return 1;
                                        } else if (aWins === bWins) {
                                            const aTouches = Object.values(teamSummary[weapon][a.seed] || {}).reduce((acc, cur) => acc + Math.abs(cur), 0);
                                            const bTouches = Object.values(teamSummary[weapon][b.seed] || {}).reduce((acc, cur) => acc + Math.abs(cur), 0);
                                            const aTouchesR = Object.values(teamSummary[weapon]).map(j => j[a.seed]).reduce((acc, cur) => acc + Math.abs(cur || 0), 0);
                                            const bTouchesR = Object.values(teamSummary[weapon]).map(j => j[b.seed]).reduce((acc, cur) => acc + Math.abs(cur || 0), 0);
                                            if (aTouches - aTouchesR > bTouches - bTouchesR) {
                                                return -1;
                                            } else if (aTouches - aTouchesR < bTouches - bTouchesR) {
                                                return 1;
                                            } else {
                                                if (aTouches === bTouches) {
                                                    faceoffs.push(a.seed);
                                                    faceoffs.push(b.seed);
                                                    return 0;
                                                } else if (aTouches > bTouches) {
                                                    return -1;
                                                } else {
                                                    return 1;
                                                }
                                            }
                                        }
                                        return 0;
                                    })
                                    const place = teamsCopy.findIndex(j => j.seed === l.seed) + 1;
                                    const endings = {
                                        1: "st",
                                        2: "nd",
                                        3: "rd",
                                    }
                                    return (
                                        <tr key={`matchSummaryRow${l.seed}`}>
                                            <td className="matchWhiteCell" onClick={ () => data.matchType !== "team" && setViewingFencer(l) }>{data.matchType === "team" ? l.name : l.name.trim().split(" ").reverse().join(", ")}</td>
                                            <td className="matchWhiteCell">{l.seed + 1}</td>
                                            <td className="matchWhiteCell">{Object.values(teamSummary[weapon][l.seed] || {}).filter(j => (j || -1) > 0).length}</td>
                                            <td className="matchWhiteCell">{Object.values(teamSummary[weapon][l.seed] || {}).filter(j => (j || 1) < 0).length}</td>
                                            <td className="matchWhiteCell" style={{ color: ind === 0 ? "grey" : ind > 0 ? "green" : "red" }}>{ind >= 0 ? "+" : "-"}{Math.abs(ind)}</td>
                                            <td className="matchWhiteCell">{isNaN(wins) ? 0 : wins}</td>
                                            <td className="matchWhiteCell">{losses}</td>
                                            <td className="matchWhiteCell">{ faceoffs.includes(l.seed) && place < 4 ? "F.O." : `${place}${endings[place] || "th"}` }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </TbTTab>
                <TbTTab title="Bouts list">
                    <div style={{ maxWidth: 500, width: "90%", minHeight: 400, height: (data.teamCount + 1) * 50, overflow: "auto", margin: "auto", flexShrink: 0 }}>
                        <div style={{ color: "white", height: 50, fontSize: 24, lineHeight: "35px" }} className="rainbowBox rainbowBoxBG">Bout List</div>
                        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center", minHeight: (data.teamCount + 1) * 5 }}>
                            { bouts.map((bout, idx) => (
                                <Bout
                                    bout={bout}
                                    data={data}
                                    onChange={(num, strip, rightSide) => {
                                        bout[strip][rightSide ? "score2" : "score1"] = num;
                                        setBouts([...bouts]);
                                    }}
                                    idx={idx}
                                />
                            ))}
                        </div>
                    </div>
                </TbTTab>
            </TbTTabView>
            { JSON.stringify(teamSummary) }
            { viewingFencer && <FencerModalBox viewingFencer={viewingFencer} setViewingFencer={setViewingFencer}></FencerModalBox> }
        </TbTPage>
    )
}
