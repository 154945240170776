import { createStore, applyMiddleware } from "redux";
import DB, { getRefs, removeDBListener } from "./database";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { Fencer, MatchData, MeetBouts, MeetData, Team, TeamMember, TournamentData, TournamentDataSmaller, UserInfo, Weapon, AdministratingTeam, TournamentDataSmallerDate } from "../types";
import { FirebaseUser } from "./firebaseSetup";
import { winner_legacy } from "./helpers";

const persistConfig = {
    key: 'root',
    storage,
};

/**
 * Gets score of BoutData, but absolutely (no ourteam/theirteam)
 */
export function getMeetScoreAbsolute(bouts: MeetBouts) {
    // This is here because redux is like semi-synchronous and stuff is weird idk
    if (!bouts) {
        return { team1Score: 0, team2Score: 0 };
    }
    const meet = bouts;
    const team1Score = Object.values(meet).reduce((acc0, cur0) => acc0 + Object.values(cur0).reduce((acc, cur) => acc + (cur.score1 > cur.score2 ? 1 : 0), 0), 0);
    const team2Score = Object.values(meet).reduce((acc0, cur0) => acc0 + Object.values(cur0).reduce((acc, cur) => acc + (cur.score1 < cur.score2 ? 1 : 0), 0), 0);
    return { team1Score, team2Score };
}

const composedEnhancer = applyMiddleware(thunkMiddleware);

export interface ReduxState {
    season: string;
    seasons: string[];
    team: string;
    teamRegion: string;
    teamCountry: string;
    teamID: string;
    administratingTeams: Record<string, AdministratingTeam>;
    subteam: string;
    subteams: string[];
    meetsData: Record<string, TournamentDataSmaller>;
    meetsDataAdvanced: Record<string, MeetData>;
    meetsInfo: Record<string, TournamentData>;
    teamMembers: Record<Weapon, TeamMember[]>;
    fencerList: Fencer[];
    fencerRecord: Record<Weapon, Record<string, { wins: number, losses: number }>>;
    tournamentData: TournamentDataSmaller[];
    tournamentScores: { team1Score: number, team2Score: number }[];
    tournamentBouts: Record<string, MeetBouts>;
    tournamentDetails: any[];
    matchList: MatchData[];
    teamList: Record<string, Team>;
    uid: string;
    user: FirebaseUser | null;
    administratingTeam: boolean;
    isPublished: boolean;
    loading: boolean;
    userInfo: UserInfo | null;
    editingTeamBehalf: Record<string, any>;
}

const getInitialState = (): ReduxState => ({
    season: "2020-2021",
    seasons: ["2020-2021"],
    team: "My team",
    teamRegion: "New Jersey",
    teamCountry: "United States",
    teamID: "",
    administratingTeams: {},
    subteam: "boys",
    subteams: ["boys", "girls"],
    meetsData: {},
    meetsDataAdvanced: {},
    meetsInfo: {},
    teamMembers: { Sabre: [], Foil: [], Epee: [] },
    fencerList: [],
    fencerRecord: { Sabre: {}, Foil: {}, Epee: {} },
    tournamentData: [],
    tournamentScores: [],
    tournamentBouts: {},
    tournamentDetails: [],
    matchList: [],
    teamList: {},
    uid: "",
    user: null,
    administratingTeam: false,
    isPublished: false,
    loading: false,
    userInfo: null,
    editingTeamBehalf: {}
});

const initialState = getInitialState();

const updateBasicData = async (dispatch): Promise<[UserInfo | null, Record<string, Team>, Fencer[]]> => {
    console.time("Updating basic data");

    const [userInfo, teamsList, fencerList] = await Promise.all([
        DB.getUserInfo(),
        DB.getTeamList(),
        DB.getFencersList(),
    ]);

    dispatch({ type: "updateUserInfo", info: userInfo });
    dispatch({ type: "updateTeamList", teamsList });

    fencerList.sort((a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn));
    dispatch({ type: "updateFencerList", fencerList });

    console.timeEnd("Updating basic data");

    return [userInfo, teamsList, fencerList];
};

export const updateMeetsThunk = async (dispatch, getState: () => ReduxState) => {
    console.time("Updating meets thunk");
    const state = getState();

    DB.getMatchList(matches => dispatch({ type: "updateMatchList", matches }));

    dispatch({ type: "setLoading", loading: true });

    let teamList = state.teamList;

    if (!state.userInfo || !Object.keys(state.teamList).length || !state.fencerList.length) {
        const res = await updateBasicData(dispatch);
        teamList = res[1];
        console.log("Getting basic data...");
    } else {
        console.log("Skipping getting basic data...");
    }

    console.time("Get administrators");

    let teamIDs: string[] = [];

    if (state.teamID) {
        // Team ID won't ever change if it's already defined - so we don't need to await this
        if (state.uid) {
            getRefs<Record<string, AdministratingTeam>>(`/users/${state.uid}/teams`).then(administrating => {
                teamIDs = Object.keys(administrating);

                if (teamIDs.length !== 0) {
                    dispatch({ type: "setAdministrating", administratingTeams: administrating });
                }
            })
        }
    } else {
        const administratingRaw = (state.uid ? await getRefs<Record<string, AdministratingTeam>>(`/users/${state.uid}/teams`) : {}) || {};
        const teamListKeys = Object.keys(teamList);
        const administrating = Object.fromEntries(Object.entries(administratingRaw).filter(l => teamListKeys.includes(l[0])));
        teamIDs = Object.keys(administrating);

        if (teamIDs.length !== 0) {
            dispatch({ type: "setAdministrating", administratingTeams: administrating });
        } else {
            dispatch({ type: "setLoading", loading: false });
            return;
        }
    }

    console.timeEnd("Get administrators");

    let teamIdx = 0;
    let teamID = state.teamID || teamIDs[teamIdx];
    let teamData = await DB.teamData(teamID);
    while (teamData === null) {
        teamID = teamIDs[++teamIdx];
        teamData = await DB.teamData(teamID);
    }

    dispatch({ type: "updateTeamID", teamID });

    console.time("Fetching seasons");
    const seasonsList: Record<string, unknown> = await getRefs(`/teams/details/${teamID}/seasons/list`) || {};
    dispatch({ type: "updateSeasons", seasons: Object.keys(seasonsList) });
    console.timeEnd("Fetching seasons");
    console.time("Processing seasons");

    let season = state.season;
    if (JSON.stringify(state.seasons) !== JSON.stringify(Object.keys(seasonsList))) {
        season = new Date().getMonth() >= 7 ? `${new Date().getFullYear()}-${new Date().getFullYear() + 1}` : `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
    }
    if (!Object.keys(seasonsList).includes(season)) {
        season = Object.keys(seasonsList)[Object.keys(seasonsList).length - 1];
    }

    console.timeEnd("Processing seasons");
    console.time("Processing team data");
    const [teamRoster, res] = await Promise.all([
        DB.getTeamRosterForSeason(teamID, season, state.subteam, roster => dispatch({ type: "updateRoster", teamRoster: roster })),
        DB.tournamentsForTeam(teamID, season, state.subteam)
    ]);
    if (!teamData.boysAndGirlsTeam || teamData.boysAndGirlsTeam === "boys" || teamData.boysAndGirlsTeam === "girls") {
        dispatch({ type: "updateSubteams", subteams: [""] });
    } else {
        dispatch({ type: "updateSubteams", subteams: ["boys", "girls"] });
    }

    dispatch({ type: "updateRoster", teamRoster });

    console.timeEnd("Processing team data");
    console.time("Fetching tournaments");
    const meetsData = Object.fromEntries(res.map(l => [l.tournamentID, l])) || {};
    const [meetsDataAdvancedArr, meetsInfoArr] = await Promise.all([
        Promise.all(Object.keys(meetsData).map(l => getRefs<MeetData>(`/tournaments/details/${l}`))),
        Promise.all(Object.keys(meetsData).map(l => getRefs<TournamentData>(`/tournaments/list/${l}`)))
    ]);
    console.timeEnd("Fetching tournaments");
    console.time("Tournament processing");

    const meetsInfo = meetsInfoArr.reduce((acc, cur, idx) => ({ ...acc, [Object.keys(meetsData)[idx]]: cur }), {});
    const meetsDataAdvanced = Object.fromEntries(
        Object.entries(
            meetsDataAdvancedArr.reduce((acc, cur, idx) => ({ ...acc, [Object.keys(meetsData)[idx]]: cur }), {})
        ).filter((_, idx) => meetsInfo[Object.keys(meetsData)[idx]].isPublished)
    );
    dispatch({ type: "updateTeamArea", region: teamData.region, country: teamData.country });
    dispatch({ type: "updateTeamName", name: teamData.teamName });
    dispatch({ type: "updateTeamPublished", published: teamData.isPublished });
    dispatch({ type: "updateMeetsBig", meetsInfo, meetsDataAdvanced, meetsData });

    res.sort((a, b) => Number(b.startDate) - Number(a.startDate));
    const bouts = meetsDataAdvancedArr.map(l => l.bouts);
    const boutsObj: Record<string, MeetBouts> = {};
    for (const i in res) {
        boutsObj[res[i].tournamentID] = bouts[i];
    }
    dispatch({ type: "setTournaments", tournaments: res, bouts: boutsObj, scores: bouts.map(getMeetScoreAbsolute) });

    dispatch({ type: "setLoading", loading: false });

    DB.getTeamRosterForSeason(teamID, season, state.subteam, () => {});
    sessionStorage.setItem("signedIn", "true");
    console.timeEnd("Tournament processing");
    console.timeEnd("Updating meets thunk");
};

function teamDataReducer (state = initialState, action: { type: string } & Record<string, any>) {
    switch (action.type) {
        case "setLoading":
            return { ...state, loading: action.loading };
        case "switchSeason":
            return { ...state, season: action.season };
        case "switchTeam":
            return { ...state, team: action.team };
        case "updateSubteams":
            const modified = { ...state, subteams: action.subteams };
            if (action.subteams.length !== state.subteams.length) {
                modified.subteam = action.subteams[0];
            }
            return modified;
        case "switchSubteam":
            removeDBListener(`/teams/details/${state.teamID}/seasons/details/${state.season}${state.subteam === "boys" ? "/boys" : "/girls"}/squads`);
            return { ...state, subteam: action.season };
        case "switchAdministratingTeam":
            return { ...state, teamID: action.season };
        case "setAdministrating":
            return {
                ...state,
                administratingTeams: action.administratingTeams,
                administratingTeam: action.administratingTeams.length > 0
            };
        case "updateTeamList":
            return { ...state, teamList: action.teamsList };
        case "updateTeamName":
            return { ...state, team: action.name };
        case "updateTeamArea":
            return { ...state, teamRegion: action.region, teamCountry: action.country };
        case "updateMeets":
            return { ...state, meets: action.meets };
        case "updateUser":
            return { ...state, user: action.user };
        case "updateRoster":
            return { ...state, teamMembers: action.teamRoster };
        case "updateUid":
            return { ...state, uid: action.uid };
        case "updateTeamID":
            return { ...state, teamID: action.teamID };
        case "updateSeasons":
            let newSeason = state.season;
            if (JSON.stringify(state.seasons) !== JSON.stringify(action.seasons)) {
                newSeason = new Date().getMonth() >= 7 ? `${new Date().getFullYear()}-${new Date().getFullYear() + 1}` : `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
            }
            if (!action.seasons.includes(newSeason)) {
                newSeason = action.seasons[action.seasons.length - 1];
            }
            return { ...state, seasons: action.seasons, season: newSeason };
        case "updateMatchList":
            return { ...state, matchList: action.matches };
        case "updateMeetsData":
            return { ...state, meetsData: action.meetsData };
        case "updateMeetsDataAdvanced":
            return { ...state, meetsDataAdvanced: action.meetsDataAdvanced };
        case "updateMeetsInfo":
            return { ...state, meetsInfo: action.meetsInfo };
        case "updateMeetsBig":
            return { ...state, meetsData: action.meetsData, meetsDataAdvanced: action.meetsDataAdvanced, meetsInfo: action.meetsInfo };
        case "updateFencerList":
            return { ...state, fencerList: action.fencerList };
        case "updateTeamPublished":
            return { ...state, isPublished: action.published };
        case "setTournaments":
            if (action.bouts) {
                const tournamentsObj: Record<string, TournamentDataSmallerDate> = {};
                for (const tourney of action.tournaments) {
                    tournamentsObj[tourney.tournamentID] = tourney;
                }
                const record: Record<Weapon, Record<string, { wins: number, losses: number }>> = { Sabre: {}, Foil: {}, Epee: {} };
                for (const i in action.bouts) {
                    const bouts: MeetBouts = action.bouts[i];
                    if (tournamentsObj[i].JV) continue;
                    for (const weapon in bouts) {
                        for (const j in bouts[weapon as Weapon]) {
                            const bout = bouts[weapon as Weapon][j];
                            const score = winner_legacy(bout);
                            if (bout.fencer1TeamID === state.teamID) {
                                if (bout.fencer1ID in record[weapon]) {
                                    record[weapon][bout.fencer1ID].wins += Number(score === false);
                                    record[weapon][bout.fencer1ID].losses += Number(score === true);
                                } else {
                                    record[weapon][bout.fencer1ID] = {
                                        wins: Number(score === false),
                                        losses: Number(score === true)
                                    };
                                }
                            } else if (bout.fencer2TeamID === state.teamID) {
                                if (bout.fencer2ID in record[weapon]) {
                                    record[weapon][bout.fencer2ID].wins += Number(score === true);
                                    record[weapon][bout.fencer2ID].losses += Number(score === false);
                                } else {
                                    record[weapon][bout.fencer2ID] = {
                                        wins: Number(score === true),
                                        losses: Number(score === false)
                                    };
                                }
                            }
                        }
                    }
                }
                return { ...state, tournamentData: action.tournaments, tournamentScores: action.scores, tournamentBouts: action.bouts, fencerRecord: record };
            } else {
                return { ...state, tournamentData: action.tournaments, tournamentScores: action.scores };
            }
        case "updateUserInfo":
            return { ...state, userInfo: action.info };
        case "editEditingTeamBehalf":
            return { ...state, editingTeamBehalf: action.payload, team: action.payload.teamName, isPublished: action.payload.isPublished };
        case "resetState":
            return getInitialState();
        default:
            return state;
    }
}

const persistedReducer = persistReducer(persistConfig, teamDataReducer);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const store = createStore(persistedReducer, composedEnhancer);
    const persistor = persistStore(store);
    return { store, persistor };
};
