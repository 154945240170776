import { Button, Dropdown, Table } from 'react-bootstrap';
import { CommonLoading } from "../../components/Loading/Loading";
import { useDispatch, useSelector } from 'react-redux';
import SeasonTeamSwitcher from '../../components/SeasonTeamSwitcher/SeasonTeamSwitcher';
import "./Homepage.css";
import TbTPage from "../../components/TbTPage/TbTPage";
import PermissionDenied from '../../components/PermissionDenied/PermissionDenied';
import Modal from '../../components/Modal/Modal';
import { MeetData, TournamentDataSmaller, Weapon } from '../../types';
import { ReduxState, updateMeetsThunk } from '../../utils/store';
import { useState } from 'react';
import { enableScroll } from "../../utils/helpers";
import DB from '../../utils/database';

//
// This file is unbelievably messy. Please read through this at your own risk.
// Update 1/24/2022 - this file has been cleaned up a lot but there are still messy parts to it
// Update 2/4/2022 - this file is no longer messy
//

/**
 * Gets score of BoutData with our team and their team.
 */
function getMeetScore({ bouts }: MeetData, teamID: string): { us: number, them: number } {
    let us = 0;
    let them = 0;
    for (const weapon in bouts) {
        for (const bout of Object.values(bouts[weapon as Weapon])) {
            if (bout?.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
                if (bout.fencer1TeamID === teamID) {
                    if (bout.score1 > bout.score2) {
                        us++;
                    } else if (bout.score1 < bout.score2) {
                        them++;
                    } else if (bout.fencer1Priority) {
                        us++;
                    } else if (bout.fencer2Priority) {
                        them++;
                    }
                } else {
                    if (bout.score1 > bout.score2) {
                        them++;
                    } else if (bout.score1 < bout.score2) {
                        us++;
                    } else if (bout.fencer1Priority) {
                        them++;
                    } else if (bout.fencer2Priority) {
                        us++;
                    }
                }
            }
        }
    }
    return { us, them };
}

/**
 * Gets squad score of BoutData
 */
const getSquadScoreOverall = (bouts: MeetData[], teamID: string): { wins: Record<Weapon, number>; losses: Record<Weapon, number> } => {
    const meets = bouts.map(l => l.bouts);

    const overallScore: { wins: Record<Weapon, number>; losses: Record<Weapon, number> } = {
        wins: {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        },
        losses: {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        }
    };

    for (const meet of meets) {
        const wins: Record<Weapon, number> = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };

        const losses: Record<Weapon, number> = {
            Sabre: 0,
            Foil: 0,
            Epee: 0
        };

        for (const discipline in meet) {
            for (const bout of Object.values(meet[discipline as Weapon])) {
                if (bout?.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
                    if (bout.fencer1TeamID === teamID) {
                        if (bout.score1 > bout.score2) {
                            wins[discipline]++;
                        } else if (bout.score1 < bout.score2) {
                            losses[discipline]++;
                        } else if (bout.fencer1Priority) {
                            wins[discipline]++;
                        } else if (bout.fencer2Priority) {
                            losses[discipline]++;
                        }
                    } else {
                        if (bout.score1 > bout.score2) {
                            losses[discipline]++;
                        } else if (bout.score1 < bout.score2) {
                            wins[discipline]++;
                        } else if (bout.fencer1Priority) {
                            losses[discipline]++;
                        } else if (bout.fencer2Priority) {
                            wins[discipline]++;
                        }
                    }
                }
            }
        }

        if (wins.Sabre > losses.Sabre) {
            overallScore.wins.Sabre++;
        } else {
            overallScore.losses.Sabre++;
        }
        if (wins.Foil > losses.Foil) {
            overallScore.wins.Foil++;
        } else {
            overallScore.losses.Foil++;
        }
        if (wins.Epee > losses.Epee) {
            overallScore.wins.Epee++;
        } else {
            overallScore.losses.Epee++;
        }
    }

    return overallScore;
}

/**
 * Team highlights component
 */
export default function TeamHighlights () {
    const administratingTeams = useSelector((s: ReduxState) => s.administratingTeams);

    const teamName = useSelector((s: ReduxState) => s.team);
    const teamRegion = useSelector((s: ReduxState) => s.teamRegion);
    const teamCountry = useSelector((s: ReduxState) => s.teamCountry);
    const teamID = useSelector((s: ReduxState) => s.teamID);
    const meetData: Record<string, TournamentDataSmaller> = useSelector((s: ReduxState) => s.meetsData);
    const loading = useSelector((s: ReduxState) => s.loading);
    const seasons = useSelector((s: ReduxState) => s.seasons);

    const [deletingTeam, setDeletingTeam] = useState(false);
    const [deletingSeason, setDeletingSeason] = useState(false);
    const [seasonToDelete, setSeasonToDelete] = useState<string | null>(null);

    const dispatch = useDispatch();

    const DeleteSeasonModal = () => {
        return <Modal close={() => setDeletingSeason(false)}>
            <h2>Choose the season you want to delete. <strong>This action is irreversible!</strong></h2>
            <Dropdown style={{ textAlign: "center" }} className="transparentDropdown" focusFirstItemOnShow={true}>
                <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                    { seasonToDelete || "Choose" } season
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: "inherit" }}>
                    { seasons.map(l => <Dropdown.Item onClick={() => setSeasonToDelete(l)} key={`seasonToDelete${l}`}>{l} season</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown>
            <div style={{ display: "flex" }}>
                <Button variant="success" onClick={async () => {
                    if (!teamID || !seasonToDelete) {
                        return;
                    }
                    await DB.deleteTeamSeason(teamID, seasonToDelete);
                    dispatch(updateMeetsThunk);
                    setSeasonToDelete(null);
                    setDeletingSeason(false);
                    enableScroll();
                }} style={{ marginRight: 10 }}>Delete</Button>
                <Button variant="danger" onClick={() => { setDeletingSeason(false); enableScroll() }}>cancel</Button>
            </div>
        </Modal>
    }

    const DeleteTeamModal = () => {
        return <Modal close={() => setDeletingTeam(false)}>
            <h2>Are you sure you want to delete this team? <strong>This action is irreversible!</strong></h2>
            <div style={{ display: "flex" }}>
                <Button variant="success" onClick={async () => {
                    await DB.deleteTeam(teamID);
                    dispatch(updateMeetsThunk);
                    setDeletingTeam(false);
                    enableScroll();
                }} style={{ marginRight: 10 }}>Yes</Button>
                <Button variant="danger" onClick={() => { setDeletingTeam(false); enableScroll() }}>No</Button>
            </div>
        </Modal>
    }

    /**
     * Contains the bouts and administrators for each meet, though we're only concerned about the bouts and I didn't type the admins.
     */
    const meetsDataAdvancedRaw: Record<string, MeetData> = useSelector((s: ReduxState) => s.meetsDataAdvanced);

    if (!Object.keys(administratingTeams || {}).length && !loading) {
        return <PermissionDenied message="You have no teams to administrate at this time." promptTeamCreation={true} />;
    }

    if (loading) {
        return (
            <div className="backgroundImage" style={{ fontSize: "125%", minHeight: "100vh" }}>
                <CommonLoading color="#714FCA" size="large"/>
            </div>
        )
    }

    // Remove meets that are all 0-0
    const meetsDataAdvanced = Object.fromEntries(Object.entries(meetsDataAdvancedRaw).filter(l => {
        for (const discipline in l[1].bouts) {
            const bouts = Object.values(l[1].bouts[discipline as Weapon]);
            for (const bout of bouts) {
                if (bout.score1 !== 0 || bout.score2 !== 0) {
                    return true;
                }
            }
        }
        return false;
    }));

    /**
     * Most recent meet for the team
     */
    const mostRecentMeet = Object.entries(meetData).sort((a, b) => Number(new Date(b[1].startDate)) - Number(new Date(a[1].startDate)))[0];

    // Exit out if there's no first meet (i.e. no meets have happened yet)
    if (!mostRecentMeet) {
        return (
            <TbTPage className="homePage">
                <div style={{ maxWidth: "1000px", width: "90%", textAlign: "center", margin: "0 auto" }}>
                    <h2 style={{ fontSize: "60px", fontWeight: "bold" }}>{ teamName }</h2>
                    <h3 style={{ fontSize: "24px" }}>{ teamRegion }, { teamCountry }</h3>
                    <div className="homePageDeleteButtons">
                        <Button variant="danger" onClick={ () => setDeletingSeason(true) }>Delete season</Button>
                        <Button variant="danger" onClick={ () => setDeletingTeam(true) }>Delete team</Button>
                    </div>
                    <SeasonTeamSwitcher />
                    <div className="rainbowBox rainbowBoxBG" style={{ padding: "12px" }}>
                        Overall Score
                    </div>
                    <Table style={{ color: "#EEE", backgroundColor: "rgba(0, 0, 0, 0.95)" }}>
                        <tbody>
                            <tr>
                                <td>No meets yet!</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                { deletingSeason && <DeleteSeasonModal /> }
                { deletingTeam && <DeleteTeamModal /> }
            </TbTPage>
        )
    }

    /**
     * Accumulates a record of player IDs to wins, losses, name, and discipline
     */
    const individualStats: Record<string, { wins: number, losses: number, name: string, discipline: Weapon }> = {};
    /**
     * List of bouts of the most recent meet
     */
    const firstMeetBoutData = meetsDataAdvanced[mostRecentMeet[0]]?.bouts!;
    if (firstMeetBoutData) {
        /**
         * Creates a blank user in case there's no entry in `individualStats` yet
         */
        const createBlankUser = (name: string, discipline: Weapon, win: 0 | 1) => ({
            wins: Number(win),
            losses: Number(!win),
            name,
            discipline
        });
        // For each discipline
        for (const discipline in firstMeetBoutData) {
            // For each bout of the bouts in that meet for that discipline
            for (const bout of Object.values(firstMeetBoutData[discipline as Weapon])) {
                // Whether we are left side or right side
                const ourTeam = bout.fencer1TeamID === teamID ? "score1" : "score2";
                const theirTeam = bout.fencer2TeamID === teamID ? "score1" : "score2";
                const ourTeamFencer = ourTeam === "score1" ? "fencer1" : "fencer2";
                // Our team's score
                const ourScore = individualStats[bout[`${ourTeamFencer}ID`]];
                if (bout[ourTeam] > bout[theirTeam]) {
                    // If we won the bout
                    individualStats[bout[`${ourTeamFencer}ID`]] = ourScore ? { ...ourScore, wins: ourScore.wins + 1 } : createBlankUser(bout[ourTeamFencer], discipline as Weapon, 1);
                } else {
                    // If we lost the bout
                    individualStats[bout[`${ourTeamFencer}ID`]] = ourScore ? { ...ourScore, losses: ourScore.losses + 1 } : createBlankUser(bout[ourTeamFencer], discipline as Weapon, 0);
                }
            }
        }
    }

    /**
     * Same as `individualStats`, but only the standouts
     */
    const standouts = Object.values(individualStats).filter(l => l.wins >= 2).sort((a, b) => b.wins - a.wins || a.name.localeCompare(b.name));

    /**
     * Squad score overall across the season
     */
    const { wins: squadScoreOverall, losses: squadScoreLosses } = getSquadScoreOverall(Object.values(Object.keys(meetsDataAdvanced).length !== 0 ? meetsDataAdvanced : {}), teamID);

    let winCount = 0;
    let lossCount = 0;

    for (const meetID in meetsDataAdvanced) {
        const meet = meetsDataAdvanced[meetID];
        const score = getMeetScore(meet, teamID);
        if (score.us > score.them) {
            winCount++;
        } else {
            lossCount++;
        }
    }

    const topSquads = Object.entries(squadScoreOverall).sort((a, b) => b[1] - a[1]);

    // Modified name of the most recent meet
    const modifiedName = (() => {
        const tournamentObj = mostRecentMeet[1];
        if (!tournamentObj) {
            return "";
        }
        if ("tournamentName" in tournamentObj && (tournamentObj.tournamentName.includes("vs") || tournamentObj.tournamentName.includes("@"))) {
            let modifiedNameArr = tournamentObj.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
            modifiedNameArr = modifiedNameArr
                .filter(l => !!l)
                .filter(l => !l.includes("vs") && !l.includes("@"))
                .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
            if (tournamentObj.tournamentName.includes("@")) {
                modifiedNameArr.reverse();
            }
            return `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
        } else {
            return `${tournamentObj.team1Name} (H) vs. ${tournamentObj.team2Name} (A)`;
        }
    })();

    /**
     * Score of the most recent meet.
     */
    const mostRecentMeetScore = getMeetScore(meetsDataAdvanced[mostRecentMeet[0]], teamID);

    return (
        <TbTPage className="homePage">
            <div style={{ maxWidth: "1000px", width: "90%", textAlign: "center", margin: "0 auto" }}>
                <h2 style={{ fontSize: "60px", fontWeight: "bold" }}>{ teamName }</h2>
                <h3 style={{ fontSize: "24px" }}>{ teamRegion }, { teamCountry }</h3>
                <div className="homePageDeleteButtons">
                    <Button variant="danger" onClick={ () => setDeletingSeason(true) }>Delete season</Button>
                    <Button variant="danger" onClick={ () => setDeletingTeam(true) }>Delete team</Button>
                </div>
                <SeasonTeamSwitcher />
                <div className="rainbowBox rainbowBoxBG" style={{ padding: "12px" }}>
                    Overall Score
                </div>
                <Table style={{ color: "#EEE", backgroundColor: "rgba(0, 0, 0, 0.95)" }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Wins</th>
                            <th>Losses</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ fontWeight: "600", fontSize: "133%" }}>
                            <td style={{ padding: "7px", width: "34%" }}>Team</td>
                            <td style={{ padding: "7px" }}>{ winCount }</td>
                            <td style={{ padding: "7px" }}>{ lossCount }</td>
                        </tr>
                        <tr>
                            <td style={{ width: "34%" }}>Sabre</td>
                            <td>{squadScoreOverall.Sabre}</td>
                            <td>{squadScoreLosses.Sabre}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "34%" }}>Foil</td>
                            <td>{squadScoreOverall.Foil}</td>
                            <td>{squadScoreLosses.Foil}</td>
                        </tr>
                        <tr>
                            <td style={{ width: "34%" }}>Epee</td>
                            <td>{squadScoreOverall.Epee}</td>
                            <td>{squadScoreLosses.Epee}</td>
                        </tr>
                        <tr>
                            <th>{ topSquads.filter(l => l[1] === topSquads[0][1]).length > 1 ? "Top Squads" : "Top Squad" }</th>
                            <th colSpan={2}>{ topSquads.filter(l => l[1] === topSquads[0][1]).map(l => l[0]).sort((a, b) => b.localeCompare(a)).join(", ") }</th>
                        </tr>
                    </tbody>
                </Table>
                { Object.keys(meetsDataAdvanced).length === 0 && <div className="rainbowBox rainbowBoxBG" style={{ marginBottom: "20px" }}>No meets have happened yet!</div> }
                { Object.keys(meetsDataAdvanced).length !== 0 && <>
                    <div style={{ marginTop: "20px" }} className="rainbowBox rainbowBoxBG">Most Recent Meet</div>
                    <Table style={{ color: "#EEE", backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center" }}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>{ modifiedName }</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><strong style={{ fontSize: "125%" }}>{ mostRecentMeetScore.us || 0 }</strong> vs. <strong style={{ fontSize: "125%" }}>{ mostRecentMeetScore.them || 0 }</strong></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>{ new Date(mostRecentMeet?.[1].startDate).toLocaleDateString(undefined, { month: "long", day: "numeric", year: "numeric" }) }</td>
                            </tr>
                            <tr style={{ backgroundColor: "#222" }}>
                                <th colSpan={3} style={{ fontSize: "125%" }}>Standouts</th>
                            </tr>
                            {(standouts || []).map(({ name, discipline, wins, losses }) =>
                                <tr key={`notablePlayer${name}`}>
                                    <td style={{ width: "34%" }}>{name}</td>
                                    <td>{discipline}</td>
                                    <td>{wins}-{losses}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </> }
            </div>
            { deletingSeason && <DeleteSeasonModal /> }
            { deletingTeam && <DeleteTeamModal /> }
        </TbTPage>
    )
}
