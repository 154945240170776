import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import Homepage from "./pages/Homepage/Homepage";
import Roster from "./pages/Roster/Roster";
import TeamDetail from "./components/TeamDetail/TeamDetail";
import RosterEditor from "./pages/RosterEditor/RosterEditor";
import Footer from "./components/Footer";
import Login from "./pages/Login/Login";
import TeamCreation from "./pages/TeamCreation/TeamCreation";
import Tournaments from "./pages/DualMeets/DualMeets";
import Matches from "./pages/Matches/Matches";
import About from "./pages/About/About";
import TournamentInfo from "./pages/DualMeetInfo/DualMeetInfo";
import MatchInfo from "./pages/MatchInfo/MatchInfo";
import MatchParticipants from "./pages/MatchParticipants/MatchParticipants";
import CreateTournament from "./pages/CreateTournament/CreateTournament";
import RequireAuthenticated from "./components/RequireAuthenticated/RequireAuthenticated";
import Navigation from "./components/Navigation";

import "./Home.css";

const Home = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Navigation />
            <Switch>
                <Route path="/" exact render={() => <LandingPage />} />
                <Route path="/login" exact render={() => <Login></Login>} />
                <Route path="/about" exact render={() => <About></About> } />
                <Route path="/createTeam" exact render={() => <TeamCreation></TeamCreation>} />
                <Route path="/teamData" exact render={() => <RequireAuthenticated><Homepage /></RequireAuthenticated>} />
                <Route path="/roster" render={() => <RequireAuthenticated><Roster /></RequireAuthenticated>} />
                <Route path="/rosterEditor" exact render={() => <RequireAuthenticated><RosterEditor /></RequireAuthenticated>} />
                <Route path="/dualMeets" render={() => <Tournaments></Tournaments>} />
                <Route path="/dualMeetInfo/:id" render={() => <TournamentInfo></TournamentInfo>} />
                <Route path="/tournaments" render={() => <Matches></Matches>} />
                <Route path="/tournamentInfo/:id" render={() => <MatchInfo></MatchInfo>} />
                <Route path="/tournamentParticipants/:id" render={() => <MatchParticipants></MatchParticipants>} />
                <Route path="/createTournament" render={() => <CreateTournament />} />
                <Route path="/teamDetail/:teamId" render={() => <TeamDetail />} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default Home;
