import { Button, Dropdown, Form, ListGroup, ListGroupItem, Toast } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react';
import { mdiDelete, mdiPencil } from '@mdi/js';
import "./RosterEditor.css";
import DB from "../../utils/database";
import Modal from "../../components/Modal/Modal";
import { ReduxState, updateMeetsThunk } from "../../utils/store";
import SeasonTeamSwitcher from "../../components/SeasonTeamSwitcher/SeasonTeamSwitcher";
import { CommonLoading } from "../../components/Loading/Loading";
import { enableScroll } from "../../utils/helpers";
import PermissionDenied from "../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../components/TbTPage/TbTPage";
import { TeamMember, Weapon } from "../../types";
import { saveAs } from 'file-saver';

interface UsersSectionProps {
    teamMembers: Record<string, TeamMember>;
    discipline: Weapon;
    onEdit: (l: TeamMember) => void;
    onDelete: (l: TeamMember) => void;
    updateStrip: (strip: "A" | "B" | "C" | null, l: TeamMember) => void;
}

type ActionQueue = (() => void)[];

function UsersSection (props: UsersSectionProps) {
    const { teamMembers, discipline, onEdit, onDelete, updateStrip } = props;
    const [expanded, setExpanded] = useState(true);
    const [fading, setFading] = useState(false);

    const handleToggle = () => {
        if (expanded) {
            setFading(true);
            setTimeout(() => {
                setExpanded(!expanded);
                setFading(false);
            }, 500);
        } else {
            setExpanded(true);
        }
    }

    return (
        <div className={`usersSection`}>
            <p className="rosterBlockHeader" onClick={handleToggle}>{ discipline }</p>
            { expanded && <div className={`rosterBlock ${fading && "fadingOut"}`}>
                { Object.values(teamMembers || {}).map((l, idx) => (
                    <div className="rosterItem" key={`iadsjfiajsdfijaisdjfi${idx}`}>
                        <div style={{ width: "90%", margin: "auto", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div>
                                <div style={{ width: "75px", display: "inline-block" }}>
                                    <Dropdown className="transparentDropdown" focusFirstItemOnShow={true}>
                                        <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                                            { l.role || "Sub" }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ fontSize: "inherit" }}>
                                            <Dropdown.Item onClick={() => updateStrip("A", l)} key={`updateStripA${l.id}`}>A</Dropdown.Item>
                                            <Dropdown.Item onClick={() => updateStrip("B", l)} key={`updateStripB${l.id}`}>B</Dropdown.Item>
                                            <Dropdown.Item onClick={() => updateStrip("C", l)} key={`updateStripC${l.id}`}>C</Dropdown.Item>
                                            <Dropdown.Item onClick={() => updateStrip(null, l)} key={`updateStripSub${l.id}`}>Sub</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                { l.firstName }{" "}{ l.lastName } <span className="teamMemberDesc"> - { l.gradYear }</span>
                            </div>
                            <div>
                                <Icon path={mdiPencil}
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                                    color="#EEE"
                                    className="deleteIconRoster"
                                    /*
                                    // @ts-ignore */
                                    onClick={ () => onEdit(l) }
                                />
                                <Icon path={mdiDelete}
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                                    color="#EEE"
                                    className="deleteIconRoster"
                                    /*
                                    // @ts-ignore */
                                    onClick={ () => onDelete(l) }
                                />
                            </div>
                        </div>
                    </div>
                )) }
            </div>}
        </div>

    );
}

export default function RosterEditor () {
    const storedTeamMembers = useSelector((s: ReduxState) => s.teamMembers);
    const [teamMembers, setTeamMembers] = useState(JSON.parse(JSON.stringify(useSelector((s: ReduxState) => s.teamMembers))));
    const teamID = useSelector((s: ReduxState) => Object.keys(s.editingTeamBehalf).length !== 0 ? s.editingTeamBehalf.teamID : s.teamID);
    const teamName = useSelector((s: ReduxState) => s.team);
    const team = useSelector((s: ReduxState) => s.team);
    const season = useSelector((s: ReduxState) => s.season);
    const subteam = useSelector((s: ReduxState) => s.subteam);
    const subteams = useSelector((s: ReduxState) => s.subteams);
    const loading = useSelector((s: ReduxState) => s.loading);
    const seasons = useSelector((s: ReduxState) => s.seasons);
    const isPublished = useSelector((s: ReduxState) => s.isPublished);
    const administratingTeams = useSelector((state: ReduxState) => state.administratingTeams);

    const possibleSeasons = ["2021-2022", "2022-2023", "2023-2024"];

    const availableSeasons = possibleSeasons.filter(l => !seasons.includes(l));

    useEffect(() => {
        setTeamMembers(storedTeamMembers);
    }, [storedTeamMembers]);

    const graduatingYear = new Date().getMonth() >= 7 ? new Date().getFullYear() + 4 : new Date().getFullYear() + 3

    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [lastname, setLastname] = useState("");
    const [graduationYear, setGraduationYear] = useState(graduatingYear.toString());
    const [weapon, setWeapon] = useState<Weapon | "Weapon">("Weapon");
    const [seasonToClone, setSeasonToClone] = useState("Don't clone data");
    const [newSeason, setNewSeason] = useState(availableSeasons.length === 0 ? "No available seasons!" : availableSeasons[0]);

    const [addingUser, setAddingUser] = useState(false);
    const [creatingUser, setCreatingUser] = useState<null | true>(null);
    const [editingUser, setEditingUser] = useState<TeamMember | null>(null);
    const [deletingUser, setDeletingUser] = useState<TeamMember | null | false>(null);
    const [savingChanges, setSavingChanges] = useState(false);
    const [cancellingChanges, setCancellingChanges] = useState(false);
    const [cloningSeason, setCloningSeason] = useState(false);
    const [exportingRoster, setExportingRoster] = useState(false);
    const [savingStatus, setSavingStatus] = useState("");

    const [changesExist, setChangesExist] = useState(false);
    const [actionQueue, setActionQueue] = useState<ActionQueue>([]);

    const [availableFencerList, setAvailableFencerList] = useState<{ fencerID: string; firstName: string; gradYear: string; lastName: string; id: string }[]>([]);
    const [fencerSearchQuery, setFencerSearchQuery] = useState("");

    const [toastOpen, setToastOpen] = useState(false);

    const latestValue = useRef(changesExist);

    const history = useHistory();

    latestValue.current = changesExist;

    const updateTeamData = () => {
        DB.getTeamFencersList(teamID, subteams.length > 1 ? subteam : "").then(f => {
            setAvailableFencerList(f.map(l => ({ ...l, fencerID: l.id })).filter(l => Number(l.gradYear) >= graduatingYear - 6));
        });
        DB.getTeamRosterForSeason(teamID, season, subteams.length > 1 ? subteam : "", roster => dispatch({ type: "updateRoster", teamRoster: roster }));
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        updateTeamData();
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subteam, subteams, season]);

    if (seasons.length === 0) {
        history.replace('/roster');
    }

    const alertUser = e => {
        if (latestValue.current) {
            e.preventDefault();
            e.returnValue = 'All changes will be lost. Would you like to cancel your changes?';
        }
    }

    if (!Object.keys(administratingTeams).length && !teamID && !loading) {
        return <PermissionDenied message="You have no teams to administrate at this time." promptTeamCreation={true} />;
    }

    const addFencer = async (fencer) => {
        if (weapon === "Weapon") {
            setToastOpen(true);
            return;
        }
        setChangesExist(true);
        teamMembers[weapon].push({
            displayName: `${fencer.firstName} ${fencer.lastName}`,
            firstName: fencer.firstName,
            gradYear: fencer.gradYear,
            id: fencer.fencerID,
            lastName: fencer.lastName,
            role: undefined,
            weapon: weapon
        });
        setTeamMembers(teamMembers);
        setActionQueue([...actionQueue, () => DB.addExitingFencerToSeason(teamID, season, weapon, fencer.fencerID, fencer.firstName, fencer.lastName, fencer.gradYear, subteams.length > 1 ? subteam : "")]);
        setAddingUser(false);
    };

    const createFencer = () => {
        setChangesExist(true);
        teamMembers[weapon].push({
            displayName: `${username} ${lastname}`,
            firstName: username,
            gradYear: graduationYear.toString(),
            id: Math.random().toString(),
            lastName: lastname,
            role: undefined,
            weapon: weapon
        });
        setTeamMembers(teamMembers);
        setActionQueue([...actionQueue, () => DB.addNewFencerToTeamAndSeason(teamID, team, season, weapon as Weapon, username, lastname, graduationYear, "", subteams.length > 1 ? subteam : "")]);
        setCreatingUser(null);
    };

    const editFencer = () => {
        if (!editingUser) {
            return;
        }
        setChangesExist(true);
        const user = teamMembers[editingUser.weapon].find(l => l.id === editingUser.id);
        user.firstName = username;
        user.lastName = lastname;
        user.gradYear = graduationYear;
        setTeamMembers({ ...teamMembers });
        setActionQueue([...actionQueue, () => DB.editFencer(teamID, editingUser.id, username, lastname, graduationYear)]);
        setEditingUser(null);
    }

    const deleteFencer = () => {
        if (!deletingUser) {
            return;
        }
        setChangesExist(true);
        enableScroll();
        teamMembers[deletingUser.weapon].splice(teamMembers[deletingUser.weapon].findIndex(l => l.id === deletingUser.id), 1);
        setTeamMembers(teamMembers);
        setActionQueue([...actionQueue, () => DB.removeFencerFromRoster(teamID, season, deletingUser.weapon, deletingUser.id, subteams.length > 1 ? subteam : "")]);
        setDeletingUser(null);
    }

    const cloneSeason = async () => {
        await DB.createTeamSeason(teamID, teamName, newSeason, subteams.length > 1 ? subteam : "", isPublished, seasonToClone);
        dispatch(updateMeetsThunk);
    }

    const saveChanges = async () => {
        setSavingStatus("Saving changes...");
        actionQueue.forEach(action => {
            action();
        });
        setActionQueue([]);
        dispatch(updateMeetsThunk);
        setChangesExist(false);
        setSavingStatus("");
        updateTeamData();
    }

    const deleteChanges = () => {
        setSavingStatus("Deleting changes...");
        setTeamMembers(JSON.parse(JSON.stringify(storedTeamMembers)));
        setActionQueue([]);
        dispatch(updateMeetsThunk);
        setChangesExist(false);
        setSavingStatus("");
    }

    const startEditing = v => {
        setEditingUser(v);
        setUsername(v.firstName);
        setLastname(v.lastName);
        setGraduationYear(v.gradYear);
    }

    const updateStrip = (strip, u) => {
        const newActions: ActionQueue = [];
        if (strip !== null) {
            for (const member of teamMembers[u.weapon]) {
                if (member.role === strip) {
                    member.role = null;
                    newActions.push(() => DB.setFencerRole(teamID, season, u.weapon, member.id, null, subteams.length > 1 ? subteam : ""));
                }
            }
        }
        const user = teamMembers[u.weapon].find(l => l.id === u.id);
        user.role = strip;
        setTeamMembers({ ...teamMembers });
        setActionQueue([...actionQueue, ...newActions, () => DB.setFencerRole(teamID, season, u.weapon, u.id, strip, subteams.length > 1 ? subteam : "")]);
    }

    const exportRoster = async () => {
        const csv: string = await DB.getTeamRosterAsCSV(teamID, season, subteam);

        saveAs(new Blob([csv]), `${team} Roster ${season}.csv`);
    };

    const disabled = !username || !lastname || !graduationYear || parseInt(graduationYear).toString() !== graduationYear;

    const availableFencers = availableFencerList.filter(l => `${l.firstName} ${l.lastName}`.toLowerCase().includes(fencerSearchQuery));

    return (
        <>
            <TbTPage>
                <div style={{ textAlign: "center", marginTop: 25 }}>
                    <Button style={{ margin: "15px 7px" }} onClick={() => setCreatingUser(true)}>Add New Fencer</Button>
                    <Button style={{ margin: "15px 7px" }} onClick={() => setAddingUser(true)}>Add Existing Fencer</Button>
                    <Button style={{ margin: "15px 7px" }} onClick={() => setCloningSeason(true)}>Create New Season</Button>
                </div>
                <div style={{ textAlign: "center" }}>
                    <Button variant="success" style={{ margin: "0 7px" }} onClick={() => { setSavingChanges(true) }}>Save Changes</Button>
                    <Button variant="danger" style={{ margin: "0 7px" }} onClick={() => setCancellingChanges(true)}>Discard Changes</Button>
                    <Button variant="warning" style={{ margin: "0 7px" }} onClick={() => setExportingRoster(true)}>Export Roster as CSV</Button>
                </div>
                <SeasonTeamSwitcher></SeasonTeamSwitcher>
                {
                    loading
                        ? <CommonLoading color="#714FCA" size="large" />
                        : <>
                            <UsersSection updateStrip={updateStrip} onEdit={startEditing} onDelete={setDeletingUser} teamMembers={teamMembers.Sabre?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Sabre"></UsersSection>
                            <UsersSection updateStrip={updateStrip} onEdit={startEditing} onDelete={setDeletingUser} teamMembers={teamMembers.Foil?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Foil"></UsersSection>
                            <UsersSection updateStrip={updateStrip} onEdit={startEditing} onDelete={setDeletingUser} teamMembers={teamMembers.Epee?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Epee"></UsersSection>
                        </>
                }
                { addingUser && <Modal close={() => setAddingUser(false)}>
                    <div style={{ position: "absolute", left: "calc(50% - 175px)", zIndex: "10" }}>
                        <Toast bg="danger" onClose={() => setToastOpen(false)} show={toastOpen} delay={5000} autohide>
                            <Toast.Header>
                                <strong className="me-auto">Fencer addition failed</strong>
                            </Toast.Header>
                            <Toast.Body>You need to set a weapon to assign this fencer to!</Toast.Body>
                        </Toast>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3>Add existing fencer</h3>
                            <input placeholder="Find fencer" onChange={(e) => setFencerSearchQuery(e.target.value)} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ margin: "0 10px 0 0" }}>Add fencer to: </p>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    { weapon }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(b) => { setWeapon("Sabre") }}>Sabre</Dropdown.Item>
                                    <Dropdown.Item onClick={(b) => { setWeapon("Foil") }}>Foil</Dropdown.Item>
                                    <Dropdown.Item onClick={(b) => { setWeapon("Epee") }}>Epee</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    { availableFencers.length === 0 &&
                        <p style={{ textAlign: "center", color: "#999", fontSize: 24 }}>No available fencers to add</p>
                    }
                    <ListGroup style={{ height: 400, overflow: "auto" }}>
                        { availableFencers.map(l => (
                            <ListGroupItem action onClick={() => { addFencer(l); enableScroll() }} key={`fencerInListjasdoifj${l.fencerID}`}>{l.firstName} {l.lastName} - {l.gradYear}</ListGroupItem>
                        )) }
                    </ListGroup>
                </Modal> }
                { creatingUser && <Modal close={() => setCreatingUser(null)}>
                    <Form>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First name <span className="requiredStar">*</span></Form.Label>
                            <Form.Control type="text" placeholder="First name" onChange={e => setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                            <Form.Label>Last name <span className="requiredStar">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Last name" onChange={e => setLastname(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formGradYear">
                            <Form.Label>Graduation year <span className="requiredStar">*</span></Form.Label>
                            <Form.Control type="number" placeholder="Graduation year" defaultValue={graduationYear} onChange={e => setGraduationYear(e.target.value)} />
                        </Form.Group>

                        <Dropdown style={{ marginTop: 15 }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                { weapon }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={e => { setWeapon("Sabre") }}>Sabre</Dropdown.Item>
                                <Dropdown.Item onClick={e => { setWeapon("Foil") }}>Foil</Dropdown.Item>
                                <Dropdown.Item onClick={e => { setWeapon("Epee") }}>Epee</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Button variant="primary" onClick={() => { createFencer(); enableScroll() }} className={`${disabled || weapon === "Weapon" ? "disabledButton" : ""}`} disabled={disabled || weapon === "Weapon"} style={{ marginTop: 15 }}>
                            Submit
                        </Button>
                    </Form>
                </Modal> }
                { editingUser && <Modal close={() => setEditingUser(null)}>
                    <Form>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text" placeholder="First name" value={username} onChange={e => setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text" placeholder="Last name" value={lastname} onChange={e => setLastname(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formGradYear">
                            <Form.Label>Graduation year</Form.Label>
                            <Form.Control type="number" placeholder="Graduation year" value={graduationYear} onChange={e => setGraduationYear(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" className={`${disabled ? "disabledButton" : ""}`} disabled={disabled} style={{ marginTop: 15 }} onClick={() => { editFencer(); enableScroll() }}>
                            Submit
                        </Button>
                    </Form>
                </Modal> }
                { deletingUser && <Modal close={() => setDeletingUser(false)}>
                    <h2>Are you sure you want to remove this fencer from the roster?</h2>
                    <Button variant="danger" onClick={deleteFencer}>Delete fencer</Button>
                </Modal> }
                { savingChanges && <Modal close={() => setSavingChanges(false)}>
                    <h2>All changes will be permanent. Would you like to save your changes?</h2>
                    <div style={{ display: "flex" }}>
                        <Button variant="success" onClick={() => { saveChanges(); setSavingChanges(false); enableScroll() }} style={{ marginRight: 10 }}>Yes</Button>
                        <Button variant="danger" onClick={() => { setSavingChanges(false); enableScroll() }}>No</Button>
                    </div>
                    { savingStatus !== "" && <div style={{ position: "absolute", left: "calc(50% - 175px)", zIndex: "10" }}>
                        <Toast bg="danger" onClose={() => setToastOpen(false)} show={toastOpen} delay={5000} autohide>
                            <Toast.Header>
                                <strong className="me-auto">{savingStatus}</strong>
                            </Toast.Header>
                        </Toast>
                    </div> }
                </Modal> }
                { cancellingChanges && <Modal close={() => setCancellingChanges(false)}>
                    <h2>All changes will be lost. Would you like to cancel your changes?</h2>
                    <div style={{ display: "flex" }}>
                        <Button variant="success" onClick={() => { deleteChanges(); setCancellingChanges(false); enableScroll() }} style={{ marginRight: 10 }}>Yes</Button>
                        <Button variant="danger" onClick={() => { setCancellingChanges(false); enableScroll() }}>No</Button>
                    </div>
                    { savingStatus !== "" && <div style={{ position: "absolute", left: "calc(50% - 175px)", zIndex: "10" }}>
                        <Toast bg="danger" onClose={() => setToastOpen(false)} show={savingStatus !== ""} autohide>
                            <Toast.Header>
                                <strong className="me-auto">{savingStatus}</strong>
                            </Toast.Header>
                        </Toast>
                    </div> }
                </Modal> }
                { cloningSeason && <Modal close={() => setCloningSeason(false)}>
                    <h2>Create new season</h2>
                    <div style={{ display: "flex", marginBottom: 20 }}>
                        <div style={{ marginRight: 10 }}>
                            <p>Select new season</p>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    { newSeason }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    { availableSeasons.map(l => <Dropdown.Item key={`oerpoa${l}`} onClick={(b) => { setNewSeason(l) }}>{l}</Dropdown.Item> ) }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <p>Select season to clone</p>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    { seasonToClone }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    { seasons.map(l => <Dropdown.Item key={`oerpgraoa${l}`} onClick={(b) => { setSeasonToClone(l) }}>{l}</Dropdown.Item> ) }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button disabled={newSeason === "No available seasons!"} variant="success" onClick={() => { setCloningSeason(false); cloneSeason(); enableScroll() }} style={{ marginRight: 10 }}>Save</Button>
                        <Button variant="danger" onClick={() => { setCloningSeason(false); enableScroll() }}>Cancel</Button>
                    </div>
                </Modal> }
                { exportingRoster && <Modal close={() => setExportingRoster(false)}>
                    <h2>Export Roster as CSV</h2>
                    <h5>By exporting this roster, you will get the roster for this season only exported in CSV format. Note that this does not export personal records or bout data.</h5>
                    <div style={{ display: "flex" }}>
                        <Button variant="success" onClick={() => { setExportingRoster(false); exportRoster(); enableScroll() }} style={{ marginRight: 10 }}>Download as CSV</Button>
                        <Button variant="danger" onClick={() => { setExportingRoster(false); enableScroll() }}>Cancel</Button>
                    </div>
                </Modal> }
            </TbTPage>
        </>
    )
}
