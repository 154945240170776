export interface GeographyData {
    countryCode: string;
    country: string;
    regions: string[];
}

/**
 * List of geographical region data for each country
 */
const geography: GeographyData[] = [
    {
        countryCode: "DZ",
        country: "Algeria",
        regions: [
            "",
            "Adrar",
            "Ain Defla",
            "Ain Temouchent",
            "Alger",
            "Annaba",
            "Batna",
            "Bechar",
            "Bejaia",
            "Biskra",
            "Blida",
            "Bordj Bou Arreridj",
            "Bouira",
            "Boumerdes",
            "Chlef",
            "Constantine",
            "Djelfa",
            "El Bayadh",
            "El Oued",
            "El Tarf",
            "Ghardaia",
            "Guelma",
            "Illizi",
            "Jijel",
            "Khenchela",
            "Laghouat",
            "Muaskar",
            "Medea",
            "Mila",
            "Mostaganem",
            "M'Sila",
            "Naama",
            "Oran",
            "Ouargla",
            "Oum el Bouaghi",
            "Relizane",
            "Saida",
            "Setif",
            "Sidi Bel Abbes",
            "Skikda",
            "Souk Ahras",
            "Tamanghasset",
            "Tebessa",
            "Tiaret",
            "Tindouf",
            "Tipaza",
            "Tissemsilt",
            "Tizi Ouzou",
            "Tlemcen",
        ],
    },
    {
        countryCode: "AR",
        country: "Argentina",
        regions: [
            "",
            "Buenos Aires",
            "Buenos Aires Capital",
            "Catamarca",
            "Chaco",
            "Chubut",
            "Cordoba",
            "Corrientes",
            "Entre Rios",
            "Formosa",
            "Jujuy",
            "La Pampa",
            "La Rioja",
            "Mendoza",
            "Misiones",
            "Neuquen",
            "Rio Negro",
            "Salta",
            "San Juan",
            "San Luis",
            "Santa Cruz",
            "Santa Fe",
            "Santiago del Estero",
            "Tierra del Fuego",
            "Tucuman",
        ],
    },
    {
        countryCode: "AU",
        country: "Australia",
        regions: [
            "",
            "Australian Capital Territory",
            "New South Wales",
            "Northern Territory",
            "Queensland",
            "South Australia",
            "Tasmania",
            "Victoria",
            "Western Australia",
        ],
    },
    {
        countryCode: "AT",
        country: "Austria",
        regions: [
            "",
            "Burgenland",
            "Kaernten",
            "Niederoesterreich",
            "Oberoesterreich",
            "Salzburg",
            "Steiermark",
            "Tirol",
            "Vorarlberg",
            "Wien",
        ],
    },
    {
        countryCode: "AZ",
        country: "Azerbaijan",
        regions: [
            "",
            "Abseron Rayonu",
            "Agcabadi Rayonu",
            "Agdam Rayonu",
            "Agdas Rayonu",
            "Agstafa Rayonu",
            "Agsu Rayonu",
            "Astara Rayonu",
            "Balakan Rayonu",
            "Barda Rayonu",
            "Beylaqan Rayonu",
            "Bilasuvar Rayonu",
            "Cabrayil Rayonu",
            "Calilabad Rayonu",
            "Daskasan Rayonu",
            "Davaci Rayonu",
            "Fuzuli Rayonu",
            "Gadabay Rayonu",
            "Goranboy Rayonu",
            "Goycay Rayonu",
            "Haciqabul Rayonu",
            "Imisli Rayonu",
            "Ismayilli Rayonu",
            "Kalbacar Rayonu",
            "Kurdamir Rayonu",
            "Lacin Rayonu",
            "Lankaran Rayonu",
            "Lerik Rayonu",
            "Masalli Rayonu",
            "Neftcala Rayonu",
            "Oguz Rayonu",
            "Qabala Rayonu",
            "Qax Rayonu",
            "Qazax Rayonu",
            "Qobustan Rayonu",
            "Quba Rayonu",
            "Qubadli Rayonu",
            "Qusar Rayonu",
            "Saatli Rayonu",
            "Sabirabad Rayonu",
            "Saki Rayonu",
            "Salyan Rayonu",
            "Samaxi Rayonu",
            "Samkir Rayonu",
            "Samux Rayonu",
            "Siyazan Rayonu",
            "Susa Rayonu",
            "Tartar Rayonu",
            "Tovuz Rayonu",
            "Ucar Rayonu",
            "Xacmaz Rayonu",
            "Xanlar Rayonu",
            "Xizi Rayonu",
            "Xocali Rayonu",
            "Xocavand Rayonu",
            "Yardimli Rayonu",
            "Yevlax Rayonu",
            "Zangilan Rayonu",
            "Zaqatala Rayonu",
            "Zardab Rayonu",
            "Ali Bayramli Sahari",
            "Baki Sahari",
            "Ganca Sahari",
            "Lankaran Sahari",
            "Mingacevir Sahari",
            "Naftalan Sahari",
            "Saki Sahari",
            "Sumqayit Sahari",
            "Susa Sahari",
            "Xankandi Sahari",
            "Yevlax Sahari",
            "Naxcivan Muxtar",
        ],
    },
    {
        countryCode: "BY",
        country: "Belarus",
        regions: [
            "",
            "Brest",
            "Homyel",
            "Horad Minsk",
            "Hrodna",
            "Mahilyow",
            "Minsk",
            "Vitsyebsk",
        ],
    },
    {
        countryCode: "BE",
        country: "Belgium",
        regions: [
            "",
            "Antwerpen",
            "Brabant Wallon",
            "Brussels",
            "Flanders",
            "Hainaut",
            "Liege",
            "Limburg",
            "Luxembourg",
            "Namur",
            "Oost-Vlaanderen",
            "Vlaams-Brabant",
            "Wallonia",
            "West-Vlaanderen",
        ],
    },
    {
        countryCode: "BJ",
        country: "Benin",
        regions: [
            "",
            "Alibori",
            "Atakora",
            "Atlantique",
            "Borgou",
            "Collines",
            "Donga",
            "Kouffo",
            "Littoral",
            "Mono",
            "Oueme",
            "Plateau",
            "Zou",
        ],
    },
    {
        countryCode: "BR",
        country: "Brazil",
        regions: [
            "",
            "Acre",
            "Alagoas",
            "Amapa",
            "Amazonas",
            "Bahia",
            "Ceara",
            "Distrito Federal",
            "Espirito Santo",
            "Goias",
            "Maranhao",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Para",
            "Paraiba",
            "Parana",
            "Pernambuco",
            "Piaui",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rondonia",
            "Roraima",
            "Santa Catarina",
            "Sao Paulo",
            "Sergipe",
            "Tocantins",
        ],
    },
    {
        countryCode: "BG",
        country: "Bulgaria",
        regions: [
            "",
            "Blagoevgrad",
            "Burgas",
            "Dobrich",
            "Gabrovo",
            "Khaskovo",
            "Kurdzhali",
            "Kyustendil",
            "Lovech",
            "Montana",
            "Pazardzhik",
            "Pernik",
            "Pleven",
            "Plovdiv",
            "Razgrad",
            "Ruse",
            "Shumen",
            "Silistra",
            "Sliven",
            "Smolyan",
            "Sofiya",
            "Sofiya-Grad",
            "Stara Zagora",
            "Turgovishte",
            "Varna",
            "Veliko Turnovo",
            "Vidin",
            "Vratsa",
            "Yambol",
        ],
    },
    {
        countryCode: "CA",
        country: "Canada",
        regions: [
            "",
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon Territory",
        ],
    },
    {
        countryCode: "CN",
        country: "China",
        regions: [
            "",
            "Anhui",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hubei",
            "Hunan",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Liaoning",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanxi",
            "Sichuan",
            "Yunnan",
            "Zhejiang",
            "Guangxi",
            "Nei Mongol",
            "Ningxia",
            "Xinjiang",
            "Xizang (Tibet)",
            "Beijing",
            "Chongqing",
            "Shanghai",
            "Tianjin",
        ],
    },
    {
        countryCode: "CZ",
        country: "Czech Republic",
        regions: [
            "",
            "Jihocesky Kraj",
            "Jihomoravsky Kraj",
            "Karlovarsky Kraj",
            "Kralovehradecky Kraj",
            "Liberecky Kraj",
            "Moravskoslezsky Kraj",
            "Olomoucky Kraj",
            "Pardubicky Kraj",
            "Plzensky Kraj",
            "Praha",
            "Stredocesky Kraj",
            "Ustecky Kraj",
            "Vysocina",
            "Zlinsky Kraj",
        ],
    },
    {
        countryCode: "CU",
        country: "Cuba",
        regions: [
            "",
            "Camaguey",
            "Ciego de Avila",
            "Cienfuegos",
            "Ciudad de La Habana",
            "Granma",
            "Guantanamo",
            "Holguin",
            "Isla de la Juventud",
            "La Habana",
            "Las Tunas",
            "Matanzas",
            "Pinar del Rio",
            "Sancti Spiritus",
            "Santiago de Cuba",
            "Villa Clara",
        ],
    },
    {
        countryCode: "DK",
        country: "Denmark",
        regions: [
            "",
            "Arhus",
            "Bornholm",
            "Frederiksberg",
            "Frederiksborg",
            "Fyn",
            "Kobenhavn",
            "Kobenhavns",
            "Nordjylland",
            "Ribe",
            "Ringkobing",
            "Roskilde",
            "Sonderjylland",
            "Storstrom",
            "Vejle",
            "Vestsjalland",
            "Viborg",
        ],
    },
    {
        countryCode: "EG",
        country: "Egypt",
        regions: [
            "",
            "Ad Daqahliyah",
            "Al Bahr al Ahmar",
            "Al Buhayrah",
            "Al Fayyum",
            "Al Gharbiyah",
            "Al Iskandariyah",
            "Al Isma'iliyah",
            "Al Jizah",
            "Al Minufiyah",
            "Al Minya",
            "Al Qahirah",
            "Al Qalyubiyah",
            "Al Wadi al Jadid",
            "Ash Sharqiyah",
            "As Suways",
            "Aswan",
            "Asyut",
            "Bani Suwayf",
            "Bur Sa'id",
            "Dumyat",
            "Janub Sina'",
            "Kafr ash Shaykh",
            "Matruh",
            "Qina",
            "Shamal Sina'",
            "Suhaj",
        ],
    },
    {
        countryCode: "EE",
        country: "Estonia",
        regions: [
            "",
            "Harjumaa (Tallinn)",
            "Hiiumaa (Kardla)",
            "Ida-Virumaa (Johvi)",
            "Jarvamaa (Paide)",
            "Jogevamaa (Jogeva)",
            "Laanemaa (Haapsalu)",
            "Laane-Virumaa (Rakvere)",
            "Parnumaa (Parnu)",
            "Polvamaa (Polva)",
            "Raplamaa (Rapla)",
            "Saaremaa (Kuressaare)",
            "Tartumaa (Tartu)",
            "Valgamaa (Valga)",
            "Viljandimaa (Viljandi)",
            "Vorumaa (Voru)",
        ],
    },
    {
        countryCode: "FR",
        country: "France",
        regions: [
            "",
            "Alsace",
            "Aquitaine",
            "Auvergne",
            "Basse-Normandie",
            "Bourgogne",
            "Bretagne",
            "Centre",
            "Champagne-Ardenne",
            "Corse",
            "Franche-Comte",
            "Haute-Normandie",
            "Ile-de-France",
            "Languedoc-Roussillon",
            "Limousin",
            "Lorraine",
            "Midi-Pyrenees",
            "Nord-Pas-de-Calais",
            "Pays de la Loire",
            "Picardie",
            "Poitou-Charentes",
            "Provence-Alpes-Cote d'Azur",
            "Rhone-Alpes",
        ],
    },
    {
        countryCode: "GE",
        country: "Georgia",
        regions: [""],
    },
    {
        countryCode: "DE",
        country: "Germany",
        regions: [
            "",
            "Baden-Wuerttemberg",
            "Bayern",
            "Berlin",
            "Brandenburg",
            "Bremen",
            "Hamburg",
            "Hessen",
            "Mecklenburg-Vorpommern",
            "Niedersachsen",
            "Nordrhein-Westfalen",
            "Rheinland-Pfalz",
            "Saarland",
            "Sachsen",
            "Sachsen-Anhalt",
            "Schleswig-Holstein",
            "Thueringen",
        ],
    },
    {
        countryCode: "GR",
        country: "Greece",
        regions: [
            "",
            "Agion Oros",
            "Achaia",
            "Aitolia kai Akarmania",
            "Argolis",
            "Arkadia",
            "Arta",
            "Attiki",
            "Chalkidiki",
            "Chanion",
            "Chios",
            "Dodekanisos",
            "Drama",
            "Evros",
            "Evrytania",
            "Evvoia",
            "Florina",
            "Fokidos",
            "Fthiotis",
            "Grevena",
            "Ileia",
            "Imathia",
            "Ioannina",
            "Irakleion",
            "Karditsa",
            "Kastoria",
            "Kavala",
            "Kefallinia",
            "Kerkyra",
            "Kilkis",
            "Korinthia",
            "Kozani",
            "Kyklades",
            "Lakonia",
            "Larisa",
            "Lasithi",
            "Lefkas",
            "Lesvos",
            "Magnisia",
            "Messinia",
            "Pella",
            "Pieria",
            "Preveza",
            "Rethynnis",
            "Rodopi",
            "Samos",
            "Serrai",
            "Thesprotia",
            "Thessaloniki",
            "Trikala",
            "Voiotia",
            "Xanthi",
            "Zakynthos",
        ],
    },
    {
        countryCode: "HU",
        country: "Hungary",
        regions: [
            "",
            "Bacs-Kiskun",
            "Baranya",
            "Bekes",
            "Borsod-Abauj-Zemplen",
            "Csongrad",
            "Fejer",
            "Gyor-Moson-Sopron",
            "Hajdu-Bihar",
            "Heves",
            "Jasz-Nagykun-Szolnok",
            "Komarom-Esztergom",
            "Nograd",
            "Pest",
            "Somogy",
            "Szabolcs-Szatmar-Bereg",
            "Tolna",
            "Vas",
            "Veszprem",
            "Zala",
        ],
    },
    {
        countryCode: "IN",
        country: "India",
        regions: [
            "",
            "Andaman and Nicobar Islands",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chandigarh",
            "Chhattisgarh",
            "Dadra and Nagar Haveli",
            "Daman and Diu",
            "Delhi",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jammu and Kashmir",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Lakshadweep",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Orissa",
            "Pondicherry",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttaranchal",
            "Uttar Pradesh",
            "West Bengal",
        ],
    },
    {
        countryCode: "IR",
        country: "Iran",
        regions: [
            "",
            "Ardabil",
            "Azarbayjan-e Gharbi",
            "Azarbayjan-e Sharqi",
            "Bushehr",
            "Chahar Mahall va Bakhtiari",
            "Esfahan",
            "Fars",
            "Gilan",
            "Golestan",
            "Hamadan",
            "Hormozgan",
            "Ilam",
            "Kerman",
            "Kermanshah",
            "Khorasan-e Janubi",
            "Khorasan-e Razavi",
            "Khorasan-e Shemali",
            "Khuzestan",
            "Kohgiluyeh va Buyer Ahmad",
            "Kordestan",
            "Lorestan",
            "Markazi",
            "Mazandaran",
            "Qazvin",
            "Qom",
            "Semnan",
            "Sistan va Baluchestan",
            "Tehran",
            "Yazd",
            "Zanjan",
        ],
    },
    {
        countryCode: "IT",
        country: "Italy",
        regions: [
            "",
            "Abruzzo",
            "Basilicata",
            "Calabria",
            "Campania",
            "Emilia-Romagna",
            "Friuli-Venezia Giulia",
            "Lazio",
            "Liguria",
            "Lombardia",
            "Marche",
            "Molise",
            "Piemonte",
            "Puglia",
            "Sardegna",
            "Sicilia",
            "Toscana",
            "Trentino-Alto Adige",
            "Umbria",
            "Valle d'Aosta",
            "Veneto",
        ],
    },
    {
        countryCode: "CI",
        country: "Ivory Coast",
        regions: [""],
    },
    {
        countryCode: "JP",
        country: "Japan",
        regions: [
            "",
            "Aichi",
            "Akita",
            "Aomori",
            "Chiba",
            "Ehime",
            "Fukui",
            "Fukuoka",
            "Fukushima",
            "Gifu",
            "Gumma",
            "Hiroshima",
            "Hokkaido",
            "Hyogo",
            "Ibaraki",
            "Ishikawa",
            "Iwate",
            "Kagawa",
            "Kagoshima",
            "Kanagawa",
            "Kochi",
            "Kumamoto",
            "Kyoto",
            "Mie",
            "Miyagi",
            "Miyazaki",
            "Nagano",
            "Nagasaki",
            "Nara",
            "Niigata",
            "Oita",
            "Okayama",
            "Okinawa",
            "Osaka",
            "Saga",
            "Saitama",
            "Shiga",
            "Shimane",
            "Shizuoka",
            "Tochigi",
            "Tokushima",
            "Tokyo",
            "Tottori",
            "Toyama",
            "Wakayama",
            "Yamagata",
            "Yamaguchi",
            "Yamanashi",
        ],
    },
    {
        countryCode: "KZ",
        country: "Kazakhstan",
        regions: [
            "",
            "Almaty Oblysy",
            "Almaty Qalasy",
            "Aqmola Oblysy",
            "Aqtobe Oblysy",
            "Astana Qalasy",
            "Atyrau Oblysy",
            "Batys Qazaqstan Oblysy",
            "Bayqongyr Qalasy",
            "Mangghystau Oblysy",
            "Ongtustik Qazaqstan Oblysy",
            "Pavlodar Oblysy",
            "Qaraghandy Oblysy",
            "Qostanay Oblysy",
            "Qyzylorda Oblysy",
            "Shyghys Qazaqstan Oblysy",
            "Soltustik Qazaqstan Oblysy",
            "Zhambyl Oblysy",
        ],
    },
    {
        countryCode: "KP",
        country: "North Korea",
        regions: [
            "",
            "Chagang",
            "North Hamgyong",
            "South Hamgyong",
            "North Hwanghae",
            "South Hwanghae",
            "Kangwon",
            "North P'yongan",
            "South P'yongan",
            "Yanggang",
            "Kaesong",
            "Najin",
            "Namp'o",
            "Pyongyang",
        ],
    },
    {
        countryCode: "KR",
        country: "South Korea",
        regions: [
            "",
            "Seoul",
            "Busan City",
            "Daegu City",
            "Incheon City",
            "Gwangju City",
            "Daejeon City",
            "Ulsan",
            "Gyeonggi Province",
            "Gangwon Province",
            "North Chungcheong Province",
            "South Chungcheong Province",
            "North Jeolla Province",
            "South Jeolla Province",
            "North Gyeongsang Province",
            "South Gyeongsang Province",
            "Jeju",
        ],
    },
    {
        countryCode: "KW",
        country: "Kuwait",
        regions: [
            "",
            "Al Ahmadi",
            "Al Farwaniyah",
            "Al Asimah",
            "Al Jahra",
            "Hawalli",
            "Mubarak Al-Kabeer",
        ],
    },
    {
        countryCode: "LB",
        country: "Lebanon",
        regions: [
            "",
            "Beyrouth",
            "Beqaa",
            "Liban-Nord",
            "Liban-Sud",
            "Mont-Liban",
            "Nabatiye",
        ],
    },
    {
        countryCode: "LU",
        country: "Luxembourg",
        regions: ["", "Diekirch", "Grevenmacher", "Luxembourg"],
    },
    {
        countryCode: "MX",
        country: "Mexico",
        regions: [
            "",
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila de Zaragoza",
            "Colima",
            "Distrito Federal",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "Mexico",
            "Michoacan de Ocampo",
            "Morelos",
            "Nayarit",
            "Nuevo Leon",
            "Oaxaca",
            "Puebla",
            "Queretaro de Arteaga",
            "Quintana Roo",
            "San Luis Potosi",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz-Llave",
            "Yucatan",
            "Zacatecas",
        ],
    },
    {
        countryCode: "MC",
        country: "Monaco",
        regions: [""],
    },
    {
        countryCode: "MA",
        country: "Morocco",
        regions: [
            "",
            "Agadir",
            "Al Hoceima",
            "Azilal",
            "Beni Mellal",
            "Ben Slimane",
            "Boulemane",
            "Casablanca",
            "Chaouen",
            "El Jadida",
            "El Kelaa des Sraghna",
            "Er Rachidia",
            "Essaouira",
            "Fes",
            "Figuig",
            "Guelmim",
            "Ifrane",
            "Kenitra",
            "Khemisset",
            "Khenifra",
            "Khouribga",
            "Laayoune",
            "Larache",
            "Marrakech",
            "Meknes",
            "Nador",
            "Ouarzazate",
            "Oujda",
            "Rabat-Sale",
            "Safi",
            "Settat",
            "Sidi Kacem",
            "Tangier",
            "Tan-Tan",
            "Taounate",
            "Taroudannt",
            "Tata",
            "Taza",
            "Tetouan",
            "Tiznit",
        ],
    },
    {
        countryCode: "NL",
        country: "Netherlands",
        regions: [
            "",
            "Drenthe",
            "Flevoland",
            "Friesland",
            "Gelderland",
            "Groningen",
            "Limburg",
            "Noord-Brabant",
            "Noord-Holland",
            "Overijssel",
            "Utrecht",
            "Zeeland",
            "Zuid-Holland",
        ],
    },
    {
        countryCode: "PA",
        country: "Panama",
        regions: [
            "",
            "Bocas del Toro",
            "Chiriqui",
            "Cocle",
            "Colon",
            "Darien",
            "Herrera",
            "Los Santos",
            "Panama",
            "San Blas",
            "Veraguas",
        ],
    },
    {
        countryCode: "PL",
        country: "Poland",
        regions: [
            "",
            "Greater Poland (Wielkopolskie)",
            "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
            "Lesser Poland (Malopolskie)",
            "Lodz (Lodzkie)",
            "Lower Silesian (Dolnoslaskie)",
            "Lublin (Lubelskie)",
            "Lubusz (Lubuskie)",
            "Masovian (Mazowieckie)",
            "Opole (Opolskie)",
            "Podlasie (Podlaskie)",
            "Pomeranian (Pomorskie)",
            "Silesian (Slaskie)",
            "Subcarpathian (Podkarpackie)",
            "Swietokrzyskie (Swietokrzyskie)",
            "Warmian-Masurian (Warminsko-Mazurskie)",
            "West Pomeranian (Zachodniopomorskie)",
        ],
    },
    {
        countryCode: "PT",
        country: "Portugal",
        regions: [
            "",
            "Aveiro",
            "Acores",
            "Beja",
            "Braga",
            "Braganca",
            "Castelo Branco",
            "Coimbra",
            "Evora",
            "Faro",
            "Guarda",
            "Leiria",
            "Lisboa",
            "Madeira",
            "Portalegre",
            "Porto",
            "Santarem",
            "Setubal",
            "Viana do Castelo",
            "Vila Real",
            "Viseu",
        ],
    },
    {
        countryCode: "RO",
        country: "Romania",
        regions: [
            "",
            "Alba",
            "Arad",
            "Arges",
            "Bacau",
            "Bihor",
            "Bistrita-Nasaud",
            "Botosani",
            "Braila",
            "Brasov",
            "Bucuresti",
            "Buzau",
            "Calarasi",
            "Caras-Severin",
            "Cluj",
            "Constanta",
            "Covasna",
            "Dimbovita",
            "Dolj",
            "Galati",
            "Gorj",
            "Giurgiu",
            "Harghita",
            "Hunedoara",
            "Ialomita",
            "Iasi",
            "Ilfov",
            "Maramures",
            "Mehedinti",
            "Mures",
            "Neamt",
            "Olt",
            "Prahova",
            "Salaj",
            "Satu Mare",
            "Sibiu",
            "Suceava",
            "Teleorman",
            "Timis",
            "Tulcea",
            "Vaslui",
            "Vilcea",
            "Vrancea",
        ],
    },
    {
        countryCode: "RU",
        country: "Russia",
        regions: [
            "",
            "Amur",
            "Arkhangel'sk",
            "Astrakhan'",
            "Belgorod",
            "Bryansk",
            "Chelyabinsk",
            "Chita",
            "Irkutsk",
            "Ivanovo",
            "Kaliningrad",
            "Kaluga",
            "Kamchatka",
            "Kemerovo",
            "Kirov",
            "Kostroma",
            "Kurgan",
            "Kursk",
            "Leningrad",
            "Lipetsk",
            "Magadan",
            "Moscow",
            "Murmansk",
            "Nizhniy Novgorod",
            "Novgorod",
            "Novosibirsk",
            "Omsk",
            "Orenburg",
            "Orel",
            "Penza",
            "Perm'",
            "Pskov",
            "Rostov",
            "Ryazan'",
            "Sakhalin",
            "Samara",
            "Saratov",
            "Smolensk",
            "Sverdlovsk",
            "Tambov",
            "Tomsk",
            "Tula",
            "Tver'",
            "Tyumen'",
            "Ul'yanovsk",
            "Vladimir",
            "Volgograd",
            "Vologda",
            "Voronezh",
            "Yaroslavl'",
            "Adygeya",
            "Altay",
            "Bashkortostan",
            "Buryatiya",
            "Chechnya",
            "Chuvashiya",
            "Dagestan",
            "Ingushetiya",
            "Kabardino-Balkariya",
            "Kalmykiya",
            "Karachayevo-Cherkesiya",
            "Kareliya",
            "Khakasiya",
            "Komi",
            "Mariy-El",
            "Mordoviya",
            "Sakha",
            "North Ossetia",
            "Tatarstan",
            "Tyva",
            "Udmurtiya",
            "Aga Buryat",
            "Chukotka",
            "Evenk",
            "Khanty-Mansi",
            "Komi-Permyak",
            "Koryak",
            "Nenets",
            "Taymyr",
            "Ust'-Orda Buryat",
            "Yamalo-Nenets",
            "Altay",
            "Khabarovsk",
            "Krasnodar",
            "Krasnoyarsk",
            "Primorskiy",
            "Stavropol'",
            "Moscow",
            "St. Petersburg",
            "Yevrey",
        ],
    },
    {
        countryCode: "SA",
        country: "Saudi Arabia",
        regions: [
            "",
            "Al Bahah",
            "Al Hudud ash Shamaliyah",
            "Al Jawf",
            "Al Madinah",
            "Al Qasim",
            "Ar Riyad",
            "Ash Sharqiyah",
            "'Asir",
            "Ha'il",
            "Jizan",
            "Makkah",
            "Najran",
            "Tabuk",
        ],
    },
    {
        countryCode: "SN",
        country: "Senegal",
        regions: [
            "",
            "Dakar",
            "Diourbel",
            "Fatick",
            "Kaolack",
            "Kolda",
            "Louga",
            "Matam",
            "Saint-Louis",
            "Tambacounda",
            "Thies",
            "Ziguinchor",
        ],
    },
    {
        countryCode: "ZA",
        country: "South Africa",
        regions: [
            "",
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "North-West",
            "Northern Cape",
            "Western Cape",
        ],
    },
    {
        countryCode: "ES",
        country: "Spain",
        regions: [
            "",
            "Andalucia",
            "Aragon",
            "Asturias",
            "Baleares",
            "Ceuta",
            "Canarias",
            "Cantabria",
            "Castilla-La Mancha",
            "Castilla y Leon",
            "Cataluna",
            "Comunidad Valenciana",
            "Extremadura",
            "Galicia",
            "La Rioja",
            "Madrid",
            "Melilla",
            "Murcia",
            "Navarra",
            "Pais Vasco",
        ],
    },
    {
        countryCode: "SE",
        country: "Sweden",
        regions: [
            "",
            "Blekinge",
            "Dalarna",
            "Gävleborg",
            "Gotland",
            "Halland",
            "Jämtland",
            "Jönköping",
            "Kalmar",
            "Kronoberg",
            "Norrbotten",
            "Örebro",
            "Östergötland",
            "Skåne",
            "Södermanland",
            "Stockholm",
            "Uppsala",
            "Värmland",
            "Västerbotten",
            "Västernorrland",
            "Västmanland",
            "Västra Götaland",
        ],
    },
    {
        countryCode: "CH",
        country: "Switzerland",
        regions: [
            "",
            "Aargau",
            "Appenzell Ausser-Rhoden",
            "Appenzell Inner-Rhoden",
            "Basel-Landschaft",
            "Basel-Stadt",
            "Bern",
            "Fribourg",
            "Geneve",
            "Glarus",
            "Graubunden",
            "Jura",
            "Luzern",
            "Neuchatel",
            "Nidwalden",
            "Obwalden",
            "Sankt Gallen",
            "Schaffhausen",
            "Schwyz",
            "Solothurn",
            "Thurgau",
            "Ticino",
            "Uri",
            "Valais",
            "Vaud",
            "Zug",
            "Zurich",
        ],
    },
    {
        countryCode: "TN",
        country: "Tunisia",
        regions: [
            "",
            "Ariana (Aryanah)",
            "Beja (Bajah)",
            "Ben Arous (Bin 'Arus)",
            "Bizerte (Banzart)",
            "Gabes (Qabis)",
            "Gafsa (Qafsah)",
            "Jendouba (Jundubah)",
            "Kairouan (Al Qayrawan)",
            "Kasserine (Al Qasrayn)",
            "Kebili (Qibili)",
            "Kef (Al Kaf)",
            "Mahdia (Al Mahdiyah)",
            "Manouba (Manubah)",
            "Medenine (Madanin)",
            "Monastir (Al Munastir)",
            "Nabeul (Nabul)",
            "Sfax (Safaqis)",
            "Sidi Bou Zid (Sidi Bu Zayd)",
            "Siliana (Silyanah)",
            "Sousse (Susah)",
            "Tataouine (Tatawin)",
            "Tozeur (Tawzar)",
            "Tunis",
            "Zaghouan (Zaghwan)",
        ],
    },
    {
        countryCode: "TR",
        country: "Turkey",
        regions: [
            "",
            "Adana",
            "Adiyaman",
            "Afyonkarahisar",
            "Agri",
            "Aksaray",
            "Amasya",
            "Ankara",
            "Antalya",
            "Ardahan",
            "Artvin",
            "Aydin",
            "Balikesir",
            "Bartin",
            "Batman",
            "Bayburt",
            "Bilecik",
            "Bingol",
            "Bitlis",
            "Bolu",
            "Burdur",
            "Bursa",
            "Canakkale",
            "Cankiri",
            "Corum",
            "Denizli",
            "Diyarbakir",
            "Duzce",
            "Edirne",
            "Elazig",
            "Erzincan",
            "Erzurum",
            "Eskisehir",
            "Gaziantep",
            "Giresun",
            "Gumushane",
            "Hakkari",
            "Hatay",
            "Igdir",
            "Isparta",
            "Istanbul",
            "Izmir",
            "Kahramanmaras",
            "Karabuk",
            "Karaman",
            "Kars",
            "Kastamonu",
            "Kayseri",
            "Kilis",
            "Kirikkale",
            "Kirklareli",
            "Kirsehir",
            "Kocaeli",
            "Konya",
            "Kutahya",
            "Malatya",
            "Manisa",
            "Mardin",
            "Mersin",
            "Mugla",
            "Mus",
            "Nevsehir",
            "Nigde",
            "Ordu",
            "Osmaniye",
            "Rize",
            "Sakarya",
            "Samsun",
            "Sanliurfa",
            "Siirt",
            "Sinop",
            "Sirnak",
            "Sivas",
            "Tekirdag",
            "Tokat",
            "Trabzon",
            "Tunceli",
            "Usak",
            "Van",
            "Yalova",
            "Yozgat",
            "Zonguldak",
        ],
    },
    {
        countryCode: "UA",
        country: "Ukraine",
        regions: [
            "",
            "Cherkasy",
            "Chernihiv",
            "Chernivtsi",
            "Crimea",
            "Dnipropetrovs'k",
            "Donets'k",
            "Ivano-Frankivs'k",
            "Kharkiv",
            "Kherson",
            "Khmel'nyts'kyy",
            "Kirovohrad",
            "Kiev",
            "Kyyiv",
            "Luhans'k",
            "L'viv",
            "Mykolayiv",
            "Odesa",
            "Poltava",
            "Rivne",
            "Sevastopol'",
            "Sumy",
            "Ternopil'",
            "Vinnytsya",
            "Volyn'",
            "Zakarpattya",
            "Zaporizhzhya",
            "Zhytomyr",
        ],
    },
    {
        countryCode: "GB",
        country: "United Kingdom",
        regions: [
            "",
            "Aberconwy and Colwyn",
            "Aberdeen City",
            "Aberdeenshire",
            "Anglesey",
            "Angus",
            "Antrim",
            "Argyll and Bute",
            "Armagh",
            "Avon",
            "Ayrshire",
            "Bath and NE Somerset",
            "Bedfordshire",
            "Belfast",
            "Berkshire",
            "Berwickshire",
            "BFPO",
            "Blaenau Gwent",
            "Buckinghamshire",
            "Caernarfonshire",
            "Caerphilly",
            "Caithness",
            "Cambridgeshire",
            "Cardiff",
            "Cardiganshire",
            "Carmarthenshire",
            "Ceredigion",
            "Channel Islands",
            "Cheshire",
            "City of Bristol",
            "Clackmannanshire",
            "Clwyd",
            "Conwy",
            "Cornwall/Scilly",
            "Cumbria",
            "Denbighshire",
            "Derbyshire",
            "Derry/Londonderry",
            "Devon",
            "Dorset",
            "Down",
            "Dumfries and Galloway",
            "Dunbartonshire",
            "Dundee",
            "Durham",
            "Dyfed",
            "East Ayrshire",
            "East Dunbartonshire",
            "East Lothian",
            "East Renfrewshire",
            "East Riding Yorkshire",
            "East Sussex",
            "Edinburgh",
            "England",
            "Essex",
            "Falkirk",
            "Fermanagh",
            "Fife",
            "Flintshire",
            "Glasgow",
            "Gloucestershire",
            "Greater London",
            "Greater Manchester",
            "Gwent",
            "Gwynedd",
            "Hampshire",
            "Hartlepool",
            "Hereford and Worcester",
            "Hertfordshire",
            "Highlands",
            "Inverclyde",
            "Inverness-Shire",
            "Isle of Man",
            "Isle of Wight",
            "Kent",
            "Kincardinshire",
            "Kingston Upon Hull",
            "Kinross-Shire",
            "Kirklees",
            "Lanarkshire",
            "Lancashire",
            "Leicestershire",
            "Lincolnshire",
            "Londonderry",
            "Merseyside",
            "Merthyr Tydfil",
            "Mid Glamorgan",
            "Mid Lothian",
            "Middlesex",
            "Monmouthshire",
            "Moray",
            "Neath & Port Talbot",
            "Newport",
            "Norfolk",
            "North Ayrshire",
            "North East Lincolnshire",
            "North Lanarkshire",
            "North Lincolnshire",
            "North Somerset",
            "North Yorkshire",
            "Northamptonshire",
            "Northern Ireland",
            "Northumberland",
            "Nottinghamshire",
            "Orkney and Shetland Isles",
            "Oxfordshire",
            "Pembrokeshire",
            "Perth and Kinross",
            "Powys",
            "Redcar and Cleveland",
            "Renfrewshire",
            "Rhonda Cynon Taff",
            "Rutland",
            "Scottish Borders",
            "Shetland",
            "Shropshire",
            "Somerset",
            "South Ayrshire",
            "South Glamorgan",
            "South Gloucesteshire",
            "South Lanarkshire",
            "South Yorkshire",
            "Staffordshire",
            "Stirling",
            "Stockton On Tees",
            "Suffolk",
            "Surrey",
            "Swansea",
            "Torfaen",
            "Tyne and Wear",
            "Tyrone",
            "Vale Of Glamorgan",
            "Wales",
            "Warwickshire",
            "West Berkshire",
            "West Dunbartonshire",
            "West Glamorgan",
            "West Lothian",
            "West Midlands",
            "West Sussex",
            "West Yorkshire",
            "Western Isles",
            "Wiltshire",
            "Wirral",
            "Worcestershire",
            "Wrexham",
            "York",
        ],
    },
    {
        countryCode: "US",
        country: "United States",
        regions: [
            "",
            "Georgia",
            "Illinois",
            "New Jersey",
            "New York",
            "North Carolina",
            "Great Lakes High School Fencing Conference",
        ],
    },
    {
        countryCode: "VE",
        country: "Venezuela",
        regions: [
            "",
            "Amazonas",
            "Anzoategui",
            "Apure",
            "Aragua",
            "Barinas",
            "Bolivar",
            "Carabobo",
            "Cojedes",
            "Delta Amacuro",
            "Dependencias Federales",
            "Distrito Federal",
            "Falcon",
            "Guarico",
            "Lara",
            "Merida",
            "Miranda",
            "Monagas",
            "Nueva Esparta",
            "Portuguesa",
            "Sucre",
            "Tachira",
            "Trujillo",
            "Vargas",
            "Yaracuy",
            "Zulia",
        ],
    },
    {
        countryCode: "VN",
        country: "Vietnam",
        regions: [
            "",
            "An Giang",
            "Bac Giang",
            "Bac Kan",
            "Bac Lieu",
            "Bac Ninh",
            "Ba Ria-Vung Tau",
            "Ben Tre",
            "Binh Dinh",
            "Binh Duong",
            "Binh Phuoc",
            "Binh Thuan",
            "Ca Mau",
            "Cao Bang",
            "Dac Lak",
            "Dac Nong",
            "Dien Bien",
            "Dong Nai",
            "Dong Thap",
            "Gia Lai",
            "Ha Giang",
            "Hai Duong",
            "Ha Nam",
            "Ha Tay",
            "Ha Tinh",
            "Hau Giang",
            "Hoa Binh",
            "Hung Yen",
            "Khanh Hoa",
            "Kien Giang",
            "Kon Tum",
            "Lai Chau",
            "Lam Dong",
            "Lang Son",
            "Lao Cai",
            "Long An",
            "Nam Dinh",
            "Nghe An",
            "Ninh Binh",
            "Ninh Thuan",
            "Phu Tho",
            "Phu Yen",
            "Quang Binh",
            "Quang Nam",
            "Quang Ngai",
            "Quang Ninh",
            "Quang Tri",
            "Soc Trang",
            "Son La",
            "Tay Ninh",
            "Thai Binh",
            "Thai Nguyen",
            "Thanh Hoa",
            "Thua Thien-Hue",
            "Tien Giang",
            "Tra Vinh",
            "Tuyen Quang",
            "Vinh Long",
            "Vinh Phuc",
            "Yen Bai",
            "Can Tho",
            "Da Nang",
            "Hai Phong",
            "Hanoi",
            "Ho Chi Minh",
        ],
    },
];

export default geography;
