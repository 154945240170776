import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, updateMeetsThunk } from "../../utils/store";
import { getRefs } from "../../utils/database";
import "./SeasonTeamSwitcher.css"
import { AdministratingTeam } from "../../types";

/**
 * Component that provides dropdowns which hook into the state and modify season, team, and subteam
 */
export default function SeasonTeamSwitcher () {
    const seasonRaw = useSelector((s: ReduxState) => s.season);
    const seasonsRaw = useSelector((s: ReduxState) => s.seasons);
    const team = useSelector((s: ReduxState) => s.team);
    const teamID = useSelector((s: ReduxState) => s.teamID);
    const subteam = useSelector((s: ReduxState) => s.subteam);
    const subteams = useSelector((s: ReduxState) => s.subteams);
    const editingTeamBehalf = useSelector((s: ReduxState) => s.editingTeamBehalf);
    const administratingTeams: Record<string, AdministratingTeam> = useSelector((s: ReduxState) => s.administratingTeams);
    const dispatch = useDispatch();

    const [season, setSeason] = useState(seasonRaw);
    const [seasons, setSeasons] = useState(seasonsRaw);

    useEffect(() => {
        (async () => {
            const teamIDR = editingTeamBehalf?.teamID || teamID;
            const seasonsList = Object.keys(await getRefs(`/legacy/teams/details/${teamIDR}/seasons/list`) || {});
            let newSeason = season;
            if (JSON.stringify(seasons) !== JSON.stringify(seasonsList)) {
                const barh = new Date().getMonth() >= 7 ? `${new Date().getFullYear()}-${new Date().getFullYear() + 1}` : `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`;
                newSeason = barh;
            }
            if (!seasonsList.includes(newSeason)) {
                newSeason = seasonsList[seasonsList.length - 1];
            }
            setSeason(newSeason);
            setSeasons(seasonsList);
            if (newSeason !== seasonRaw) {
                dispatch({ type: "switchSeason", season: newSeason });
                dispatch(updateMeetsThunk);
            }
        })();
    }, []);

    useEffect(() => {
        setSeason(seasonRaw);
    }, [seasonRaw]);

    useEffect(() => {
        setSeasons(seasonsRaw);
    }, [seasonsRaw]);

    /**
     * Dispatches a value but also calls `updateMeetsThunk` as well.
     */
    const updateValue = async (type: string, season: unknown): Promise<void> => {
        dispatch({ type, season });
        dispatch(updateMeetsThunk);
    };

    return (
        <div style={{ margin: 25, fontSize: "110%" }}>
            <Dropdown style={{ textAlign: "center" }} className="transparentDropdown" focusFirstItemOnShow={true}>
                <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                    { seasons.length === 0 ? "No" : season } season
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: "inherit" }}>
                    { seasons.map(l => <Dropdown.Item onClick={() => updateValue("switchSeason", l)} key={`season${l}`}>{seasons.length === 0 ? "No" : l} season</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown>
            { !!subteams[0] && <Dropdown style={{ textAlign: "center", marginTop: 25 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                <Dropdown.Toggle id="dropdown-basic" style={{ fontSize: "inherit" }}>
                    { editingTeamBehalf.teamName || team } { subteam === "boys" ? "Men's" : "Women's" }
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: "inherit" }}>
                    { subteams.map((l, idx) => <Dropdown.Item onClick={() => updateValue("switchSubteam", l)} key={`subteam${idx}`}>{team} {l === "boys" ? "Men's" : "Women's"}</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown> }
            { Object.keys(administratingTeams).length >= 2 && <Dropdown style={{ textAlign: "center", marginTop: 25 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                <Dropdown.Toggle id="dropdown-basic" style={{ fontSize: "inherit" }}>
                    { editingTeamBehalf.teamName || team }
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: "inherit" }}>
                    { Object.entries(administratingTeams).map(l => <Dropdown.Item onClick={() => updateValue("switchAdministratingTeam", l[0])} key={`teamDASfji${l[0]}`}>{l[1].teamName}</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown> }
        </div>
    )
}
