import { useEffect } from "react";
import * as ReactDOM from "react-dom";

import { PersistGate } from "redux-persist/integration/react"
import { Provider, useDispatch } from "react-redux";
import storeRaw, { updateMeetsThunk } from "./utils/store";
import fire from "./utils/firebaseSetup";

import Home from "./Home";

import "bootstrap/dist/css/bootstrap.min.css";

const { store, persistor } = storeRaw();

const App = () => {
    const dispatch = useDispatch();

    const authListener = () => {
        fire.auth().onAuthStateChanged(async user => {
            if (user && user !== null) {
                dispatch({ type: "updateUid", uid: user.uid });
                dispatch({ type: "updateUser", user });
                if (sessionStorage.getItem("signedIn") !== "true") {
                    dispatch(updateMeetsThunk);
                }
            } else if (store.getState().uid) {
                dispatch({ type: "resetState" });
                sessionStorage.removeItem("signedIn");
                localStorage.removeItem("persist:root");
            }
        })
    }

    useEffect(() => {
        authListener();

        window.addEventListener("error", (event) => {
            alert(`${event.message}`);
        });
    }, []);

    return (
        <Home />
    )
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

export const modalBoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 800 ? "50%" : "90%",
    maxWidth: "90vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};
