import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import "./Roster.css";
import SeasonTeamSwitcher from "../../components/SeasonTeamSwitcher/SeasonTeamSwitcher";
import PermissionDenied from "../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../components/TbTPage/TbTPage";
import { CommonLoading } from "../../components/Loading/Loading";
import { Button } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import Modal from "../../components/Modal/Modal";
import { enableScroll } from "../../utils/helpers";
import { useDispatch } from 'react-redux';
import { ReduxState, updateMeetsThunk } from '../../utils/store';
import DB from '../../utils/database';
import { useHistory } from 'react-router-dom';
import { Team, TeamMember, Weapon } from '../../types';

function UsersSection ({ teamMembers, discipline, records }: { teamMembers: Record<string, TeamMember>, discipline: Weapon, records: Record<string, { wins: number, losses: number }> }) {
    const [expanded, setExpanded] = useState(true);
    const [fading, setFading] = useState(false);

    const handleToggle = () => {
        if (expanded) {
            setFading(true);
            setTimeout(() => {
                setExpanded(!expanded);
                setFading(false);
            }, 500);
        } else {
            setExpanded(true);
        }
    }

    return (
        <div className={`usersSection`}>
            <p className="rosterBlockHeader" onClick={handleToggle}>{ discipline }</p>
            { expanded && <div className={`rosterBlock ${fading && "fadingOut"}`}>
                { Object.values(teamMembers || {}).map((l, idx) => (
                    <div className="rosterItem" key={`iadsjfiajsdfijaisdjfi${idx}`}>
                        <div style={{ width: "90%", margin: "auto", display: "block" }}>
                            <div style={{ width: "50px", display: "inline-block" }}>
                                {l.role || "Sub"}
                            </div>
                            <div style={{ display: "inline-block", marginRight: "auto" }}>
                                { l.firstName }{" "}{ l.lastName } <span className="teamMemberDesc"> - { l.gradYear }</span>
                            </div>
                            <div style={{ marginLeft: "auto", float: "right" }}>{ records[l.id]?.wins || 0 } - { records[l.id]?.losses || 0 }</div>
                        </div>
                    </div>
                )) }
            </div>}
        </div>

    );
}

export default function RosterEditor () {
    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const seasons = useSelector((s: ReduxState) => s.seasons);
    const teamID = useSelector((s: ReduxState) => s.teamID);
    const teamName = useSelector((s: ReduxState) => s.team);
    const subteam = useSelector((s: ReduxState) => s.subteam);
    const isPublished = useSelector((s: ReduxState) => s.isPublished);
    const administratingTeams = useSelector((s: ReduxState) => s.administratingTeams);
    const storedTeamMembers = useSelector((s: ReduxState) => s.teamMembers);
    const fencerRecord = useSelector((s: ReduxState) => s.fencerRecord);
    const loading = useSelector((s: ReduxState) => s.loading);
    const [teamMembers, setTeamMembers] = useState(JSON.parse(JSON.stringify(useSelector((s: ReduxState) => s.teamMembers))));

    const possibleSeasons = ["2021-2022", "2022-2023", "2023-2024", "2024-2025", "2025-2026", "2026-2027", "2027-2028"];

    const availableSeasons = possibleSeasons.filter(l => !seasons.includes(l));
    const [newSeason, setNewSeason] = useState(availableSeasons.length === 0 ? "No available seasons!" : availableSeasons[0]);

    const [teamList, setTeamList] = useState<Record<string, Team>>({});

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setTeamMembers(storedTeamMembers);
    }, [storedTeamMembers]);

    useEffect(() => {
        if (userInfo?.isUberAdmin) {
            DB.getTeamList().then(setTeamList);
        }
    }, [userInfo?.isUberAdmin]);

    const createSeason = async () => {
        await DB.createTeamSeason(teamID, teamName, newSeason, subteam, isPublished);
        dispatch(updateMeetsThunk);
    }

    if (userInfo?.isUberAdmin) {
        return <TbTPage>
            <div style={{ maxHeight: "calc(100vh - 150px)"}}>
                <div style={{ height: 50 }}></div>
                <h2>Choose team to edit</h2>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        Choose team...
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                        { Object.values(teamList).filter(l => l.isPublished).map(l => <Dropdown.Item key={`rtsdfg${l.teamID}]}`} onClick={() => {
                            dispatch({ type: "editEditingTeamBehalf", payload: l });
                            history.push("/rosterEditor");
                        }}>
                            {l.teamName}
                        </Dropdown.Item> ) }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </TbTPage>
    }

    if (!Object.keys(administratingTeams).length && !loading) {
        return <PermissionDenied message="You have no teams to administrate at this time." promptTeamCreation={true} />;
    }

    if (seasons.length === 0) {
        return (
            <>
                <TbTPage>
                    <SeasonTeamSwitcher></SeasonTeamSwitcher>
                    {
                        loading
                            ? <CommonLoading color="#714FCA" size="large" />
                            : <>
                                <UsersSection records={{}} teamMembers={teamMembers.Sabre?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Sabre"></UsersSection>
                                <UsersSection records={{}} teamMembers={teamMembers.Foil?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Foil"></UsersSection>
                                <UsersSection records={{}} teamMembers={teamMembers.Epee?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Epee"></UsersSection>
                            </>
                    }
                    <Modal close={() => {}} closeable={false}>
                        <h2>You need to create a season before you can use the roster with this team.</h2>
                        <div style={{ marginRight: 10 }}>
                            <h4>Select season to create</h4>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    { newSeason }
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    { availableSeasons.map(l => <Dropdown.Item key={`oerpoa${l}`} onClick={(b) => setNewSeason(l)}>{l}</Dropdown.Item> ) }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <Button style={{ marginRight: 10 }} disabled={newSeason === "No available seasons!"} variant="success" onClick={() => { createSeason(); enableScroll() }}>Create Season</Button>
                            <Button variant="danger" onClick={() => { history.push("/teamData"); enableScroll() }}>Back</Button>
                        </div>
                    </Modal>
                </TbTPage>
            </>
        );
    }


    return (
        <TbTPage>
            <SeasonTeamSwitcher></SeasonTeamSwitcher>
            {
                loading
                    ? <CommonLoading color="#714FCA" size="large" />
                    : <>
                        <UsersSection records={fencerRecord.Sabre} teamMembers={teamMembers.Sabre?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Sabre"></UsersSection>
                        <UsersSection records={fencerRecord.Foil} teamMembers={teamMembers.Foil?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Foil"></UsersSection>
                        <UsersSection records={fencerRecord.Epee} teamMembers={teamMembers.Epee?.sort((a, b) => a.gradYear - b.gradYear)} discipline="Epee"></UsersSection>
                    </>
            }
            <div style={{ position: "fixed", top: 85, right: 10 }}>
                <a href="/rosterEditor"><Button variant="success">Edit Roster</Button></a>
            </div>
        </TbTPage>
    )
}
