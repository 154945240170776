import { useState } from 'react';
import fire from '../../utils/firebaseSetup'
import DB from "../../utils/database";

import { useHistory } from "react-router-dom";

import "./LoginFront.css"
import CarryingMask from "../../assets/CarryingMask.jpg";
import TbtIcon from "../../assets/TBTicon.png";
import ForgotLogin from "./ForgotLogin"
import TbTPage from "../../components/TbTPage/TbTPage";

import { Form, Button, Col, InputGroup } from "react-bootstrap"

const Login = () => {
    const history = useHistory();

    //states for user info
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //states for email and password errors
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    //state to switch between Sign In, and Register
    const [hasAccount, setHasAccount] = useState(true);

    const clearErrors = () => {
        setEmailError('');
        setPasswordError('');
    }

    const handleLogin = () => {
        clearErrors();
        fire
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(async l => {
                const userInfo = await DB.getUserInfo();
                if (userInfo?.isBoutCommittee) {
                    history.push("/tournaments");
                } else if (userInfo?.isUberAdmin) {
                    history.push("/roster");
                } else {
                    history.push("/teamData");
                }
            })
            //only runs if email and/or password are invalid or incorrect
            .catch(err => {
                switch (err.code){
                    //email error message
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setEmailError(err.message);
                        break;
                    //password error message
                    case "auth/wrong-password":
                        setPasswordError(err.message);
                        break;
                    default:
                        setPasswordError("Too many login attempts have been made from this device! Please try again later.");
                        fire
                            .firestore()
                            .collection("mail")
                            .add({
                                to: email,
                                message: {
                                subject: "Account Security from Touch by Touch!",
                                text: "This email is to notify you that several failed attemps have been made to access your account. If this was not you, please change your password immediately. Otherwise, feel free to ignore this email.",
                                },
                            });
                        break;
                }
            })
    };

    const handleSignup = () => {
        clearErrors();
        fire
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async ({ user }) => {
                if (user) {
                    await DB.createUser(user.uid, email, email);
                    history.push("/teamData");
                }
            })
            .catch(err => {
                switch (err.code){
                    //email error message
                    case "auth/email-already-in-use":
                    case "auth/invalid-email":
                        setEmailError(err.message);
                        break;
                    //password error message
                    case "auth/weak-password":
                        setPasswordError(err.message);
                        break;
                    default:
                        break;
                }
            })
    };

    const [forgotPassword, setForgotPassword] = useState(false);

    return (
        forgotPassword ? (
            <ForgotLogin
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
                setForgotPassword={setForgotPassword}
            />
        ) : (
            <TbTPage>
                <div style={{ minHeight: "calc(100vh - 115px)", padding: "50px 0" }}>
                    <section className="login">
                        <div className="loginForm">
                            <div className="loginHeader">{ hasAccount ? "Legacy Login" : "Legacy Register" }</div>
                            <img src={ TbtIcon } alt="TBT Icon" style={{ width: 60, height: 60 }} />
                            <Form style={{width: "85%", margin: "auto", textAlign: "center" }}>
                                <Form.Group as={Col}>
                                    <Form.Label style={{marginTop: "5%", display: "flex"}}>Email</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            placeholder="Email address"
                                            autoFocus
                                            required
                                            value={email}
                                            onChange={e => setEmail(e.target.value) }
                                        />
                                    </InputGroup>
                                    <Form.Text style={{width:"100%", color: "red", display: "flex", textAlign: "left"}}>
                                        {emailError}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label style={{display: "flex"}}>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Form.Text style={{width:"100%", color: "red", display: "flex", textAlign: "left"}}>
                                    {passwordError}
                                    </Form.Text>
                                </Form.Group>
                                {hasAccount ? (
                                    <>
                                        <Button onClick={handleLogin} variant="primary" style={{width: "90%", marginTop: 5 }}>Sign in</Button>
                                        <Button className="forgotPassword" onClick={() => setForgotPassword(true)} variant="dark" style={{ width: "90%", marginTop: "10px" }}>
                                            Forgot Password?
                                        </Button>
                                        <p style={{ fontSize: 15, marginTop: 5 }}>Don't have an account?
                                            <span className="switchSignIn" onClick={() => setHasAccount(!hasAccount)}>{" "}Sign Up</span>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={handleSignup} variant="primary" style={{width: "90%", marginTop: 5, marginBottom: "5%"}}>Register</Button>
                                        <p>Have an account?
                                            <span className="switchSignIn" onClick={() => setHasAccount(!hasAccount)}>{" "}Sign In</span>
                                        </p>
                                    </>
                                )}
                            </Form>
                        </div>
                        <img src={ CarryingMask } alt="Primitive fencers" className="loginImage" />
                    </section>
                </div>
            </TbTPage>
        )
    )
}

export default Login;
