import { useEffect, useState, useRef } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CommonLoading } from "../../components/Loading/Loading";
import "./DualMeetInfo.css";
import { useSelector } from "react-redux";
import DB from "../../utils/database";
import TbTPage from "../../components/TbTPage/TbTPage";
import { TbTTab, TbTTabView } from "../../components/TabView/TabView";
import Icon from '@mdi/react';
import { mdiFlagOutline, mdiFlag, mdiEye } from '@mdi/js';
import { Bout, MeetBouts, TournamentDataSmaller, Weapon } from "../../types";
import { ReduxState } from "../../utils/store";
import { winner_legacy } from "../../utils/helpers";

type PlayerWinsAccum = { wins: number, losses: number, name: string, discipline: Weapon }[];
type TeamWinsAccum = { team1: PlayerWinsAccum, team2: PlayerWinsAccum }

function LiveNowBout({ data, onClick }: { data: Bout, onClick: () => void }) {
    return <div className="liveNowBout" onClick={ onClick }>
        <h4 style={{ fontWeight: 500 }}><Icon path={mdiEye} size={1} /> Live now - { data?.weapon }</h4>
        <h6>{ data?.fencer1 } ({ data?.team1strip || "Sub" }) vs { data?.fencer2 } ({ data?.team2strip || "Sub" })</h6>
    </div>
}

export default function TournamentInfo() {
    const { id } = useParams();
    const tournaments = useSelector((s: ReduxState) => s.tournamentData);
    const tournamentBouts = useSelector((s: ReduxState) => s.tournamentBouts);

    const [data, setData] = useState<TournamentDataSmaller | null>(null);
    const [weapon, setWeapon] = useState<Weapon>("Sabre");
    const [bouts, setBouts] = useState<MeetBouts>({ "Epee": {}, "Sabre": {}, "Foil": {} });
    const [timeouts, setTimeouts] = useState<[number, number]>([0, 0]);
    const [currentBoutIdx, setCurrentBoutIdx] = useState<number | null>(null);
    const [liveBouts, setLiveBouts] = useState<Bout[]>([]);
    const [teamWinsAccum, setTeamWinsAccum] = useState<TeamWinsAccum>({ team1: [], team2: [] });

    const tabsRef = useRef<any>();

    const currentBout = currentBoutIdx !== null ? Object.values(bouts[weapon])[currentBoutIdx] : null;

    const homeTeam = data?.team1ID || "";

    const calcTimeouts = (b: MeetBouts) => {
        let timeouts1 = 0;
        let timeouts2 = 0;

        for (const bout of Object.values(b[weapon])) {
            if (bout.fencer1TeamID === homeTeam) {
                if (bout.team1TimeOut) {
                    timeouts1++;
                }
                if (bout.team2TimeOut) {
                    timeouts2++;
                }
            } else {
                if (bout.team1TimeOut) {
                    timeouts2++;
                }
                if (bout.team2TimeOut) {
                    timeouts1++;
                }
            }
        }

        setTimeouts([timeouts1, timeouts2]);
    };

    useEffect(() => {
        calcTimeouts(bouts);
        if (data) {
            /**
             * Creates a blank user in case there's no entry in `playerWinsAccum` yet
             */
            const createBlankUser = (name: string, discipline: Weapon, win: 0 | 1 | boolean) => ({
                wins: Number(win),
                losses: Number(!win),
                name,
                discipline
            });

            const addScore = (right: boolean, won: boolean, id: string, name: string, weapon: Weapon, switchedSides: boolean) => {
                // We use !== to make right act as an "inverter" for `switchedSides`
                // When `right` is true, the condition becomes the opposite of `switchedSides`
                // Basically this just looks at the other side which is what we want
                const score = (switchedSides !== right ? playerWinsAccum.team2 : playerWinsAccum.team1)[id || `writeIn${name}`];
                if (score) {
                    if (won) {
                        return { ...score, wins: score.wins + 1 };
                    } else {
                        return { ...score, losses: score.losses + 1 };
                    }
                } else {
                    return createBlankUser(name, weapon, won);
                }
            }

            const playerWinsAccum = { team1: {}, team2: {} };
            // For every bout of the selected weapon
            for (const bout of Object.values(bouts[weapon])) {
                const boutWinner = winner_legacy(bout);
                if (bout.fencer1TeamID === data.team1ID) {
                    // No switching sides
                    const currID1 = bout.fencer1ID || `writeIn${bout.fencer1}`;
                    const currID2 = bout.fencer2ID || `writeIn${bout.fencer2}`;
                    if (boutWinner === false) {
                        playerWinsAccum.team1[currID1] = addScore(false, true, bout.fencer1ID, bout.fencer1, weapon, false);
                        playerWinsAccum.team2[currID2] = addScore(true, false, bout.fencer2ID, bout.fencer2, weapon, false);
                    } else if (boutWinner === true) {
                        playerWinsAccum.team1[currID1] = addScore(false, false, bout.fencer1ID, bout.fencer1, weapon, false);
                        playerWinsAccum.team2[currID2] = addScore(true, true, bout.fencer2ID, bout.fencer2, weapon, false);
                    } else {
                        if (!(currID1 in playerWinsAccum.team1)) {
                            playerWinsAccum.team1[currID1] = {
                                wins: 0,
                                losses: 0,
                                name: bout.fencer1,
                                discipline: weapon
                            }
                        }
                        if (!(currID2 in playerWinsAccum.team2)) {
                            playerWinsAccum.team2[currID2] = {
                                wins: 0,
                                losses: 0,
                                name: bout.fencer2,
                                discipline: weapon
                            }
                        }
                    }
                } else {
                    // Switching sides
                    const currID1 = bout.fencer1ID || `writeIn${bout.fencer1}`;
                    const currID2 = bout.fencer2ID || `writeIn${bout.fencer2}`;
                    // Switched sides
                    if (boutWinner === false) {
                        playerWinsAccum.team2[currID1] = addScore(false, true, bout.fencer1ID, bout.fencer1, weapon, true);
                        playerWinsAccum.team1[currID2] = addScore(true, false, bout.fencer2ID, bout.fencer2, weapon, true);
                    } else if (boutWinner === true) {
                        playerWinsAccum.team2[currID1] = addScore(false, false, bout.fencer1ID, bout.fencer1, weapon, true);
                        playerWinsAccum.team1[currID2] = addScore(true, true, bout.fencer2ID, bout.fencer2, weapon, true);
                    } else {
                        playerWinsAccum.team1[currID1] = addScore(false, false, bout.fencer1ID, bout.fencer1, weapon, false);
                        playerWinsAccum.team2[currID2] = addScore(true, false, bout.fencer2ID, bout.fencer2, weapon, false);
                    }
                }
            }
            const teamWinsAccum: TeamWinsAccum = { team1: Object.values(playerWinsAccum.team1), team2: Object.values(playerWinsAccum.team2) };
            teamWinsAccum.team1.sort((a, b) => b.wins - a.wins || a.losses - b.losses);
            teamWinsAccum.team2.sort((a, b) => b.wins - a.wins || a.losses - b.losses);
            setTeamWinsAccum(teamWinsAccum);
        }
    }, [bouts, weapon]);

    useEffect(() => {
        const liveNow: Bout[] = [];
        for (const weapon in bouts) {
            for (const i in bouts[weapon]) {
                const bout = bouts[weapon][i];

                if (
                    bout.startedAt &&
                    bout.startedAt !== "" &&
                    (!bout.endedAt || bout.endedAt === "")
                ) {
                    //bout is live
                    liveNow.push(bout);
                }
            }
        }
        // Testing purposes only
        // if (Object.values(bouts.Sabre).length) {
        //     liveNow.unshift(Object.values(bouts.Foil)[3]);
        //     liveNow.unshift(Object.values(bouts.Sabre)[5]);
        // }
        setLiveBouts(liveNow);
    }, [bouts]);

    useEffect(() => {
        if (tournaments.some(l => l.tournamentID === id)) {
            setData(tournaments.find(l => l.tournamentID === id)!);
            setBouts(tournamentBouts[id]);
            calcTimeouts(tournamentBouts[id]);
        } else {
            DB.getTournamentData(id, l => { setData(l); setBouts(l.bouts); calcTimeouts(l.bouts) });
        }
    }, []);

    function getBoutScore(weapon: Weapon) {
        let home = 0;
        let away = 0;
        for (const bout of Object.values(bouts[weapon])) {
            if (bout?.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
                if (bout.fencer1TeamID === homeTeam) {
                    if (bout.score1 > bout.score2) {
                        home++;
                    } else if (bout.score1 < bout.score2) {
                        away++;
                    } else if (bout.fencer1Priority) {
                        home++;
                    } else if (bout.fencer2Priority) {
                        away++;
                    }
                } else {
                    if (bout.score1 > bout.score2) {
                        away++;
                    } else if (bout.score1 < bout.score2) {
                        home++;
                    } else if (bout.fencer1Priority) {
                        away++;
                    } else if (bout.fencer2Priority) {
                        home++;
                    }
                }
            }
        }
        return { home, away };
    }

    const score = {
        home: getBoutScore("Epee").home + getBoutScore("Foil").home + getBoutScore("Sabre").home,
        away: getBoutScore("Epee").away + getBoutScore("Foil").away + getBoutScore("Sabre").away
    }

    return (
        <TbTPage className="tournamentInfoPage">
            <h1 style={{ color: "white" }}>Dual Meet Info</h1>
            {
                data ? <Table className="tournamentInfoTable" style={{ width: "600px", color: "#EEE", backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center" }}>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "125%", fontWeight: 600 }}>{ data.modifiedName }</td>
                        </tr>
                        <tr>
                            <td><strong style={{ fontSize: "125%" }}>{ score.home }</strong> - <strong style={{ fontSize: "125%" }}>{ score.away }</strong></td>
                        </tr>
                        <tr>
                            <td>{ new Date(Date.parse(data.startDate)).toDateString() }</td>
                        </tr>
                    </tbody>
                </Table> : <CommonLoading color="#714FCA" size="large"/>
            }
            <div className="liveNowBoutsList">
                { liveBouts.map(l => <LiveNowBout data={ l } onClick={() => { setWeapon(l.weapon); setCurrentBoutIdx(l.boutOrder - 1); tabsRef?.current?.switchTab(2) }} />) }
            </div>
            {
                data && <Dropdown style={{ textAlign: "center", marginBottom: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                    <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                        { weapon }
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "inherit" }}>
                        <Dropdown.Item onClick={() => setWeapon("Sabre")}>Sabre</Dropdown.Item>
                        <Dropdown.Item onClick={() => setWeapon("Foil")}>Foil</Dropdown.Item>
                        <Dropdown.Item onClick={() => setWeapon("Epee")}>Epee</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }
            {
                data && <TbTTabView ref={tabsRef}>
                    <TbTTab title="Summary">
                        <div style={{ maxWidth: 500, width: "90%", height: 250, overflow: "auto", margin: "auto", flexShrink: 0 }}>
                            <div style={{ color: "white", height: 50, fontSize: 24, lineHeight: "35px" }} className="rainbowBox rainbowBoxBG">Dual Meet Summary</div>
                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", color: "white", height: 250 - 56, fontSize: 18 }}>
                                <p>{ weapon } Timeouts: { timeouts[0] } - { timeouts[1] }</p>
                                <p>Sabre: { getBoutScore("Sabre").home } - { getBoutScore("Sabre").away }</p>
                                <p>Foil: { getBoutScore("Foil").home } - { getBoutScore("Foil").away }</p>
                                <p>Epee: { getBoutScore("Epee").home } - { getBoutScore("Epee").away }</p>
                            </div>
                        </div>
                    </TbTTab>
                    <TbTTab title="Individual Stats">
                        <div style={{ maxWidth: 600, width: "90%", height: 300, overflow: "auto", margin: "auto", flexShrink: 0 }}>
                            <div style={{ color: "white", height: 50, fontSize: 24, lineHeight: "35px" }} className="rainbowBox rainbowBoxBG">Individual stats</div>
                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center", display: "flex", justifyContent: "center", color: "white", minHeight: 250 - 56, fontSize: 18 }}>
                                <div style={{ width: 299, borderRight: "1px solid #999" }}>
                                    <div style={{ padding: 10, borderBottom: "1px solid #999" }}>{ data.team1Name }</div>
                                    { teamWinsAccum.team1.map(l => <p style={{ margin: 10 }}>{ l.name }: { l.wins }-{ l.losses }</p>) }
                                </div>
                                <div style={{ width: 300 }}>
                                    <div style={{ padding: 10, borderBottom: "1px solid #999" }}>{ data.team2Name }</div>
                                    { teamWinsAccum.team2.map(l => <p style={{ margin: 10 }}>{ l.name }: { l.wins }-{ l.losses }</p>) }
                                </div>
                            </div>
                        </div>
                    </TbTTab>
                    <TbTTab title="Current Bout">
                        <div style={{ maxWidth: 500, width: "90%", height: 300, overflow: "hidden", margin: "auto", flexShrink: 0 }}>
                            <div style={{ color: "white", height: 50, fontSize: 24, lineHeight: "35px" }} className="rainbowBox rainbowBoxBG">Current Bout</div>
                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", color: "white", height: 300 - 56, fontSize: 18 }}>
                                { currentBout
                                    ? <div style={{ display: "flex" }} key={`currentBout${currentBout.boutOrder}`}>
                                        <div style={{ width: "33%" }}>
                                            <div className="boutPlayer">
                                                <h3 style={{ marginBottom: 5, height: 20, fontSize: 18, fontWeight: "bold" }}>{ currentBout.fencer1 }</h3>
                                                <h5 style={{ marginBottom: 5, height: 20, fontSize: 14 }}>{ currentBout.team1.trim() }</h5>
                                                <div className="currentBoutScore" style={{ backgroundColor: "rgb(240,128,128)" }}>{ currentBout.score1 }</div>
                                                <div style={{ backgroundColor: "#999", borderRadius: 8, marginTop: 10, height: 40, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                                    <Icon path={ currentBout.fencer1YellowCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="yellow"
                                                    />
                                                    <Icon path={ currentBout.fencer1RedCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="red"
                                                    />
                                                    <Icon path={ currentBout.fencer1BlackCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="black"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="boutLog">
                                            { [...currentBout.boutLog]?.reverse()?.map(event => <div className="boutEvent">
                                                {event.eventDescription} at {event.localTime}.
                                                <br />
                                                <strong>Score: {event.score1} - {event.score2}</strong>
                                                <br />
                                                Bout Clock: { Math.floor(event.boutTime / 60000) }:{ (Math.floor(event.boutTime / 1000) % 60).toString().padStart(2, "0") }
                                            </div>)}
                                        </div>
                                        <div style={{ width: "33%" }}>
                                            <div className="boutPlayer">
                                                <h3 style={{ fontSize: 18, fontWeight: "bold" }}>{ currentBout.fencer2 }</h3>
                                                <h5 style={{ fontSize: 14 }}>{ currentBout.team2.trim() }</h5>
                                                <div className="currentBoutScore" style={{ backgroundColor: "rgb(100,255,160)", borderColor: "green" }}>{ currentBout.score2 }</div>
                                                <div style={{ backgroundColor: "#999", borderRadius: 8, marginTop: 10, height: 40, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                                    <Icon path={ currentBout.fencer2YellowCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="yellow"
                                                    />
                                                    <Icon path={ currentBout.fencer2RedCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="red"
                                                    />
                                                    <Icon path={ currentBout.fencer2BlackCard ? mdiFlag : mdiFlagOutline }
                                                        size="36px"
                                                        color="black"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <p>You have not selected any bouts!</p> }
                            </div>
                        </div>
                    </TbTTab>
                    <TbTTab title="All Bouts">
                        <div style={{ maxWidth: 500, width: "90%", height: 375, overflow: "auto", margin: "auto", flexShrink: 0 }}>
                            <div style={{ color: "white", height: 50, fontSize: 24, lineHeight: "35px" }} className="rainbowBox rainbowBoxBG">Bout List</div>
                            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.95)", textAlign: "center", minHeight: 325 }}>
                                { Object.values(bouts[weapon] ?? {}).every(l => l.score1 === 0 && l.score2 === 0) && <h4 style={{ color: "grey" }}>No bouts have happened yet!</h4> }
                                { Object.values(bouts[weapon] ?? {}).map((bout, idx) => {
                                    const leftSide = bout.fencer1TeamID === data.team1ID ? "1" : "2";
                                    const rightSide = bout.fencer1TeamID !== data.team1ID ? "1" : "2";
                                    return <div key={`adsjfirjgi${idx}`} style={{ display: "flex", backgroundColor: bout.boutColor, padding: 10, color: "#ddd", position: "relative", cursor: "pointer" }} onClick={() => { setCurrentBoutIdx(idx); tabsRef?.current?.switchTab(2) }}>
                                        <div style={{ position: "absolute", bottom: 0, left: 40, fontSize: 48, width: 40, textAlign: "right" }}>{ bout[`team${leftSide}strip`] }</div>
                                        <div style={{ position: "absolute", bottom: 0, right: 40, fontSize: 48, width: 40, textAlign: "left" }}>{ bout[`team${rightSide}strip`] }</div>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {bout[`fencer${leftSide}`]}
                                            <div className="scoreBox" style={{ backgroundColor: "red" }}>
                                                {bout[`score${leftSide}`]}
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        {bout[`fencer${rightSide}`]}
                                            <div className="scoreBox" style={{ backgroundColor: "green" }}>
                                                {bout[`score${rightSide}`]}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </TbTTab>
                </TbTTabView>
            }
        </TbTPage>
    )
}
