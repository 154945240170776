import { useState, useEffect } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TbTPage from "../../components/TbTPage/TbTPage";
import DB from "../../utils/database";

import "./MatchParticipants.css";

export default function MatchParticipants() {
    const { id } = useParams();

    const matches = useSelector(s => s.matchList);
    const season = useSelector(s => s.season);
    const data = matches.find(l => l.matchID === id);

    const weapon = data.weapon;
    const [strips, setStrips] = useState({});

    useEffect(() => {
        if (data.matchType === "team") {
            (async () => {
                const ids = data.teams.map(l => l.id);
                const promises = ids.map(l => l.startsWith("writeIn") ? new Promise(r => r(null)) : DB.getTeamRosterForSeason(l, season, data.gender));
                const things = await Promise.all(promises);
                for (const thing of things) {
                    if (!thing) {
                        continue;
                    }
                    for (const i in thing) {
                        const val = thing[i];
                        thing[i] = val.filter(l => !!l.role).sort((a, b) => a.role.localeCompare(b.role));
                    }
                }
                setStrips(things);
            })();
        } else {
            (async () => {
                const ids = data.fencers.map(l => l.searchingTeam);
                const promises = ids.map(l => l.startsWith("writeIn") ? new Promise(r => r(null)) : DB.teamData(l));
                const things = await Promise.all(promises);
                setStrips(things);
            })();
        }
    }, [data.gender, data.matchType, data.teams, data, season]);

    return (
        <TbTPage>
            <h1 style={{ color: "white", paddingTop: 30 }}>Tournament Info</h1>
            <h3 style={{ color: "grey", marginTop: 10, marginBottom: 30 }}>{data.name}</h3>
            { Object.keys(data.pairIDs).length > 1 && <Dropdown style={{ textAlign: "center", marginBottom: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                    <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                        { data.weapon }
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "inherit" }}>
                        { Object.keys(data.pairIDs).map(l => (
                            <Dropdown.Item href={`/tournamentParticipants/${data.pairIDs[l][data.gender]}`}>{l}</Dropdown.Item>
                        )) }
                    </Dropdown.Menu>
                </Dropdown> }
            {
                data.matchType === "team" ? <Table className="matchParticipantsTable" bordered style={{ height: `${(data.teamCount + 1) * 5}px`, flexShrink: 0, margin: "auto" }}>
                    <tbody>
                        <tr>
                            <td className="matchWhiteCell"></td>
                            <td className="matchWhiteCell">A Strip</td>
                            <td className="matchWhiteCell">B Strip</td>
                            <td className="matchWhiteCell">C Strip</td>
                        </tr>
                        {
                            data.teams.map((l, idx) => (
                                <tr>
                                    <td className="matchWhiteCell">{l.name.trim().split(" ").reverse().join(", ")}</td>
                                    <td className="matchWhiteCell">{!!strips?.[idx]?.[weapon]?.[2] ? `${strips[idx][weapon][0].lastName}, ${strips[idx][weapon][0].firstName}` : "-"}</td>
                                    <td className="matchWhiteCell">{!!strips?.[idx]?.[weapon]?.[2] ? `${strips[idx][weapon][1].lastName}, ${strips[idx][weapon][1].firstName}` : "-"}</td>
                                    <td className="matchWhiteCell">{!!strips?.[idx]?.[weapon]?.[2] ? `${strips[idx][weapon][2].lastName}, ${strips[idx][weapon][2].firstName}` : "-"}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table> : <Table className="matchParticipantsTable" bordered style={{ height: `${(data.teamCount + 1) * 5}px`, flexShrink: 0, margin: "auto" }}>
                    <tbody>
                        <tr>
                            <td className="matchWhiteCell">Name</td>
                            <td className="matchWhiteCell">Team</td>
                        </tr>
                        {
                            data.fencers.map((l, idx) => (
                                <tr>
                                    <td className="matchWhiteCell">{l.name.trim().split(" ").reverse().join(", ")}</td>
                                    <td className="matchWhiteCell">{strips?.[idx]?.teamName || "-"}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            }
        </TbTPage>
    )
}
