import "./TbTPage.css";

const TbTPage: React.FC<{ className?: string }> =({ children, className }) => {
    return (
        <div className={`tbtPage ${className ?? ""}`}>
            { children }
        </div>
    );
}

export default TbTPage;
