import { useState, useEffect } from "react";
import { Button, Form, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DB from "../../utils/database";
import { enableScroll } from "../../utils/helpers";
import Modal from "../../components/Modal/Modal";
import PermissionDenied from "../../components/PermissionDenied/PermissionDenied";
import { TbTTab, TbTTabView } from "../../components/TabView/TabView";
import TbTPage from "../../components/TbTPage/TbTPage";
import "./CreateTournament.css";

export default function CreateTournament () {
    const userInfo = useSelector(s => s.userInfo);
    const teamList = useSelector(s => s.teamList);
    const fencerList = useSelector(s => s.fencerList);
    const [teams, setTeams] = useState([]);
    const [teamCount, setTeamCount] = useState(0);
    const [fencers, setFencers] = useState({ Sabre: { boys: [], girls: [] }, Foil: { boys: [], girls: [] }, Epee: { boys: [], girls: [] } });
    const [tournamentRound, setTournamentRound] = useState(1);
    const [tournamentName, setTournamentName] = useState("");
    const [tournamentGender, setTournamentGender] = useState("both");
    const [tournamentWeapon, setTournamentWeapon] = useState("all");
    const [venue, setVenue] = useState("test venue");
    const [toastOpen, setToastOpen] = useState(false);
    const [tourneyDate, setTourneyDate] = useState(null);
    const [tourneyTime, setTourneyTime] = useState(null);
    // const [searchingTeam, setSearchingTeam] = useState("");
    const [individualPool, setIndividualPool] = useState(false);
    const [confirmingChanges, setConfirmingChanges] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (individualPool) {
            fencers.Sabre.boys = [...fencers.Sabre.boys.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Sabre.boys.length, 0)).fill({}).map(() => ({}))];
            fencers.Sabre.girls = [...fencers.Sabre.girls.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Sabre.girls.length, 0)).fill({}).map(() => ({}))];
            fencers.Epee.boys = [...fencers.Epee.boys.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Epee.boys.length, 0)).fill({}).map(() => ({}))];
            fencers.Epee.girls = [...fencers.Epee.girls.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Epee.girls.length, 0)).fill({}).map(() => ({}))];
            fencers.Foil.boys = [...fencers.Foil.boys.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Foil.boys.length, 0)).fill({}).map(() => ({}))];
            fencers.Foil.girls = [...fencers.Foil.girls.slice(0, teamCount), ...Array(Math.max(teamCount - fencers.Foil.girls.length, 0)).fill({}).map(() => ({}))];
            setFencers({...fencers});
        } else {
            setTeams(l => [...l.slice(0, teamCount), ...Array(Math.max(teamCount - l.length, 0)).fill({}).map(() => ({}))]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamCount, tournamentWeapon, individualPool]);

    if (!userInfo?.isBoutCommittee) {
        return <PermissionDenied message="You are not a member of the bout committee!" />
    }

    const message = (() => {
        if (!tournamentName) {
            return "You need to fill out a name for the tournament.";
        } else if (!venue) {
            return "You need to add a venue."
        } else if (teamCount < 3) {
            return "You have too few participants, you need to have at least 6."
        } else if (teamCount > 20) {
            return "You have too many participants, you need to have at maxmimum 20."
        } else if (!individualPool && false && teams.some(l => !l.name || !l.initial)) {
            return "You have some unfilled team names or initials.";
        } else if (!individualPool && false && teams.some(l => teams.filter(j => j.name === l.name && l.id !== "writeIn").length >= 2)) {
            return "You have some duplicate teams.";
        } else if (!individualPool && false && teams.some(l => teams.filter(j => j.intial === l.initial).length >= 2)) {
            return "You have some teams with duplicate initials.";
        } else if (!tourneyDate) {
            return "You need to set a date for the tournament.";
        } else if (!tourneyTime) {
            return "You need to set a time for the tournament.";
        }
        return "";
    })();

    const updates = Object.entries(teamList).filter(l => l[1].teamName === "asdfasdfasdf");
    const updatesObj = {};
    for (const update of updates) {
        updatesObj['/teams/list/' + update[0]] = null;
    }

    const options = individualPool
        ? Object.entries(fencerList)
        : Object.entries(teamList).filter(l => l[1].isPublished || true).filter(l => tournamentGender === "both" || (typeof l[1].boysAndGirlsTeam === "boolean") || l[1].boysAndGirlsTeam === tournamentGender);

    const submit = async () => {
        if (!individualPool) {
            const ids = teams.map(l => l.id);
            // TODO: Change from hardcoded season
            const promises = ids.map(l => l.startsWith("writeIn") ? new Promise(r => r(null)) : DB.getTeamRosterForSeason(l, "2021-2022", tournamentGender));
            const things = await Promise.all(promises);
            for (const thing of things) {
                if (!thing) {
                    continue;
                }
                for (const i in thing) {
                    const val = thing[i];
                    thing[i] = val.filter(l => !!l.role).sort((a, b) => a.role.localeCompare(b.role));
                }
            }
            for (let i = 0; i < teams.length; i++) {
                teams[i].strips = things[i];
            }
            DB.addNewMatch(individualPool ? "individual" : "team", tournamentRound, tournamentGender, tournamentWeapon, tournamentName, tourneyDate, tourneyTime, venue, teamCount, teams);
        } else {
            DB.addNewMatch(individualPool ? "individual" : "team", tournamentRound, tournamentGender, tournamentWeapon, tournamentName, tourneyDate, tourneyTime, venue, teamCount, fencers);
        }
        setConfirmingChanges(false);
        enableScroll();
        history.push("/tournaments");
    }

    return (
        <TbTPage className="createTournamentPage">
            <h2 style={{ textAlign: "center", color: "white", margin: "30px" }}>Create Tournament</h2>
            <TbTTabView>
                <TbTTab title="Details">
                    <div className="createTournamentBox">
                        <div className="createTournamentCol1">
                            <div style={{ position: "absolute", left: "calc(50% - 175px)", zIndex: "10", marginTop: "15px" }}>
                                <Toast bg="danger" position="top-center" onClose={() => setToastOpen(false)} show={toastOpen && message !== ""} delay={5000} autohide>
                                    <Toast.Header>
                                        <strong className="me-auto">Invalid data</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {message}
                                    </Toast.Body>
                                </Toast>
                            </div>
                            <Form>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Tournament type</Form.Label>
                                    <Form.Select aria-label="Tournament type" onChange={(e) => setIndividualPool(e.target.value === "individual")}>
                                        <option value="team">Team Pool</option>
                                        <option value="individual">Individual Pool</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" onChange={(e) => setTournamentRound(Number(e.target.value))}>
                                    <Form.Label>Tournament Round</Form.Label>
                                    <Form.Select aria-label="Tournament round">
                                        <option value="1">Round 1</option>
                                        <option value="2">Round 2</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" onChange={(e) => setTournamentGender(e.target.value)}>
                                    <Form.Label>Genders</Form.Label>
                                    <Form.Select aria-label="Tournament genders">
                                        <option value="both">Both genders</option>
                                        <option value="boys">Men's</option>
                                        <option value="girls">Women's</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" onChange={(e) => setTournamentWeapon(e.target.value)}>
                                    <Form.Label>Weapons</Form.Label>
                                    <Form.Select aria-label="Tournament type">
                                        <option value="all">All Weapons</option>
                                        <option value="Sabre">Sabre</option>
                                        <option value="Foil">Foil</option>
                                        <option value="Epee">Epee</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Tournament Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tournament Name"
                                        value={tournamentName}
                                        onChange={(e) => {
                                            const regex = /^[0-9a-zA-Z(\-) ]+$/;
                                            if (e.target.value.match(regex) || e.target.value === "") {
                                                setTournamentName(e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Number of Teams</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Number of Teams (maximum 20)"
                                        max={20}
                                        min={3}
                                        onKeyPress={(e) => {
                                            if (Number(`${e.target.value}${e.key}`) > 20) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                        onChange={(e) => setTeamCount(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" name='tourney_date' onChange={(e) => setTourneyDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Time</Form.Label>
                                    <Form.Control type="time" name='tourney_time' onChange={(e) => setTourneyTime(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="formQuestion mb-3" controlId="createTournamentForm.ControlInput1">
                                    <Form.Label>Venue</Form.Label>
                                    <Form.Control type="text" placeholder="Venue" onChange={(e) => setVenue(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </TbTTab>
                <TbTTab title="Teams" hidden={individualPool} disabled={teamCount === 0}>
                    <div className="createTournamentBox">
                        <div className="createTournamentTeamsEdit">
                            <Form>
                                {
                                    teams.map((j, idx) => (
                                        <Form.Group className="formQuestionSlim mb-3" controlId="createTournamentForm.ControlInput1" key={`tourneyTeamInput${idx}`}>
                                            <Form.Label>Team {idx + 1}</Form.Label>
                                            <Form.Select style={{ marginRight: "20px", borderColor: (!!j.id && teams.filter(k => k.id === j.id && j.id !== "writeIn").length >= 2) ? "red" : "initial" }} aria-label="teamSelect" onChange={(e) => {
                                                const newName = e.target.value;
                                                teams[idx].id = newName;
                                                if (newName !== "writeIn") {
                                                    teams[idx].name = teamList[newName]?.teamName || "";
                                                    teams[idx].boysAndGirlsTeam = teamList[newName]?.boysAndGirlsTeam || false;
                                                }
                                                setTeams([...teams]);
                                            }}>
                                                <option value="">Choose team</option>
                                                <option value="writeIn">Write-in</option>
                                                {
                                                    options.filter(l => !individualPool ? true : (l[1].teamID === j.searchingTeam)).filter(l => !l[1].isAdmin).sort((a, b) => a[1].teamName.localeCompare(b[1].teamName)).map(l => (
                                                        <option value={l[0]} key={`adsfjasiodjfio4j${l[0]}`}>{l[1].teamName}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            { (j.id === "writeIn" || teams[idx].searchingTeam === "writeIn") && <Form.Control type="text" placeholder="Write-in name" onChange={(e) => {
                                                const newName = e.target.value;
                                                teams[idx].name = newName;
                                                setTeams([...teams]);
                                            }} /> }
                                            <Form.Control style={{ borderColor: (!!j.initial && teams.filter(k => k.initial === j.initial).length >= 2) ? "red" : "initial" }} type="text" placeholder={individualPool ? "Fencer initials" : `Team initials`} maxLength="4" onChange={(e) => {
                                                const newName = e.target.value;
                                                teams[idx].initial = newName;
                                                setTeams([...teams]);
                                            }} />
                                        </Form.Group>
                                    ))
                                }
                            </Form>
                        </div>
                    </div>
                </TbTTab>
                {
                    ["Sabre", "Foil", "Epee"].map(discipline => <TbTTab title={discipline} hidden={!individualPool || (tournamentWeapon !== "all" && tournamentWeapon !== discipline)} disabled={teamCount === 0}>
                        <div className="createTournamentBox">
                            <div className="createTournamentCol2">
                                { tournamentGender !== "girls" && <div className="createTournamentLeftCol" style={{ maxWidth: tournamentGender === "both" ? "50%" : "100%" }}>
                                    <h3>Boys fencers</h3>
                                    <Form>
                                        {
                                            fencers[discipline].boys.map((j, idx) => (
                                                <Form.Group className="formQuestionSlim mb-3" controlId="createTournamentForm.ControlInput1" key={`tourneyTeamInput${idx}`}>
                                                    <Form.Label>Fencer {idx + 1}</Form.Label>
                                                    <Form.Select defaultValue="" style={{ borderColor: (!!j.id && teams.filter(k => k.id === j.id).length >= 2) ? "red" : "initial" }} aria-label="teamSelect" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        if (newName === "writeIn") {
                                                            fencers[discipline].boys[idx].searchingTeam = newName;
                                                            fencers[discipline].boys[idx].teamName = newName;
                                                            fencers[discipline].boys[idx].name = "";
                                                            fencers[discipline].boys[idx].id = newName;
                                                        } else if (newName !== "") {
                                                            fencers[discipline].boys[idx].searchingTeam = newName;
                                                            fencers[discipline].boys[idx].teamName = teamList[newName].teamName;
                                                        }
                                                        setTeams([...teams]);
                                                    }}>
                                                        <option value="" disabled hidden>Choose fencer team</option>
                                                        <option value="writeIn">Write-in</option>
                                                        {
                                                            Object.entries(teamList).sort((a, b) => a[1].teamName.localeCompare(b[1].teamName)).filter(l => l[1].isPublished).map(l => <option value={l[0]} key={`ewtadafg${l[0]}`}>{l[1].teamName}</option>)
                                                        }
                                                    </Form.Select>
                                                    { fencers[discipline].boys[idx].searchingTeam === "writeIn" && <Form.Control style={{ marginLeft: "20px" }} type="text" placeholder="Team name" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].boys[idx].teamName = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                    { fencers[discipline].boys[idx].searchingTeam !== "writeIn" && fencers[discipline].boys[idx].searchingTeam && <Form.Select defaultValue="" style={{ marginLeft: "20px", borderColor: (!!j.id && teams.filter(k => k.id === j.id && j.id !== "writeIn").length >= 2) ? "red" : "initial" }} aria-label="teamSelect" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].boys[idx].id = newName;
                                                        if (newName !== "writeIn") {
                                                            const val = fencerList.find(p => p.fencerID === newName);
                                                            fencers[discipline].boys[idx].name = `${val.firstName} ${val.lastName}`;
                                                        }
                                                        setTeams([...teams]);
                                                    }}>
                                                        <option value="" disabled hidden>Choose fencer</option>
                                                        <option value="writeIn">Write-in</option>
                                                        {
                                                            options.filter(l => !individualPool ? true : (l[1].teamID === j.searchingTeam)).filter(l => !l[1].isAdmin).sort((a, b) => a[1].teamName.localeCompare(b[1].teamName)).map(l => (
                                                                <option value={individualPool ? l[1].fencerID : l[0]} key={`adsfjasiodjfio4j${l[0]}`}>{l[1].firstName ? `${l[1].firstName} ${l[1].lastName}` : l[1].teamName}</option>
                                                            ))
                                                        }
                                                    </Form.Select> }
                                                    { (j.id === "writeIn" || fencers[discipline].boys[idx].searchingTeam === "writeIn") && <Form.Control style={{ marginLeft: "20px" }} type="text" placeholder="Write-in name" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].boys[idx].name = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                    { fencers[discipline].boys[idx].name && <Form.Control style={{ marginLeft: "20px", borderColor: (!!j.initial && teams.filter(k => k.initial === j.initial).length >= 2) ? "red" : "initial" }} type="text" placeholder={individualPool ? "Fencer initials" : `Team initials`} maxLength="4" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].boys[idx].initial = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                </Form.Group>
                                            ))
                                        }
                                    </Form>
                                </div> }
                                { tournamentGender !== "boys" && <div className="createTournamentRightCol" style={{ maxWidth: tournamentGender === "both" ? "50%" : "100%" }}>
                                    <h3>Girls fencers</h3>
                                    <Form>
                                        {
                                            tournamentGender !== "boys" && fencers[discipline].girls.map((j, idx) => (
                                                <Form.Group className="formQuestionSlim mb-3" controlId="createTournamentForm.ControlInput1" key={`tourneyTeamInput${idx}`}>
                                                    <Form.Label>Fencer {idx + 1}</Form.Label>
                                                    <Form.Select defaultValue="" style={{ borderColor: (!!j.id && teams.filter(k => k.id === j.id).length >= 2) ? "red" : "initial" }} aria-label="teamSelect" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        if (newName === "writeIn") {
                                                            fencers[discipline].girls[idx].searchingTeam = newName;
                                                            fencers[discipline].girls[idx].teamName = newName;
                                                            fencers[discipline].girls[idx].name = "";
                                                            fencers[discipline].girls[idx].id = newName;
                                                        } else if (newName !== "") {
                                                            fencers[discipline].girls[idx].searchingTeam = newName;
                                                            fencers[discipline].girls[idx].teamName = teamList[newName].teamName;
                                                        }
                                                        setTeams([...teams]);
                                                    }}>
                                                        <option value="" disabled hidden>Choose fencer team</option>
                                                        <option value="writeIn">Write-in</option>
                                                        {
                                                            Object.entries(teamList).sort((a, b) => a[1].teamName.localeCompare(b[1].teamName)).filter(l => l[1].isPublished).map(l => <option value={l[0]} key={`ewtadafg${l[0]}`}>{l[1].teamName}</option>)
                                                        }
                                                    </Form.Select>
                                                    { fencers[discipline].girls[idx].searchingTeam === "writeIn" && <Form.Control style={{ marginLeft: 20 }} type="text" placeholder="Team name" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].girls[idx].teamName = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                    { fencers[discipline].girls[idx].searchingTeam !== "writeIn" && fencers[discipline].girls[idx].searchingTeam && <Form.Select defaultValue="" style={{ marginLeft: "20px", borderColor: (!!j.id && teams.filter(k => k.id === j.id && j.id !== "writeIn").length >= 2) ? "red" : "initial" }} aria-label="teamSelect" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].girls[idx].id = newName;
                                                        if (newName !== "writeIn") {
                                                            const val = fencerList.find(p => p.fencerID === newName);
                                                            fencers[discipline].girls[idx].name = `${val.firstName} ${val.lastName}`;
                                                        }
                                                        setTeams([...teams]);
                                                    }}>
                                                        <option value="" disabled hidden>Choose fencer</option>
                                                        <option value="writeIn">Write-in</option>
                                                        {
                                                            options.filter(l => !individualPool ? true : (l[1].teamID === j.searchingTeam)).filter(l => !l[1].isAdmin).sort((a, b) => a[1].teamName.localeCompare(b[1].teamName)).map(l => (
                                                                <option value={individualPool ? l[1].fencerID : l[0]} key={`adsfjasiodjfio4j${l[0]}`}>{l[1].firstName ? `${l[1].firstName} ${l[1].lastName}` : l[1].teamName}</option>
                                                            ))
                                                        }
                                                    </Form.Select> }
                                                    { (j.id === "writeIn" || fencers[discipline].girls[idx].searchingTeam === "writeIn") && <Form.Control style={{ marginLeft: 20 }} type="text" placeholder="Write-in name" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].girls[idx].name = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                    { fencers[discipline].girls[idx].name && <Form.Control style={{ marginLeft: 20, borderColor: (!!j.initial && teams.filter(k => k.initial === j.initial).length >= 2) ? "red" : "initial" }} type="text" placeholder={individualPool ? "Fencer initials" : `Team initials`} maxLength="4" onChange={(e) => {
                                                        const newName = e.target.value;
                                                        fencers[discipline].girls[idx].initial = newName;
                                                        setTeams([...teams]);
                                                    }} /> }
                                                </Form.Group>
                                            ))
                                        }
                                    </Form>
                                </div> }
                            </div>
                        </div>
                    </TbTTab>)
                }
            </TbTTabView>
            <Button style={{ margin: "0 auto 20px 0" }} onClick={() => { setToastOpen(true); if (message === "") { setConfirmingChanges(true) }}}>Create Tournament</Button>
            { confirmingChanges && <Modal close={() => setConfirmingChanges(false)}>
                <h2 style={{ textAlign: "left" }}>Please double check your inputs. Once you create this tournament you will not be able to change it. Are you sure you wish to create this tournament?</h2>
                <div style={{ display: "flex" }}>
                    <Button variant="success" onClick={submit} style={{ marginRight: 10 }}>Create</Button>
                    <Button variant="danger" onClick={() => setConfirmingChanges(false)}>Go back</Button>
                </div>
            </Modal> }
        </TbTPage>
    )
}
