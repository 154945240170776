import { useEffect } from "react";
import { enableScroll, disableScroll } from "../../utils/helpers";
import "./Modal.css"

const Modal: React.FC<{ close: () => void, closeable?: boolean }> = ({ children, close, closeable = true }) => {
    const handleClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // TS really hates mouse events
        // @ts-ignore
        if (e?.target?.id === "modalBox") {
            enableScroll();
            close();
        }
    }

    useEffect(() => {
        disableScroll();

        return enableScroll;
    }, []);

    return (
        <div className="modalBox" onClick={ (e) => closeable && handleClick(e) } id="modalBox">
            <div className="modalContent">
                {
                    closeable && <span className="modalClose" onClick={() => { enableScroll(); close() }}>&times;</span>
                }
                { children }
            </div>
        </div>
    )
}

export default Modal;
