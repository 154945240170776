/* eslint-disable array-callback-return */
import fire from "./firebaseSetup";
import { Bout, Fencer, MatchData, MatchTeam, MeetBouts, Team, TeamAdministrators, TeamMember, TournamentData, TournamentDataSmaller, TournamentDataSmallerDate, TournamentDataWithBouts, UserInfo, Weapon } from "../types";
import { getRandomInt } from "./helpers";

const dbPrefix = "";

/**
 * Helper function for calling a database ref
 */
export function getRefs<T>(ref: string): Promise<T> {
    return new Promise(res => fire.database().ref(ref).once("value", v => {
        res(v.val());
    }));
}

/**
 * Helper function for initializing a database listener
 */
export function addDBListener(ref: string, listener: (val: any) => void) {
    fire.database().ref(ref).on("value", v => {
        listener(v.val());
    })
}

/**
 * Helper function for stopping a database listener
 */
export function removeDBListener(ref: string) {
    fire.database().ref(ref).off();
}

const formatArrs = (a: string): [number, number][] => a.split(" ").map(l => l.split("-").map(Number) as [number, number]);

const orderArrs = {
    2: formatArrs("1-2"),
    3: formatArrs("1-2 2-3 1-3"),
    4: formatArrs("1-4 2-3 1-3 2-4 3-4 1-2"),
    5: formatArrs("1-2 3-4 5-1 2-3 5-4 1-3 2-5 4-1 3-5 4-2"),
    6: formatArrs("1-2 4-5 2-3 5-6 3-1 6-4 2-5 1-4 5-3 1-6 4-2 3-6 5-1 3-4 6-2"),
    7: formatArrs("1-4 2-5 3-6 7-1 5-4 2-3 6-7 5-1 4-3 6-2 5-7 3-1 4-8 7-2 3-5 1-6 2-4 7-3 6-5 1-2 4-7"),
    8: formatArrs("2-3 1-5 7-4 6-8 1-2 3-4 5-6 8-7 4-1 5-2 8-3 6-7 4-2 8-1 7-5 3-6 2-8 7-4 6-1 3-7 4-8 2-6 3-5 1-7 4-6 8-5 7-2 1-3"),
    9: formatArrs("1-9 2-8 3-7 4-6 1-5 2-9 8-3 7-4 6-5 1-2 9-3 8-4 7-5 6-1 3-2 9-4 5-8 7-6 3-1 2-4 5-9 8-6 7-1 4-3 5-2 6-9 8-7 4-1 5-3 6-2 9-7 1-8 4-5 3-6 2-7 9-8"),
    10: formatArrs("1-4 6-9 2-5 7-10 3-1 8-6 4-5 9-10 2-3 7-8 5-1 10-6 4-2 9-7 5-3 10-8 1-2 6-7 3-4 8-9 5-10 1-6 2-7 3-8 4-9 6-5 10-2 8-1 7-4 9-3 2-6 5-8 4-10 1-9 3-7 8-2 6-4 9-5 10-3 7-1 4-8 2-9 3-6 5-7 1-10"),
    11: formatArrs("1-2 7-8 4-5 10-11 2-3 8-9 5-6 3-1 9-7 6-4 2-5 8-11 1-4 7-10 5-3 11-9 1-6 4-2 10-8 3-6 5-1 11-7 3-4 9-10 6-2 1-7 3-9 10-4 8-2 5-11 1-8 9-2 3-10 4-11 6-7 9-1 2-10 11-3 7-5 6-8 10-1 11-2 4-7 8-5 6-9 11-1 7-3 4-8 9-5 6-10 2-7 8-3 4-9 10-5 6-11"),
    12: formatArrs("1-2 7-8 4-5 10-11 2-3 8-9 5-6 11-12 3-1 9-7 6-4 12-10 2-5 8-11 1-4 7-10 5-3 11-9 1-6 7-12 4-2 10-8 3-6 9-12 5-1 11-7 3-4 9-10 6-2 12-8 1-7 3-9 10-4 8-2 5-11 12-6 1-8 9-2 3-10 4-11 12-5 6-7 9-1 2-10 11-3 4-12 7-5 6-8 10-1 11-2 12-3 4-7 8-5 6-9 11-1 2-12 7-3 4-8 9-5 6-10 12-1 2-7 8-3 4-9 10-5 6-11")
};

export default class DB {
    /**
     * Add newly created user to db
     */
    static createUser (uid: string, name: string, email: string): void {
        const now = Date.now();
        fire.database().ref(`/users/${uid}`).set({
            name,
            email,
            isVerified: false,
            isSuperadmin: false,
            isBoutCommittee: false,
            isUberAdmin: false,
            createdOn: now,
            verificationPIN: getRandomInt(10000, 99999),
            teams: {}
        });
    }
    static getUserInfo(): Promise<UserInfo | null> {
        return new Promise(async res => {
            if (fire.auth().currentUser) {
                // I prefer returning null here rather than undefined because with undefined it's usually harder to tell if there is a bug
                fire.database().ref(`/users/${fire.auth().currentUser!.uid}`).once("value", (queryResults) => {
                    const val = queryResults.val();
                    if (val) {
                        val.isBoutCommittee = val.isBoutCommittee ?? false;
                        val.isUberAdmin = val.isUberAdmin ?? false;
                    }
                    res(val);
                });
            } else {
                res(null);
            }
        });
    }

    static async getTeamList (): Promise<Record<string, Team & { teamID: string }>> {
        const teams = await getRefs<Record<string, Team>>(`/teams/list`);
        return Object.fromEntries(Object.entries(teams).map(l => [l[0], { ...l[1], teamID: l[0] }]));
    }

    /**
     * Get tournaments for a team
     */
    static tournamentsForTeam (teamID: string, seasonName: string, boysOrGirlsTeamInDatabase: string): Promise<TournamentDataSmallerDate[]> {
        return new Promise(async res => {
            const tournaments: TournamentDataSmallerDate[] = [];
            await new Promise(r => getRefs(`${dbPrefix}/teams/details/${teamID}/seasons/details/${seasonName}/undefined/tournaments`).then(async (v: Record<string, TournamentDataSmaller>) => {
                if (!v) {
                    return r(null);
                }
                const mapped = await Promise.all(Object.keys(v).map(l => DB.getTournamentData(l)));
                Object.entries(v)
                    .filter((_, idx) => mapped[idx].seasonName === seasonName && [mapped[idx].team1BoysOrGirlsTeam, mapped[idx].team2BoysOrGirlsTeam].includes(boysOrGirlsTeamInDatabase))
                    .map(dbTournamentObj => {
                        const rawTournamentObj = dbTournamentObj[1];
                        const tournamentObj: TournamentDataSmallerDate = {
                            ...rawTournamentObj,
                            tournamentID: dbTournamentObj[0],
                            startDate: new Date(Date.parse(rawTournamentObj.startDate)),
                            createdOn: new Date(Date.parse(rawTournamentObj.createdOn)),
                        }
                        tournamentObj.modifiedName = (() => {
                            let modifiedName = "";
                            if ("tournamentName" in tournamentObj && (tournamentObj.tournamentName.includes("vs") || tournamentObj.tournamentName.includes("@"))) {
                                let modifiedNameArr = tournamentObj.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
                                modifiedNameArr = modifiedNameArr
                                    .filter(l => !!l)
                                    .filter(l => !l.includes("vs") && !l.includes("@"))
                                    .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
                                if (tournamentObj.tournamentName.includes("@")) {
                                    modifiedNameArr.reverse();
                                }
                                modifiedName = `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
                            } else {
                                modifiedName = `${tournamentObj.team1Name} (H) vs. ${tournamentObj.team2Name} (A)`;
                            }
                            return modifiedName;
                        })();
                        tournaments.unshift(tournamentObj);
                    });
            }));
            fire.database().ref(`${dbPrefix}/teams/details/${teamID}/seasons/details/${seasonName}/${boysOrGirlsTeamInDatabase}/tournaments`).orderByChild('startDate').on("value", (queryResults) => {
                queryResults.forEach((dbTournamentObj) => {
                    const rawTournamentObj = dbTournamentObj.val();
                    const tournamentObj: TournamentDataSmallerDate = {
                        ...rawTournamentObj,
                        tournamentID: dbTournamentObj.key!,
                        startDate: new Date(Date.parse(rawTournamentObj.startDate)),
                        createdOn: new Date(Date.parse(rawTournamentObj.createdOn)),
                    }
                    tournamentObj.modifiedName = (() => {
                        let modifiedName = "";
                        if ("tournamentName" in tournamentObj && (tournamentObj.tournamentName.includes("vs") || tournamentObj.tournamentName.includes("@"))) {
                            let modifiedNameArr = tournamentObj.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
                            modifiedNameArr = modifiedNameArr
                                .filter(l => !!l)
                                .filter(l => !l.includes("vs") && !l.includes("@"))
                                .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
                            if (tournamentObj.tournamentName.includes("@")) {
                                modifiedNameArr.reverse();
                            }
                            modifiedName = `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
                        } else {
                            modifiedName = `${tournamentObj.team1Name} (H) vs. ${tournamentObj.team2Name} (A)`;
                        }
                        return modifiedName;
                    })();
                    tournaments.unshift(tournamentObj);
                });
                tournaments.sort((a, b) => (b.startDate > a.startDate) ? 1 : (a.startDate === b.startDate) ? ((a.tournamentName > b.tournamentName) ? 1 : -1) : -1)
                res(tournaments);
            });
        });
    }

    // TODO: later
    static teamData (teamID: string, listener?: (data: Team) => void): Promise<Team> {
        return new Promise(async (res) => {
            fire.database().ref(dbPrefix + `/teams/list/${teamID}`).on("value", async (data) => {
                listener && listener(data.val());
                res(data.val());
            });
        });
    }

    static createTeam (teamName: string, country: string, countryCode: string, region: string, teamGender: string) {
        return new Promise(async (res) => {
            let user = fire.auth().currentUser;

            if (user) {
                let d = new Date();

                let databaseAdditions = {};

                let teamPIN = Math.floor(Math.random() * 90000) + 10000;

                let teamID = (await fire.database().ref(dbPrefix + '/teams/list/').push()).key;

                const boysAndGirlsTeam = teamGender === "both" ? true : teamGender;

                databaseAdditions['/teams/list/' + teamID] = {
                    teamName: teamName,
                    teamPIN: teamPIN,
                    isPublished: false,
                    country: country,
                    countryCode: countryCode,
                    region: region,
                    teamColor: 'rgba(' + getRandomInt(0, 100) +
                        ',' + getRandomInt(0, 230) +
                        ',' + getRandomInt(0, 255) +
                        ',' + (getRandomInt(40, 80) / 100) + ')',
                    createdOn: d.toString(),
                    createdBy: user.displayName,
                    createdByUID: user.uid,
                    boysAndGirlsTeam: boysAndGirlsTeam,
                };

                databaseAdditions['/teams/details/' + teamID] = {
                    administrators: {
                        [user.uid]: {
                            name: user.displayName,
                            email: user.email,
                        }
                    }
                };

                databaseAdditions['/users/' + user.uid + '/teams/' + teamID] = {
                    teamName: teamName,
                    createdOn: d.toString(),
                };
                if (dbPrefix !== "")
                    fire.database().ref(dbPrefix).update(databaseAdditions, () => { res(teamID) });
                else
                    fire.database().ref().update(databaseAdditions, () => { res(teamID) });
            };
        });
    }

    static deleteTeam(teamID: string, listener?: () => void) {
        return new Promise<void>(res => {
            const user = fire.auth().currentUser;
            if (user) {
            let databaseDeletions = {};

            fire.database()
                .ref(dbPrefix + "/fencers/")
                .orderByChild("teamID")
                .equalTo(teamID)
                .once("value", (fencers) => {
                    if (fencers) {
                        fencers.forEach((fencer) => {
                            databaseDeletions["/fencers/" + fencer.key] = null;
                        });

                        fire.database()
                            .ref(dbPrefix + "/teams/details/" + teamID + "/administrators")
                            .once("value", (admins) => {
                                admins.forEach((admin) => {
                                databaseDeletions[
                                    "/users/" + admin.key + "/teams/" + teamID
                                ] = null;
                                });

                                databaseDeletions["/teams/details/" + teamID] = null;
                                databaseDeletions["/teams/list/" + teamID] = null;

                                if (dbPrefix !== "") {
                                    fire.database()
                                        .ref(dbPrefix)
                                        .update(databaseDeletions)
                                        .then(() => {
                                            listener && listener();
                                            res();
                                        });
                                } else {
                                    fire.database()
                                        .ref()
                                        .update(databaseDeletions)
                                        .then(() => {
                                            listener && listener();
                                            res();
                                        });
                                }
                            });
                    } else {
                        fire.database()
                            .ref(dbPrefix + "/teams/details/" + teamID + "/administrators")
                            .once("value", (admins) => {
                                admins.forEach((admin) => {
                                    databaseDeletions[
                                        "/users/" + admin.key + "/teams/" + teamID
                                    ] = null;
                                });

                                databaseDeletions["/teams/details/" + teamID] = null;
                                databaseDeletions["/teams/list/" + teamID] = null;

                                if (dbPrefix !== "") {
                                    fire.database()
                                        .ref(dbPrefix)
                                        .update(databaseDeletions)
                                        .then(() => {
                                            listener && listener();
                                            res();
                                        });
                                } else {
                                    fire.database()
                                        .ref()
                                        .update(databaseDeletions)
                                        .then(() => {
                                            listener && listener();
                                            res();
                                        });
                                }
                            });
                    }
                });
            }
        });
    }

    static deleteTeamSeason(teamID: string, seasonName: string) {
        return new Promise<void>(res => {
            const user = fire.auth().currentUser;

            if (user) {
                let databaseDeletions = {};

                databaseDeletions[
                    "/teams/details/" + teamID + "/seasons/list/" + seasonName
                ] = null;

                databaseDeletions[
                    "/teams/list-by-season/" + seasonName + "/" + teamID
                ] = null;

                databaseDeletions[
                    "/teams/details/" + teamID + "/seasons/details/" + seasonName
                ] = null;

                if (dbPrefix !== "") fire.database().ref(dbPrefix).update(databaseDeletions);
                else fire.database().ref().update(databaseDeletions);
                res();
            }
        });
    }

    /**
     * Adds a new match to the database
     */
    static addNewMatch<T extends "individual" | "team">(type: T, round: number, gender: string, weapons: Weapon | "all", name: string, date: string, time: string, venue: string, teamCount: number, teams: T extends "team" ? MatchTeam[] : Record<string, unknown>) {
        const splitDate = date.split("-").map(Number);
        const splitTime = time.split(":");
        const newDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2], Number(splitTime[0]), Number(splitTime[1]));
        const databaseAdditions = {};
        const user = fire.auth().currentUser;
        if (type === "team") {
            const sorted: (MatchTeam & { strips?: Record<Weapon, any> })[] = [...(teams as MatchTeam[])].sort();
            for (const i in sorted) {
                sorted[i].seed = Number(i);
                if (sorted[i].id === "writeIn") {
                    sorted[i].id = `writeIn${Math.random()}`;
                }
            }

            const addBouts = (matchID: string, gender: string) => {
                let boutOrder = 0;

                const weaponsArr = weapons === "all" ? ["Sabre", "Foil", "Epee"] : [weapons];

                const mTeams = (teams as MatchTeam[]).filter(l => (typeof l.boysAndGirlsTeam === "boolean") || l.boysAndGirlsTeam === gender);

                for (const weapon of weaponsArr) {
                    let leOrder = orderArrs[mTeams.length];

                    if (!leOrder) {
                        leOrder = [];
                        for (let i = 1; i <= mTeams.length; i++) {
                            for (let j = 1; j <= mTeams.length; j++) {
                                if (j >= i) {
                                    continue;
                                }
                                leOrder.push([i, j]);
                            }
                        }
                    }

                    // TODO: Add typing for match bouts
                    const bouts: Record<string, any>[] = [];

                    for (const thing of leOrder) {
                        const i = thing[0] - 1;
                        const j = thing[1] - 1;

                        // const boutID = fire.database().ref(`${dbPrefix}/matches/details/${matchID}/bouts/${weapon}`).push().key; // generate a unique key
                        const seasonYear = newDate.getMonth() > 7 ? newDate.getFullYear() : newDate.getFullYear() - 1;
                        const seasonName = `${seasonYear}-${seasonYear + 1}`;

                        const base = {
                            tournamentID: matchID,
                            tournamentPIN: Math.floor(Math.random() * 90000) + 10000,
                            boutOrder: boutOrder++,
                            weapon: weapon,
                            player1: sorted[i].name,
                            player1ID: sorted[i].id,
                            player1TimeOut: false,
                            player1TeamSeason: seasonName,
                            player2: sorted[j].name,
                            player2ID: sorted[j].id,
                            player2TimeOut: false,
                            player2TeamSeason: seasonName,
                            boutColor: 'rgba(' + getRandomInt(0, 100) +
                            ',' + getRandomInt(0, 230) +
                            ',' + getRandomInt(0, 255) +
                            ',' + (getRandomInt(40, 80) / 100) + ')',
                            currentSpectatorsNum: 0,
                            heartL: 1,
                            heartR: 1,
                        };

                        if (type === "individual") {
                            const r = {
                                ...base,
                                score1: 0,
                                score2: 0,
                                player1BlackCard: 0,
                                player1RedCard: 0,
                                player1YellowCard: 0,
                                player2BlackCard: 0,
                                player2RedCard: 0,
                                player2YellowCard: 0,
                            }

                            bouts.push(r);
                        } else {
                            const teamBout = (strip) => ({
                                score1: 0,
                                score2: 0,
                                team1ID: sorted[i].id,
                                team2ID: sorted[j].id,
                                player1Name: sorted[i]!.strips![weapon]?.[strip] ? `${sorted[i]!.strips![weapon]?.[strip]?.lastName.trim()}, ${sorted[i]!.strips![weapon]?.[strip]?.firstName.trim()}` : "Unspecified",
                                player2Name: sorted[j]!.strips![weapon]?.[strip] ? `${sorted[j]!.strips![weapon]?.[strip]?.lastName.trim()}, ${sorted[j]!.strips![weapon]?.[strip]?.firstName.trim()}` : "Unspecified",
                                player1BlackCard: 0,
                                player1RedCard: 0,
                                player1YellowCard: 0,
                                player2BlackCard: 0,
                                player2RedCard: 0,
                                player2YellowCard: 0,
                            });

                            const r = {
                                ...base,
                                aStrip: { ...teamBout(0) },
                                bStrip: { ...teamBout(1) },
                                cStrip: { ...teamBout(2) },
                            }

                            bouts.push(r);
                        }
                    }

                    databaseAdditions[`${dbPrefix}/matches/details/${matchID}/bouts`] = bouts;
                }
            }

            if (user) {
                const weaponsArr = weapons === "all" ? ["Sabre", "Foil", "Epee"] : [weapons];
                const gendersArr = gender === "both" ? ["boys", "girls"] : [gender];
                const newIDs = {};
                weaponsArr.map(weapon => {
                    newIDs[weapon] = {};
                    gendersArr.map(gender => {
                        newIDs[weapon][gender] = fire.database().ref(dbPrefix + '/matches/').push().key;
                    });
                });
                weaponsArr.map(weapon => {
                    gendersArr.map(gender => {
                        const mTeams = (teams as MatchTeam[]).filter(l => (typeof l.boysAndGirlsTeam === "boolean") || l.boysAndGirlsTeam === gender);
                        const matchID = newIDs[weapon][gender];
                        databaseAdditions['/matches/' + matchID] = {
                            matchType: type,
                            round,
                            gender: gender,
                            weapon,
                            name,
                            date: newDate,
                            venue,
                            teams: mTeams,
                            teamCount: mTeams.length,
                            pairIDs: newIDs,
                            createdOn: new Date().toString(),
                            createdBy: user.displayName,
                            createdByUID: user.uid,
                        };
                        addBouts(matchID, gender);
                    });
                })

                if (dbPrefix !== "")
                    fire.database().ref(dbPrefix).update(databaseAdditions);
                else
                    fire.database().ref().update(databaseAdditions);
            }
        } else {
            const fencers = teams as Record<string, any>;
            for (const i in fencers) {
                for (let k = 0; k < fencers[i].boys.length; k++) {
                    const f = fencers[i].boys[k];
                    f.seed = k;
                    if (f.id === "writeIn") {
                        f.id = `writeIn${Math.random()}`
                    }
                }
                for (let k = 0; k < fencers[i].boys.length; k++) {
                    const f = fencers[i].girls[k];
                    f.seed = k;
                    if (f.id === "writeIn") {
                        f.id = `writeIn${Math.random()}`
                    }
                }
            }

            const addBouts = (matchID: string, weapon: Weapon, gender: string) => {
                let boutOrder = 0;

                let leOrder = orderArrs[teamCount];

                if (!leOrder) {
                    leOrder = [];
                    for (let i = 1; i <= teamCount; i++) {
                        for (let j = 1; j <= teamCount; j++) {
                            if (j >= i) {
                                continue;
                            }
                            leOrder.push([i, j]);
                        }
                    }
                }

                const bouts: Record<string, any>[] = [];

                for (const thing of leOrder) {
                    const i = thing[0] - 1;
                    const j = thing[1] - 1;

                    // const boutID = fire.database().ref(`${dbPrefix}/matches/details/${matchID}/bouts/${weapon}`).push().key; // generate a unique key
                    const seasonYear = newDate.getMonth() > 7 ? newDate.getFullYear() : newDate.getFullYear() - 1;
                    const seasonName = `${seasonYear}-${seasonYear + 1}`;

                    const r = {
                        tournamentID: matchID,
                        tournamentPIN: Math.floor(Math.random() * 90000) + 10000,
                        boutOrder: boutOrder++,
                        weapon,
                        player1: fencers[weapon][gender][i].name,
                        player1ID: fencers[weapon][gender][i].id,
                        player1TimeOut: false,
                        player1TeamSeason: seasonName,
                        score1: 0,
                        player2: fencers[weapon][gender][j].name,
                        player2ID: fencers[weapon][gender][j].id,
                        player2TimeOut: false,
                        player2TeamSeason: seasonName,
                        score2: 0,
                        player1BlackCard: 0,
                        player1RedCard: 0,
                        player1YellowCard: 0,
                        player2BlackCard: 0,
                        player2RedCard: 0,
                        player2YellowCard: 0,
                        boutColor: 'rgba(' + getRandomInt(0, 100) +
                        ',' + getRandomInt(0, 230) +
                        ',' + getRandomInt(0, 255) +
                        ',' + (getRandomInt(40, 80) / 100) + ')',
                        currentSpectatorsNum: 0,
                        heartL: 1,
                        heartR: 1,
                    }

                    // databaseAdditions[`${dbPrefix}/matches/details/${matchID}/bouts/${weapon}/${boutID}`] = r;
                    bouts.push(r);
                }

                databaseAdditions[`${dbPrefix}/matches/details/${matchID}/bouts`] = bouts;
            }

            if (user) {
                const weaponsArr: Weapon[] = weapons === "all" ? ["Sabre", "Foil", "Epee"] : [weapons];
                const gendersArr = gender === "both" ? ["boys", "girls"] : [gender];
                const newIDs = {};
                weaponsArr.map(weapon => {
                    newIDs[weapon] = {};
                    gendersArr.map(gender => {
                        newIDs[weapon][gender] = fire.database().ref(dbPrefix + '/matches/').push().key;
                    });
                });
                weaponsArr.map((weapon: Weapon) => {
                    gendersArr.map(gender => {
                        const matchID = newIDs[weapon][gender];
                        databaseAdditions['/matches/' + matchID] = {
                            matchType: type,
                            round,
                            gender: gender,
                            weapon,
                            name,
                            date: newDate,
                            venue,
                            fencers: fencers[weapon][gender],
                            teamCount,
                            pairIDs: newIDs,
                            createdOn: new Date().toString(),
                            createdBy: user.displayName,
                            createdByUID: user.uid,
                        };
                        addBouts(matchID, weapon, gender);
                    });
                })

                if (dbPrefix !== "")
                    fire.database().ref(dbPrefix).update(databaseAdditions);
                else
                    fire.database().ref().update(databaseAdditions);
            }
        }
    }

    static deleteMatch(matchData: { matchID: string, pairID: string }) {
        const { matchID } = matchData;
        const databaseAdditions = {};
        databaseAdditions[`/matches/${matchID}`] = null;
        if (matchData.pairID) {
            databaseAdditions[`/matches/${matchData.pairID}/pairID`] = null;
        }
        if (dbPrefix !== "")
            fire.database().ref(dbPrefix).update(databaseAdditions);
        else
            fire.database().ref().update(databaseAdditions);
    }

    /**
     * Adds a new fencer to a team in the database
     */
    static addNewFencerToTeamAndSeason(teamID: string, teamName: string, season: string, weapon: Weapon, firstName: string, lastName: string, gradYear: string, associationID: string, boysOrGirlsTeam: string) {
        const user = fire.auth().currentUser;

        const d = new Date();
        const databaseAdditions = {};
        const fencerPIN = Math.floor(Math.random() * 90000) + 10000;

        let boysOrGirlsTeamInDatabase = '';
        if (boysOrGirlsTeam === 'boys') {
            boysOrGirlsTeamInDatabase = '/boys';
        } else if (boysOrGirlsTeam === 'girls') {
            boysOrGirlsTeamInDatabase = '/girls';
        }

        if (user) {
            const fencerID = fire.database().ref(dbPrefix + '/fencers/').push().key;

            databaseAdditions['/fencers/' + fencerID] = {
                teamID: teamID,
                teamName: teamName,
                firstName: firstName,
                lastName: lastName,
                gradYear: gradYear,
                associationID: associationID,
                fencerPIN: fencerPIN,

                createdOn: d.toString(),
                createdBy: user.displayName,
                createdByUID: user.uid,
            };

            databaseAdditions['/teams/details/' + teamID + '/fencers' + boysOrGirlsTeamInDatabase + "/" + fencerID] = {
                firstName: firstName,
                lastName: lastName,
                gradYear: gradYear,
            };

            databaseAdditions['/teams/details/' + teamID + '/seasons/details/' + season
                // eslint-disable-next-line no-useless-concat
                + boysOrGirlsTeamInDatabase + '/squads' + '/' + weapon + '/' + fencerID] = {
                firstName: firstName,
                lastName: lastName,
            };

            if (dbPrefix !== "")
                fire.database().ref(dbPrefix).update(databaseAdditions);
            else
                fire.database().ref().update(databaseAdditions);

            return fencerID;
        } else {
            return null;
        }
    }

    static getTeamFencersList(teamID: string, boysOrGirlsTeam: string): Promise<{ firstName: string; gradYear: string; lastName: string; id: string }[]> {
        return new Promise(res => {
            let boysOrGirlsTeamInDatabase = '';
            if (boysOrGirlsTeam === 'boys') {
                boysOrGirlsTeamInDatabase = '/boys';
            } else if (boysOrGirlsTeam === 'girls') {
                boysOrGirlsTeamInDatabase = '/girls';
            }

            fire.database().ref(dbPrefix + '/teams/details/' + teamID +
                '/fencers' + boysOrGirlsTeamInDatabase).once("value", (queryResults) => {
                    const fencers: { firstName: string; gradYear: string; lastName: string; id?: string }[] = [];
                    queryResults.forEach((f) => {
                        let fencer = f.val();
                        fencer.id = f.key;
                        fencers.push(fencer)
                    });
                    const sorted = fencers.sort(((a, b) => (a.firstName > b.firstName) ? 1 :
                        (a.firstName < b.firstName) ? -1 :
                            (a.lastName > b.lastName) ? 1 : -1));

                    res(sorted as { firstName: string; gradYear: string; lastName: string; id: string }[]);
                })
        });
    }
    static getTeamRosterForSeason(teamID: string, seasonName: string, boysOrGirls: string, listener: (val: Record<Weapon, TeamMember[]> | []) => void): Promise<Record<Weapon, TeamMember[]> | []> {
        return new Promise(async res => {
            let boysAndGirlsInDatabase = '';
            if (boysOrGirls === 'boys') {
                boysAndGirlsInDatabase = '/boys';
            } else if (boysOrGirls === 'girls') {
                boysAndGirlsInDatabase = '/girls';
            }

            if (teamID !== '') { //tournaments with manual teams will send teamID=''
                fire.database().ref(`${dbPrefix}/teams/details/${teamID}/seasons/details/${seasonName}${boysAndGirlsInDatabase}/squads`).off();
                fire.database().ref(`${dbPrefix}/teams/details/${teamID}/seasons/details/${seasonName}${boysAndGirlsInDatabase}/squads`).on("value", async (queryResults) => {
                    // Don't ask me why this is here because I honestly have no clue
                    const roleObj: Record<Weapon, Record<string, TeamMember>> = await getRefs(`/teams/details/${teamID}/seasons/details/${seasonName}${boysAndGirlsInDatabase}/squads`);
                    const rosterDbObj = queryResults.val();

                    for (const discipline in rosterDbObj) {
                        const ids = Object.keys(rosterDbObj[discipline])
                        const vals = await Promise.all(ids.map(l => getRefs(`/fencers/${l}`)));
                        rosterDbObj[discipline] = vals.reduce((acc: Record<string, any>, cur, idx) => ({ ...acc, [ids[idx]]: cur }), {});
                    }

                    const rosterResultObj: Record<Weapon, TeamMember[]> = { Sabre: [], Foil: [], Epee: [] };

                    ['Sabre', 'Foil', 'Epee'].forEach((weapon: Weapon) => {
                        const fencersArray: TeamMember[] = [];

                        if (rosterDbObj && rosterDbObj[weapon]) {
                            Object.keys(rosterDbObj[weapon]).forEach((fencerKey) => {
                                if (!rosterDbObj[weapon][fencerKey]) {
                                    return;
                                }
                                const squadMember: TeamMember = {
                                    id: fencerKey,
                                    firstName: rosterDbObj[weapon][fencerKey].firstName,
                                    lastName: rosterDbObj[weapon][fencerKey].lastName,
                                    displayName: rosterDbObj[weapon][fencerKey].firstName + ' ' +
                                        rosterDbObj[weapon][fencerKey].lastName,
                                    role: roleObj[weapon][fencerKey].role,
                                    gradYear: rosterDbObj[weapon][fencerKey].gradYear,
                                    weapon: weapon,
                                };
                                fencersArray.push(squadMember);
                            })
                        }

                        //  array of objects with title & data props is the format
                        // required by react native SectionLists
                        rosterResultObj[weapon] = fencersArray;
                    });

                    listener && listener(rosterResultObj);

                    res(rosterResultObj);
                })
            } else {  //tournaments with manual teams will send teamID=''... return empty array
                res([]);
            };
        });
    }

    static getTeamRosterAsCSV(teamID: string, season: string, boysOrGirls: string): Promise<string> {
        return new Promise(async res => {
            const teamData = await DB.getTeamRosterForSeason(teamID, season, boysOrGirls, () => {});

            let csv = "First name,Last name,Graduation year,Weapon,Strip";

            for (const weapon in teamData) {
                const roster: TeamMember[] = teamData[weapon as Weapon];

                for (const fencer of roster) {
                    csv += `\n${fencer.firstName},${fencer.lastName},${fencer.gradYear},${fencer.weapon},${fencer.role || "Sub"}`
                }
            }

            res(csv);
        });
    }

    static editFencer(teamID: string, fencerID: string, firstName: string, lastName: string, gradYear: string) {
        return new Promise(res => {
            const user = fire.auth().currentUser;
            const d = new Date();

            const fencerUpdates: Record<string, any> = {};

            if (user) {
                //update data in overall /fencers list
                fencerUpdates['/fencers/' + fencerID + '/firstName'] = firstName;
                fencerUpdates['/fencers/' + fencerID + '/lastName'] = lastName;
                fencerUpdates['/fencers/' + fencerID + '/gradYear'] = gradYear;
                fencerUpdates['/fencers/' + fencerID + '/lastModifiedOn'] = d.toString();
                fencerUpdates['/fencers/' + fencerID + '/lastModifiedBy'] = user.displayName;

                fire.database().ref(dbPrefix + '/teams/details/' + teamID).once("value", (queryResults) => {

                    //update data shored as part of teams

                    const teamDetails = queryResults.val();

                    const fencerDataPathsInTeam = this.getPathsToKey(teamDetails, '/teams/details/' + teamID, fencerID);
                    fencerDataPathsInTeam.forEach(path => {
                        fencerUpdates[path + '/' + fencerID + '/firstName'] = firstName;
                        fencerUpdates[path + '/' + fencerID + '/lastName'] = lastName;
                        fencerUpdates[path + '/' + fencerID + '/gradYear'] = gradYear;
                    })

                    if (dbPrefix !== "")
                        fire.database().ref(dbPrefix).update(fencerUpdates).then(res);
                    else
                        fire.database().ref().update(fencerUpdates).then(res);

                });
            }
        });
    }

    static setFencerRole(teamID: string, season: string, fencerWeapon: Weapon, fencerID: string, fencerRole: string | undefined | null, boysOrGirlsTeam: string): Promise<void> {
        return new Promise(res => {
            let user = fire.auth().currentUser;

            let boysOrGirlsTeamInDatabase = '';
            if (boysOrGirlsTeam === 'boys') {
                boysOrGirlsTeamInDatabase = '/boys';
            } else if (boysOrGirlsTeam === 'girls') {
                boysOrGirlsTeamInDatabase = '/girls';
            }

            if (user) {
                if (fencerRole && fencerRole !== '') {
                    fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons/details/' + season + boysOrGirlsTeamInDatabase +
                        '/squads/' + fencerWeapon + '/' + fencerID).update(
                            {
                                role: fencerRole,
                                roleUpdatedOn: (new Date()).toString(),
                                roleUpdatedBy: user.displayName,
                                roleUpdatedByUID: user.uid,
                            }
                        );
                } else {
                    fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons/details/' + season + boysOrGirlsTeamInDatabase +
                        '/squads/' + fencerWeapon + '/' + fencerID + '/role')
                        .remove();
                }
            }
            res();
        });
    }

    static getPathsToKey(obj: Object, pathToObj: string, keyToFind: string) {
        let pathsFound: string[] = [];

        if (obj.hasOwnProperty(keyToFind)) {
            pathsFound = [pathToObj];
        }

        Object.keys(obj).forEach(subObjKey => {

            if (subObjKey !== keyToFind && typeof obj[subObjKey] === 'object') { //reccursively search in subobjects

                let pathsFoundInSubObj = this.getPathsToKey(obj[subObjKey], pathToObj + '/' + subObjKey, keyToFind);
                if (pathsFoundInSubObj.length > 0 && pathsFound.length > 0)
                    pathsFound = [...pathsFound, ...pathsFoundInSubObj];
                else if (pathsFoundInSubObj.length > 0 && pathsFound.length === 0)
                    pathsFound = [...pathsFoundInSubObj];
            }
        })

        return pathsFound;
    }

    static removeFencerFromRoster(teamID: string, season: string, fencerWeapon: Weapon, fencerID: string, boysOrGirlsTeam: string) {
        let boysOrGirlsTeamInDatabase = '';

        if (boysOrGirlsTeam === 'boys') {
            boysOrGirlsTeamInDatabase = '/boys';
        } else if (boysOrGirlsTeam === 'girls') {
            boysOrGirlsTeamInDatabase = '/girls';
        }

        const updatings = {};

        updatings[`/fencers/${fencerID}/teamID`] = null;
        updatings[`/fencers/${fencerID}/teamName`] = null;


        fire.database().ref().update(updatings);

        fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons/details/' + season +
            boysOrGirlsTeamInDatabase + '/squads/' + fencerWeapon + '/' + fencerID).remove();
    }

    static getFencersList(): Promise<Fencer[]> {
        return new Promise(res => {
            console.time("Getting fencers list");
            fire.database().ref(dbPrefix + '/teams/list').orderByChild('teamName').on("value", async (queryResults) => {
                const arrayOfTeams: string[] = [];

                queryResults.forEach((dbTeamObj) => {
                    let teamObj = dbTeamObj.val();
                    if (teamObj.isPublished) { // ENABLE IN PRODUCTION
                        arrayOfTeams.push(dbTeamObj.key!);
                    }
                });

                fire.database().ref(dbPrefix + '/fencers').orderByChild('lastName').on("value", (queryResults) => {
                    const arrayOfFencers: Fencer[] = [];

                    // see https://stackoverflow.com/questions/33893866/orderbychild-not-working-in-firebase
                    // for why just doing queryResults.val() looses the sort order
                    queryResults.forEach((dbFencerObj) => {
                        let fencerObj = dbFencerObj.val();
                        fencerObj.fencerID = dbFencerObj.key;
                        if (arrayOfTeams.includes(fencerObj.teamID)) {
                            fire.database().ref(dbPrefix + '/teams/details/' + fencerObj.teamID + '/administrators').once("value", (queryResults) => {
                                const allAdminsObjects: TeamAdministrators = {};
                                queryResults.forEach((dbObject) => {
                                    allAdminsObjects[dbObject.key!] = dbObject.val();
                                });

                                fencerObj.isAdmin = Object.values(allAdminsObjects).map(l => l.name).includes(`${fencerObj.firstName} ${fencerObj.lastName}`)
                            })
                            arrayOfFencers.push(fencerObj);
                        }
                    });

                    console.timeEnd("Getting fencers list");

                    res(arrayOfFencers);
                })
            });
        });
    }

    static getFencersListRaw(): Promise<Omit<Fencer, "isAdmin">[]> {
        return new Promise(res => {
            fire.database().ref(dbPrefix + '/fencers').orderByChild('lastName').on("value", (queryResults) => {
                const arrayOfFencers: Omit<Fencer, "isAdmin">[] = [];

                // see https://stackoverflow.com/questions/33893866/orderbychild-not-working-in-firebase
                // for why just doing queryResults.val() looses the sort order
                queryResults.forEach((dbFencerObj) => {
                    let fencerObj = dbFencerObj.val();
                    fencerObj.fencerID = dbFencerObj.key;
                    arrayOfFencers.push(fencerObj);
                })

                res(arrayOfFencers);
            });
        });
    }

    static getMatchList(listener?: (match: MatchData[]) => void): Promise<MatchData[]> {
        return new Promise(res => {
            fire.database().ref(dbPrefix + '/matches').off();
            fire.database().ref(dbPrefix + '/matches').orderByChild('date').on("value", (queryResults) => {
                const arrayOfMatches: MatchData[] = [];

                // see https://stackoverflow.com/questions/33893866/orderbychild-not-working-in-firebase
                // for why just doing queryResults.val() looses the sort order
                queryResults.forEach((dbFencerObj) => {
                    let data = dbFencerObj.val();
                    data.matchID = dbFencerObj.key;
                    if (data.matchID === "details") {
                        return;
                    }
                    data.date = new Date(Date.parse(data.date));
                    arrayOfMatches.push(data);
                })

                arrayOfMatches.reverse();

                listener && listener(arrayOfMatches);
                res(arrayOfMatches);
            });
        });
    }

    static getMatchBouts(matchID: string, listener?: (bouts: Bout[]) => void): Promise<Bout[]> {
        return new Promise(res => {
            fire.database().ref(`${dbPrefix}/matches/details/${matchID}/bouts`).on("value", queryResults => {
                const r: Bout[] = queryResults.val();
                listener && listener(r);
                res(r);
            })
        });
    }

    static addExitingFencerToSeason(teamID: string, season: string, weapon: Weapon, fencerID: string, firstName: string, lastName: string, gradYear: string, boysOrGirlsTeam: string) {
        let boysOrGirlsTeamInDatabase = '';
        if (boysOrGirlsTeam === 'boys') {
            boysOrGirlsTeamInDatabase = '/boys';
        } else if (boysOrGirlsTeam === 'girls') {
            boysOrGirlsTeamInDatabase = '/girls';
        }

        let user = fire.auth().currentUser;
        if (user) {

            fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons/details/' + season + boysOrGirlsTeamInDatabase).update(
                {
                    [`squads/${weapon}/${fencerID}`]:
                    {
                        firstName: firstName,
                        lastName: lastName,
                        createdOn: (new Date()).toString(),
                        createdBy: user.displayName,
                        createdByUID: user.uid,
                    },
                }
            );
        }
    }

    /**
     * Adds a new fencer to a team in the database
     */
    static createTeamSeason(teamID: string, teamName: string, newSeasonName: string, boysOrGirlsTeam: string, isPublished: boolean, copyRosterFromSeason: string | null = null): Promise<void> {
        return new Promise(res => {
            const user = fire.auth().currentUser;

            if (user) {
                if (copyRosterFromSeason && copyRosterFromSeason !== '') {

                    fire.database().ref(dbPrefix + '/teams/details/' + teamID +
                        '/seasons/details/' + copyRosterFromSeason).once('value', (queryResults) => {

                            fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons').update({
                                ['list/' + newSeasonName]: {
                                    'createdOn': (new Date()).toString(),
                                    'createdBy': user.displayName,
                                    'createdByUID': user.uid,
                                },
                                ['details/' + newSeasonName]: queryResults.val(),
                            }).then(() => res());
                        })
                } else {
                    fire.database().ref(dbPrefix + '/teams/details/' + teamID + '/seasons').update({
                        ['list/' + newSeasonName]: {
                            'createdOn': (new Date()).toString(),
                            'createdBy': user.displayName,
                            'createdByUID': user.uid,
                        },
                        ['details/' + newSeasonName]: { squads: {} },
                    }).then(() => res());

                }

                fire.database().ref(dbPrefix + '/teams/list-by-season/' + newSeasonName).update({
                    [teamID]: { teamName: teamName, boysOrGirlsTeam: boysOrGirlsTeam, isPublished: isPublished }
                });

                return newSeasonName;
            } else {
                return null;
            }

        });
    }
    static getLiveTournamentList(listener: (tourneys: TournamentDataWithBouts[]) => void): Promise<TournamentDataWithBouts[]> {
        return new Promise(res => {
            let todaysDate = new Date();
            todaysDate.setHours(0, 0, 0, 0);
            let startTimeStamp = todaysDate.getTime(); //yesterday
            let endTimeStamp = startTimeStamp + 86400000; // tomorrow
            fire.database().ref(dbPrefix + '/tournaments/list')
                .orderByChild('startTimestamp').startAt(startTimeStamp).endAt(endTimeStamp)
                .on("value", async (queryResults) => {
                    let arrayOfTournaments: Promise<any>[] = [];

                    // see https://stackoverflow.com/questions/33893866/orderbychild-not-working-in-firebase
                    // for why just doing queryResults.val() looses the sort order
                    queryResults.forEach((dbTournamentObj) => {
                        arrayOfTournaments.push(new Promise(res => {
                            let tournamentObj = dbTournamentObj.val();
                            tournamentObj.tournamentID = dbTournamentObj.key;

                            fire.database().ref(dbPrefix + '/tournaments/details/' + tournamentObj.tournamentID + '/bouts').on("value", (q) => {
                                const dbData = q.val();

                                const bouts: Bout[] = [];

                                if (dbData) {
                                    Object.keys(dbData).forEach(weapon => {
                                        Object.keys(dbData[weapon]).forEach(boutID => {
                                            const bout = dbData[weapon][boutID];
                                            bout.boutID = boutID;
                                            bouts.push(bout);
                                        })

                                    });
                                }
                                if (tournamentObj.isPublished) {
                                    tournamentObj.bouts = bouts || [];
                                    tournamentObj.startDate = new Date(Date.parse(tournamentObj.startDate));
                                    tournamentObj.createdOn = new Date(Date.parse(tournamentObj.createdOn));
                                    tournamentObj.modifiedName = (() => {
                                        let modifiedName = "";
                                        if ("tournamentName" in tournamentObj && (tournamentObj.tournamentName.includes("vs") || tournamentObj.tournamentName.includes("@"))) {
                                            let modifiedNameArr: string[] = tournamentObj.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
                                            modifiedNameArr = modifiedNameArr
                                                .filter(l => !!l)
                                                .filter(l => !l.includes("vs") && !l.includes("@"))
                                                .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
                                            if (tournamentObj.tournamentName.includes("@")) {
                                                modifiedNameArr.reverse();
                                            }
                                            modifiedName = `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
                                        } else {
                                            modifiedName = `${tournamentObj.team1Name} (H) vs. ${tournamentObj.team2Name} (A)`;
                                        }
                                        return modifiedName;
                                    })();
                                }
                                res(tournamentObj);
                            });
                        }));
                    });

                    const resolved = await Promise.all(arrayOfTournaments);

                    listener && listener(resolved);
                    res(resolved);
                })
        });
    }
    static getTournamentData(id: string, listener?: (data: TournamentDataWithBouts & { modifiedName: string; tournamentID: string }) => void): Promise<TournamentDataWithBouts & { modifiedName: string; tournamentID: string }> {
        return new Promise(res => {
            fire.database().ref(dbPrefix + '/tournaments/list/' + id).on("value", (queryResults) => {
                const val = queryResults.val();
                fire.database().ref(dbPrefix + '/tournaments/details/' + id + '/bouts').on("value", (q) => {
                    let dbData = q.val();
                    val.bouts = dbData;
                    val.modifiedName = (() => {
                        let modifiedName = "";
                        if ("tournamentName" in val && (val.tournamentName.includes("vs") || val.tournamentName.includes("@"))) {
                            let modifiedNameArr = val.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
                            modifiedNameArr = modifiedNameArr
                                .filter(l => !!l)
                                .filter(l => !l.includes("vs") && !l.includes("@"))
                                .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
                            if (val.tournamentName.includes("@")) {
                                modifiedNameArr.reverse();
                            }
                            modifiedName = `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
                        } else {
                            modifiedName = `${val.team1Name} (H) vs. ${val.team2Name} (A)`;
                        }
                        return modifiedName;
                    })();
                    res(val);
                    listener && listener(val);
                });
            });
        });
    }
    static getAllTournaments(listener?) {
        interface RawTournament extends TournamentData {
            createdOn: string;
            startTimestamp: number;
        }

        return new Promise(res => {
            fire.database().ref(`/tournaments/list`).on("value", async q => {
                const val: Record<string, RawTournament> = q.val();
                const tournaments: TournamentData[] = [];
                Object.entries(val).forEach((dbTournamentObj) => {
                    const rawTournamentObj = dbTournamentObj[1];
                    const tournamentObj: TournamentData = {
                        ...rawTournamentObj,
                        tournamentID: dbTournamentObj[0],
                        startDate: new Date(rawTournamentObj.startTimestamp).toString(),
                        createdOn: new Date(Date.parse(rawTournamentObj.createdOn)).toString()
                    }
                    tournamentObj.modifiedName = (() => {
                        let modifiedName = "";
                        if ("tournamentName" in tournamentObj && (tournamentObj.tournamentName.includes("vs") || tournamentObj.tournamentName.includes("@"))) {
                            let modifiedNameArr = tournamentObj.tournamentName.split(/( vs\.? )|( @ )|((Boys|Girls): )/);
                            modifiedNameArr = modifiedNameArr
                                .filter(l => !!l)
                                .filter(l => !l.includes("vs") && !l.includes("@"))
                                .filter(l => l !== "Boys" && l !== "Girls" && l !== "Boys: " && l !== "Girls: ");
                            if (tournamentObj.tournamentName.includes("@")) {
                                modifiedNameArr.reverse();
                            }
                            modifiedName = `${modifiedNameArr[0]} (H) vs. ${modifiedNameArr[1]} (A)`;
                        } else {
                            modifiedName = `${tournamentObj.team1Name} (H) vs. ${tournamentObj.team2Name} (A)`;
                        }
                        return modifiedName;
                    })();
                    tournaments.unshift(tournamentObj);
                });
                const bouts = Object.values(await getRefs("/tournaments/details")).map((j: { bouts: MeetBouts }) => j.bouts);
                const boutsObj = {};
                for (const i in bouts) {
                    boutsObj[Object.keys(val)[i]] = bouts[i];
                }
                tournaments.sort((a, b) => (b.startDate > a.startDate) ? 1 : (a.startDate === b.startDate) ? ((a.tournamentName > b.tournamentName) ? 1 : -1) : -1)
                listener && listener({ tournaments, bouts: boutsObj });
                res({ tournaments, bouts: boutsObj });
            })
        });
    }
}
