import { Bout } from "../types";

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const genRandomColor = () => `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`;

/**
 * Returns `false` if left side wins, `true` if right side wins, and `null` if it has not finished.
 * Note that this does not take into account whether users have switched sides
 */
export const winner_legacy = (bout: Bout): boolean | null => {
    if (bout.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
        // if (bout.fencer1TeamID === bout.team1) {
            if (bout.score1 > bout.score2) {
                return false;
            } else if (bout.score1 < bout.score2) {
                return true;
            } else if (bout.fencer1Priority) {
                return false;
            } else if (bout.fencer2Priority) {
                return true;
            }
        // } else {
            // if (bout.score1 > bout.score2) {
                // return true;
            // } else if (bout.score1 < bout.score2) {
                // return false;
            // } else if (bout.fencer1Priority) {
                // return true;
            // } else if (bout.fencer2Priority) {
                // return false;
            // }
        // }
    }
    return null;
}

/**
 * Disable scrolling on `document.body`
 */
 export function disableScroll() {
    const scrollPosition = window.pageYOffset;
    document.body.style.top = -scrollPosition + 'px';
    document.body.classList.add("noscroll");
}

/**
 * Enable scrolling on `document.body`
 */
export function enableScroll() {
    document.body.classList.remove("noscroll");
    window.scrollTo(0, -Number(document.body.style.top.split("px")[0]));
    document.body.style.top = "initial";
}
