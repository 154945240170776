import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import geography, { GeographyData } from "../../utils/geography";
import EquipmentOnGround from "../../assets/EquipmentOnGround.jpg";
import DB from "../../utils/database";
import "./TeamCreation.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, updateMeetsThunk } from "../../utils/store";
import PermissionDenied from "../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../components/TbTPage/TbTPage";

const TeamCreation = () => {
    const [matchingCountry, setMatchingCountry] = useState<GeographyData>(geography.find(l => l.countryCode === "US")!);
    const [region, setRegion] = useState("Select region");
    const [teamName, setTeamName] = useState("");
    const [teamGender, setTeamGender] = useState("both");

    const dispatch = useDispatch();

    const history = useHistory();

    const loggedIn = useSelector((s: ReduxState) => s.user);

    if (!loggedIn) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    const submitForm = async () => {
        const teamID = await DB.createTeam(teamName, matchingCountry.country, matchingCountry.countryCode, region, teamGender);
        new Promise((res) => { res(dispatch({ type: "switchAdministratingTeam", season: teamID })) })
            .then(() => dispatch(updateMeetsThunk))
            .then(() => history.push("/teamData"));
    };

    return (
        <TbTPage className="teamCreationPage">
            <h2 className="createNewTeamHeader">Create New Team</h2>
            <div className="teamCreationBox">
                <div className="boxData">
                <Form className="formBox">
                    <Form.Group controlId="newTeamName" style={{ marginBottom: 10 }}>
                        <Form.Label>Team Name <span className="requiredStar">*</span></Form.Label>
                        <Form.Control type="text" placeholder="Team Name (e.g. Morris Hills)" onChange={e => setTeamName(e.target.value)} value={ teamName }></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="newTeamCountry">
                        <Form.Label>Team gender</Form.Label>
                        <Form.Control as="select" defaultValue={"both"} onChange={j => setTeamGender(j.target.value)}>
                            <option value="both">Men's and Women's</option>
                            <option value="boys">Men only</option>
                            <option value="girls">Women only</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="newTeamCountry">
                        <Form.Label>Country name <span className="requiredStar">*</span></Form.Label>
                        <Form.Control as="select" defaultValue={matchingCountry.country} onChange={j => setMatchingCountry(geography.find(l => l.country === j.target.value)!)}>
                            {geography.map(l =>
                                <option
                                    key={`newTeamCountry${l.countryCode}`}
                                >
                                    {l.country}
                                </option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="newTeamRegion" style={{ marginBottom: 10 }}>
                        <Form.Label>Region name <span className="requiredStar">*</span></Form.Label>
                        <Form.Control as="select" value={ region } onChange={e => setRegion(e.target.value)}>
                            {matchingCountry.regions.map(l => <option key={`newTeamRegion${l}`}>{l || "Select region"}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" disabled={!teamName || region === "Select region"} className={`${teamName || region === "Select region" ? "disabledButton" : ""}`} onClick={submitForm}>
                        Create
                    </Button>
                </Form>
                <img src={ EquipmentOnGround } alt="Old fencers" />
                </div>
            </div>
        </TbTPage>

    );
}

export default TeamCreation;
