import "./About.css";
import TbTPage from "../../components/TbTPage/TbTPage";
import TBTIcon from "../../assets/TBTicon.png";
import AppUserGuide from "../../assets/TBT Fencing App User Guide 2021.pdf";
import SiteUserGuide from "../../assets/TbT Website User Documentation 2021.pdf";
import GooglePlayBadge from "../../assets/google-play-badge.png";

export default function About () {
    return (
        <TbTPage className="aboutPage">
            <div className="aboutTopSection">
                <div className="aboutInfo">
                    <div className="aboutInfoHeader">
                        <h2>TouchByTouch</h2>
                    </div>
                    <h3 style={{ color: "#999" }}>Developed by fencers</h3>
                    <h4>Varun Gupta (Creator)</h4>
                    <h4>Andrew 'Lefty' Zlotnick (Co-Creator)</h4>
                    <h4>Henry 'Denny' Hill</h4>
                    <h4>Jay Lefever</h4>
                    <h4>Tiffany Coelho</h4>
                    <h4>Jayson Zelaya</h4>
                    <h4>Brandon Howe</h4>
                    <h4>Jerry Duffey</h4>
                </div>
            </div>
            <div className="appDownloads">
                <a href="https://apps.apple.com/us/app/touch-by-touch/id1530755543?itsct=apps_box_badge&itscg=30200">
                    <img src="https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg" alt="Download on App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=live.touchbytouch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img src={GooglePlayBadge} alt="Download on Google Play" />
                </a>
            </div>
            <div className="userGuides">
                <a target="_blank" rel="noreferrer" href={AppUserGuide}><div className="userGuide">TbT Fencing App User Guide</div></a>
                <a target="_blank" rel="noreferrer" href={SiteUserGuide}><div className="userGuide">TbT Fencing Website Documentation</div></a>
            </div>
            <div className="aboutLowerSection">
                <img src={TBTIcon} alt="Touch By Touch logo" />
                <h5>SPONSORED BY FORTE FENCING FOUNDATION</h5>
                <a href="https://fortefencing.com">https://fortefencing.com</a>
                <h6>Version 1.14</h6>
                <h6 className="stockNotice">Stock photos licensed under iStockPhoto.com inv # 15376813, cust # 20742485</h6>
            </div>
        </TbTPage>
    )
}
