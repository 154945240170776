import { useState } from "react";
import { useSelector } from "react-redux";
import { CommonLoading } from "../../components/Loading/Loading";
import { Button, Dropdown, Form, Toast } from "react-bootstrap";
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import TbTPage from "../../components/TbTPage/TbTPage";
import Modal from "../../components/Modal/Modal";
import "./Matches.css";
import DB from "../../utils/database";
import { enableScroll } from "../../utils/helpers";

/**
 * Component for match info
 * @param {MatchData} data
 * @param {BoutData} bouts
 * @param {string} teamID
 */
 function MatchInfo({ data, openModal }) {
    const gendersObj = {
        "both": "Both mens and womens",
        "boys": "Men's",
        "girls": "Women's",
    };

    const weaponsObj = {
        "all": "All weapons",
        "Sabre": "Sabre",
        "Foil": "Foil",
        "Epee": "Epee"
    };

    const date = (typeof data.date === "string") ? new Date(Date.parse(data.date)) : data.date;

    return (
        <div className="matchInfo">
            <div className="matchInfoHeader">
                <a className="matchInfoLink" href={`/tournamentInfo/${data.matchID}`} key={`matchInfo${data.matchID}`}>
                    <h4 style={{ margin: 0, width: "calc(100% - 45px)", paddingLeft: 45, lineHeight: "50px", color: "#FFF" }}>{data.name} - {gendersObj[data.gender]} {weaponsObj[data.weapon || data.weapons]}</h4>
                </a>
                <div onClick={openModal} className="matchDeleteButton"><Icon path={mdiDelete}></Icon></div>
            </div>
            <a className="matchInfoLink" href={`/tournamentInfo/${data.matchID}`} key={`matchInfo${data.matchID}asdf`}>
                <p>Round {data.round}</p>
                <p>{date.toDateString()}</p>
                <p>Venue: {data.venue}</p>
                <p>{data.matchType === "team" ? "Team Pool" : "Individual Pool"} - { data.teamCount } {data.matchType === "team" ? "teams" : "fencers"}</p>
            </a>
        </div>
    );
}

export default function Matches() {
    const matches = useSelector(s => s.matchList);
    const loading = useSelector(s => s.loading);

    const seasons = [2021, 2022];

    const [season, setSeason] = useState(new Date().getMonth() > 7 ? new Date().getFullYear() : new Date().getFullYear() - 1);
    const [search, setSearch] = useState("");
    const [deletingMatch, setDeletingMatch] = useState(-1);
    const [password, setPassword] = useState("");
    const [showPasswordToast, setShowPasswordToast] = useState(false);

    const deleteMatch = () => {
        DB.deleteMatch(matches[deletingMatch]);
        setDeletingMatch(-1);
        enableScroll();
    };

    return (
        <TbTPage>
            <h1 style={{ color: "#FFF", marginTop: 30 }}>Tournaments</h1>
            <input onChange={(e) => setSearch(e.target.value)} placeholder="Search for a tournament..." style={{ marginBottom: 20, borderRadius: 3, border: "none", padding: 5 }} />
            <Dropdown style={{ textAlign: "center", marginBottom: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                    { season }-{ season + 1 } season
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: "inherit" }}>
                    { seasons.map(l => <Dropdown.Item onClick={() => setSeason(l)} key={`matchSeason${l}`}>{l}-{l + 1} season</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown>
            {
                loading
                    ? <CommonLoading color="#714FCA" size="large" />
                    : matches
                        .filter(l => l.name.toLowerCase().includes(search.toLowerCase()))
                        .filter(l => {
                            const date = (typeof l.date === "string") ? new Date(Date.parse(l.date)) : l.date;
                            return date.getMonth() > 7 ? date.getFullYear() === season : date.getFullYear() - 1 === season;
                        })
                        .map((l, idx) => <MatchInfo key={l.matchID} data={l} openModal={() => setDeletingMatch(idx)}></MatchInfo>)
            }
            {
                deletingMatch !== -1 && <Modal close={() => setDeletingMatch(-1)}>
                    { showPasswordToast && <div style={{ position: "absolute", left: "calc(50% - 175px)", zIndex: "10" }}>
                        <Toast bg="danger" position="top-center" onClose={() => setShowPasswordToast(false)} show={showPasswordToast} delay={5000} autohide>
                            <Toast.Header>
                                <strong className="me-auto">The password you entered is incorrect.</strong>
                            </Toast.Header>
                        </Toast>
                    </div> }
                    <h2>Deleting tournament</h2>
                    <h5>Type in the password in order to delete this tournament.</h5>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="text" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" style={{ marginTop: 15 }} onClick={() => password === "12345" ? deleteMatch() : setShowPasswordToast(true)}>
                        Submit
                    </Button>
                </Modal>
            }
        </TbTPage>
    );
}
