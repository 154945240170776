import { Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../utils/store";
import { CommonLoading } from "../../components/Loading/Loading";
import { MeetBouts, TournamentDataSmaller, Weapon } from "../../types";
import DB from "../../utils/database";
import TbTPage from "../../components/TbTPage/TbTPage";
import SeasonTeamSwitcher from "../../components/SeasonTeamSwitcher/SeasonTeamSwitcher";
import "./DualMeets.css";

/**
 * Component for tournament info
 */
function TournamentInfo({ data, bouts }: { data: TournamentDataSmaller, bouts: MeetBouts }) {
    const homeTeam = data?.team1ID || "";

    function getBoutScore(weapon: Weapon): { us: number; them: number } {
        let us = 0;
        let them = 0;
        for (const bout of Object.values(bouts[weapon])) {
            if (bout?.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
                if (bout.fencer1TeamID === homeTeam) {
                    if (bout.score1 > bout.score2) {
                        us++;
                    } else if (bout.score1 < bout.score2) {
                        them++;
                    } else if (bout.fencer1Priority) {
                        us++;
                    } else if (bout.fencer2Priority) {
                        them++;
                    }
                } else {
                    if (bout.score1 > bout.score2) {
                        them++;
                    } else if (bout.score1 < bout.score2) {
                        us++;
                    } else if (bout.fencer1Priority) {
                        them++;
                    } else if (bout.fencer2Priority) {
                        us++;
                    }
                }
            }
        }
        return { us, them };
    }

    const score = {
        us: getBoutScore("Epee").us + getBoutScore("Foil").us + getBoutScore("Sabre").us,
        them: getBoutScore("Epee").them + getBoutScore("Foil").them + getBoutScore("Sabre").them
    }

    const leDate = (typeof data.startDate === "string") ? new Date(Date.parse(data.startDate)) : data.startDate;

    if (Number(leDate) < Date.now() - 86400000 && score.us + score.them !== 27) {
        return <></>
    }

    return (
        <div style={{ width: "90vw", maxWidth: "600px", margin: "0 auto 20px auto" }} key={`tournamentInfo${data.tournamentID}`}>
            <a className="tournamentInfoLink" href={`/dualMeetInfo/${data.tournamentID}`}>
                { data.JV && <div className="tournamentInfoJVFlag">Junior Varsity</div> }
                <div className="tournamentInfo">
                    <div className="tournamentInfoHeader">
                        <h4 style={{ margin: 0 }}>{data.modifiedName}{ data.JV ? " - JV" : "" }</h4>
                    </div>
                    <p>{leDate.toDateString()}</p>
                    <p>{score.us} - {score.them}</p>
                </div>
            </a>
        </div>
    );
}

export default function Tournaments() {
    const loggedIn = useSelector((s: ReduxState) => s.user);
    const administratingTeams = useSelector((s: ReduxState) => s.administratingTeams);
    const tournamentsRaw = useSelector((s: ReduxState) => s.tournamentData);
    const boutsRaw = useSelector((s: ReduxState) => s.tournamentBouts);
    const loadingRaw = useSelector((s: ReduxState) => s.loading);
    const season = useSelector((s: ReduxState) => s.season);
    const dispatch = useDispatch();

    const [tournaments, setTournaments] = useState<TournamentDataSmaller[]>([]);
    const [bouts, setBouts] = useState(boutsRaw);
    const [loading, setLoading] = useState(loadingRaw);
    const [search, setSearch] = useState("");
    const [selectedSeason, setSelectedSeason] = useState("2021-2022");

    useEffect(() => {
        setTournaments(tournamentsRaw);
        setBouts(boutsRaw);
    }, [tournamentsRaw, boutsRaw]);

    useEffect(() => {
        setLoading(loadingRaw);
    }, [loadingRaw]);

    useEffect(() => {
        setBouts(boutsRaw);
        setTournaments(tournamentsRaw);
        if (!loggedIn || !Object.keys(administratingTeams).length) {
            setLoading(true);
            (async () => {
                DB.getAllTournaments(v => {
                    setBouts(v.bouts);
                    setTournaments(v.tournaments);
                    dispatch({ type: "setTournaments", tournaments: v.tournaments, bouts: v.bouts });
                    setLoading(false);
                });
            })();
        }
    }, [loggedIn, administratingTeams]);

    if (loggedIn && Object.keys(administratingTeams).length) {
        return (
            <TbTPage className="tournamentsPage">
                <h1 style={{ color: "#FFF" }}>Dual Meets for team</h1>
                <SeasonTeamSwitcher></SeasonTeamSwitcher>
                <input onChange={(e) => setSearch(e.target.value)} placeholder="Search for a meet..." style={{ marginBottom: 20, borderRadius: 3, border: "none", padding: 5 }} />
                {
                    loading
                        ? <CommonLoading color="#714FCA" size="large" />
                        : tournaments
                            .filter(l => l.modifiedName.toLowerCase().includes(search.toLowerCase()))
                            .filter(l => !l.modifiedName.toLowerCase().includes("jv"))
                            .filter(l => {
                                const leDate = new Date(Date.parse(l.startDate));
                                const currSeason = Number(season.split("-")[0]);
                                if (leDate.getMonth() >= 7) {
                                    if (currSeason === leDate.getFullYear()) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    if (currSeason === leDate.getFullYear() - 1) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })
                            .map(l => <TournamentInfo data={l} bouts={bouts[l.tournamentID]} />)
                }
            </TbTPage>
        );
    } else {
        return (
            <TbTPage className="tournamentsPage">
                <h1 style={{ color: "#FFF" }}>Dual Meets</h1>
                <input onChange={(e) => setSearch(e.target.value)} placeholder="Search for a meet..." style={{ marginBottom: 20, borderRadius: 3, border: "none", padding: 5 }} />
                <Dropdown style={{ textAlign: "center", marginBottom: 20 }} className="transparentDropdown" focusFirstItemOnShow={true}>
                    <Dropdown.Toggle style={{ fontSize: "inherit" }} id="dropdown-basic">
                        { selectedSeason } season
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "inherit" }}>
                        <Dropdown.Item onClick={() => setSelectedSeason("2020-2021")}>2020-2021</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedSeason("2021-2022")}>2021-2022</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {
                    loading
                        ? <CommonLoading color="#714FCA" size="large" />
                        : tournaments
                            .filter(l => l.modifiedName.toLowerCase().includes(search.toLowerCase()))
                            .filter(l => !l.modifiedName.toLowerCase().includes("jv"))
                            .filter(l => l.seasonName === selectedSeason)
                            .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))
                            .map(l => <TournamentInfo data={l} bouts={bouts[l.tournamentID]} />)
                }
            </TbTPage>
        )
    }
}
