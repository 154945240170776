import { useEffect, useState } from "react";
import DB from "../../utils/database";
import { Bout, TournamentDataWithBouts } from "../../types";
import TbTPage from "../../components/TbTPage/TbTPage";
import "./LandingPage.css";

const getMeetScore = (data: TournamentDataWithBouts, bouts: Bout[]) => {
    const home = (() => {
        if (!data) {
            return "";
        }
        return data.team1ID || "";
    })();

    function getBoutScore() {
        let us = 0;
        let them = 0;
        for (const bout of bouts) {
            if (bout?.boutLog?.some(l => l.eventType.toLowerCase() === "end")) {
                if (bout.fencer1TeamID === home) {
                    if (bout.score1 > bout.score2) {
                        us++;
                    } else if (bout.score1 < bout.score2) {
                        them++;
                    } else if (bout.fencer1Priority) {
                        us++;
                    } else if (bout.fencer2Priority) {
                        them++;
                    }
                } else {
                    if (bout.score1 > bout.score2) {
                        them++;
                    } else if (bout.score1 < bout.score2) {
                        us++;
                    } else if (bout.fencer1Priority) {
                        them++;
                    } else if (bout.fencer2Priority) {
                        us++;
                    }
                }
            }
        }
        return { us, them };
    }

    let us = getBoutScore().us;
    let them = getBoutScore().them;

    return {
        home: us,
        away: them
    }
}

function CurrentMeet(props) {
    const { data, bouts } = props;

    const score = getMeetScore(data, bouts);

    return (
        <a className="tournamentInfoLink" href={`/dualMeetInfo/${data.tournamentID}`} key={`tournamentInfo${data.tournamentID}`}>
            <div className="tournamentInfo">
                <h4 className="tournamentInfoHeader">{data.modifiedName}</h4>
                <p>{new Date(data.startTimestamp).toDateString()}</p>
                <p>{score.home} - {score.away}</p>
            </div>
        </a>
    )
}

export default function Tournaments() {
    const [liveTournaments, setLiveTournaments] = useState<TournamentDataWithBouts[]>([]);

    useEffect(() => {
        DB.getLiveTournamentList(tourneys => setLiveTournaments(tourneys.filter(l => l.isPublished)));
    }, []);

    return (
        <TbTPage className="landingPage">
            <div className="landingPageText"><h1>Welcome to TBT Fencing</h1></div>
            <br />
            <div className="landingPageNotice">
                <h2 className="landingPageNoticeHeader">Notice</h2>
                <p style={{ color: "white", textAlign: "left" }}>
                    The TouchByTouch website and app have been rewritten. You are accessing the
                    legacy version of the site, which contains the data from the 2020-2021 and
                    2021-2022 seasons. Click <a href="https://tbtfencing.com">here</a> to go to the new version of
                    the website.
                </p>
            </div>
            <div className="tournamentInfo">
                <h2 className="tournamentInfoHeader">Live now</h2>
                { liveTournaments.length === 0 && <h5 style={{ color: "white" }}>There are no live meets right now. Check back later!</h5> }
            </div>
            { liveTournaments.map(l => <CurrentMeet data={l} bouts={l.bouts || []}></CurrentMeet>)}
        </TbTPage>
    );
}
