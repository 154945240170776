import { useSelector } from "react-redux";
import { ReduxState } from "../../utils/store";
import PermissionDenied from "../PermissionDenied/PermissionDenied";

const RequireAuthenticated: React.FC<{}> = ({ children }) => {
    const loggedIn = useSelector((s: ReduxState) => s.user);
    const loading = useSelector((s: ReduxState) => s.loading);

    if (!loggedIn && !loading) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    return <>{ children }</>;
}

export default RequireAuthenticated;
