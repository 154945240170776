import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import fire from '../utils/firebaseSetup'
import { ReduxState } from "../utils/store";

const Navigation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const handleLogout = () => {
        fire.auth().signOut();
        dispatch({ type: "setAdminstrating", administrating: false });
        history.push("/");
    };

    const user = useSelector((s: ReduxState) => s.uid);

    return (
        <div style={{ height: "50px" }}>
            <Navbar bg="light" style={{ minHeight: "50px", backgroundColor: "#f8f9fa", zIndex: 3000 }} expand="md">
                <Navbar.Brand href="/">TbT</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" role="">
                    { !userInfo?.isBoutCommittee ? (
                        <Nav className="m-auto">
                            <Nav.Link href="/teamData">Team Data</Nav.Link>
                            <Nav.Link href="/roster">Roster</Nav.Link>
                            <Nav.Link href="/dualMeets">Dual Meets</Nav.Link>
                            <Nav.Link href="/createTeam">Create Team</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>
                        </Nav>
                    ) : (
                        <Nav className="m-auto">
                            <Nav.Link href="/createTournament">Create Tournaments</Nav.Link>
                            <Nav.Link href="/tournaments">Tournaments</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>
                        </Nav>
                    ) }

                    <Form>
                        <Nav className="mr-auto">
                        {user ? (
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        ) : (
                            <div>
                                <Nav.Link href="/login">Login</Nav.Link>
                            </div>
                        )}
                        </Nav>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Navigation
